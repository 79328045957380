const {
  REACT_APP_FS_API_KEY,
  REACT_APP_FS_CDN,
  REACT_APP_FS_WATERMARK,
  REACT_APP_FS_WATERMARK_SOLD,
} = process.env;

/**
 * `FS_API_KEY` - Retrieves `REACT_APP_FS_API_KEY` from .env
 * @constant {string} fsapikey
 * @default
 */
export const FS_API_KEY = REACT_APP_FS_API_KEY || null;

/**
 * `FS_CDN` - Retrieves `REACT_APP_FS_CDN` from .env
 * @constant {string} fscdn
 * @default
 */
export const FS_CDN = REACT_APP_FS_CDN || "https://cdn.filestackcontent.com";

/**
 * `FS_WATERMARK` - Retrieves `REACT_APP_FS_WATERMARK` from .env
 * @constant {string} fswatermark
 * @default
 */
export const FS_WATERMARK = REACT_APP_FS_WATERMARK;

/**
 * `FS_WATERMARK_SOLD` - Retrieves `REACT_APP_FS_WATERMARK_SOLD` from .env
 * @constant {string} fswatermark
 * @default
 */
export const FS_WATERMARK_SOLD = REACT_APP_FS_WATERMARK_SOLD;
