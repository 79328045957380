import axios from "axios";
import * as Sentry from "@sentry/react";
import store from "./store";
import { actions as loginActions } from "../modules/login/Login.redux";

import {
  REQUEST_MAX_SIZE,
  REQUEST_MAX_TIME,
  URL_API_BASE,
  URL_API_LOGIN_REFRESH,
} from "../constants";

/**
 * Create and configure an axios instance for communicating with the API
 */
const api = axios.create({
  baseURL: URL_API_BASE,
  timeout: REQUEST_MAX_TIME,
  maxContentLength: REQUEST_MAX_SIZE,
  headers: {
    "Cache-Control": "no-cache,no-store,must-revalidate,max-age=-1,private",
    Pragma: "no-cache",
    Expires: "Sat, 01 Jan 2000 00:00:00 GMT",
  },
});

/**
 * Intercept 401 errors and request a refresh token
 */
api.interceptors.response.use(
  response => response,
  async error => {
    if (error?.response?.status !== 401) {
      return Promise.reject(error);
    }

    try {
      const baseErrorMessage =
        "The action taken before logging back in failed because";
      if (
        !!error.response?.data &&
        error.response.data.message === "The token has been blacklisted"
      ) {
        throw new Error(
          `${baseErrorMessage} you had logged out in another window`
        );
      }

      const originalRequest = error.config;

      if (originalRequest._retry) {
        throw new Error(
          `${baseErrorMessage} the authentication refresh token was not accepted`
        );
      }

      originalRequest._retry = true;

      const refresh = await api.post(URL_API_LOGIN_REFRESH);

      if (!refresh) {
        throw new Error(
          `${baseErrorMessage} the authentication refresh token request failed`
        );
      }

      const { access_token } = refresh?.data || {};

      if (!access_token) {
        throw new Error(
          `${baseErrorMessage} the authentication refresh token was not defined`
        );
      }

      originalRequest.headers.Authorization = `Bearer ${access_token}`;
      apiAuthorize(access_token);
      store.dispatch(loginActions.updateAuthToken(access_token));

      return api(originalRequest);
    } catch (err) {
      store.dispatch(
        loginActions.expire("Authentication could not be refreshed", true)
      );
      err.response = { data: err.message, status: 401 };
      return Promise.reject(err);
    }
  }
);

/**
 * Remove authorization header from API requests
 * @extends api
 */
export const apiDeauthorize = () => {
  if (api.defaults.headers.common.hasOwnProperty("Authorization")) {
    delete api.defaults.headers.common.Authorization;
  }
};

/**
 * Add authorization header to API requests
 * @param {string} authToken
 */
export const apiAuthorize = authToken => {
  if (!!authToken) {
    api.defaults.headers.common.Authorization = `Bearer ${authToken}`;
  } else {
    apiDeauthorize();
  }
};

/**
 * Get constructor needed to cancel API requests
 */
export const CancelToken = axios.CancelToken;

/**
 * Parse error message from API response
 * @param {error|object|string} error
 * @public
 */
const parseApiErrorMessage = (error, status) => {
  if (!!error) {
    try {
      if (typeof error === "string" || error instanceof String) {
        return error;
      }

      const errorData = error.data || (!!error.response && error.response.data);

      if (
        !!errorData &&
        (typeof errorData === "string" || errorData instanceof String)
      ) {
        return errorData;
      }

      if (!!errorData && (!!errorData.message || !!errorData.error)) {
        let message = errorData.message || errorData.error;

        if (!!errorData.errors) {
          const errors = Object.keys(error.response.data.errors).map(k =>
            error.response.data.errors[k].join(" ")
          );
          message += ` ${errors.join(", ")}`;
        }

        return message;
      }

      if (!!status) {
        switch (status) {
          case 400:
            return "Bad Request";
          case 401:
            return "Unauthorized Request";
          case 403:
            return "Forbidden Request";
          case 404:
            return "Request Not Found";
          case 429:
            return "Too Many Requests";
          case 500:
            return "Server Error";
          case 503:
            return "Service Unavailable";
          default:
            return `${status} Error`;
        }
      }

      if (!!error.message) {
        return error.message;
      }
    } catch (e) {}
  }

  return "Unknown error";
};

/**
 * Get error message from API response
 * @param {error|object|string} error
 * @public
 */
export const getApiErrorMessage = error => {
  const status = (!!error && !!error.response && error.response.status) || null;
  const errorMessage = parseApiErrorMessage(error, status);

  if (error instanceof Error) {
    if (process.env.NODE_ENV === "development") {
      console.trace(error);
    } else if (!status || (!!status && status >= 500)) {
      try {
        Sentry.captureException(error, {
          errorMessage: errorMessage,
          errorObject: error,
        });
      } catch (error) {}
    }
  }

  return errorMessage;
};

export default api;
