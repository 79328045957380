import React, { PureComponent } from "react";
import { object } from "prop-types";

import { Alert } from "reactstrap";

import ListingCopy from "./Listing.copy";

import { URL_PUBLIC } from "../../../constants";
import { removeProtocol } from "../../../helpers";

class ListingConfirmation extends PureComponent {
  constructor(props) {
    super(props);

    this.dismiss = this.dismiss.bind(this);

    this.state = {
      visible: true,
    };
  }

  /**
   * Dismiss alert
   */
  dismiss() {
    this.setState({ visible: false });
  }

  render() {
    const { dismiss } = this;
    const { slug, title, isInternal, type } = this.props.confirmation;
    const { visible } = this.state;

    switch (type) {
      case "forceStatusSold":
        return (
          <Alert
            className="listing-confirmation"
            color="primary"
            isOpen={visible}
            toggle={dismiss}
          >
            <p className="h4 text-uppercase">
              <strong>Done</strong>
            </p>
            <p>{!!title ? title : "Your listing"} has been marked as sold.</p>
          </Alert>
        );
      case "published":
        const publicLink = `${URL_PUBLIC}/${slug}`;
        return (
          <Alert
            className="listing-confirmation"
            color="success"
            isOpen={visible}
            toggle={dismiss}
          >
            <p className="h4 text-uppercase">
              <strong>Published</strong>
            </p>
            <p>
              <strong className="d-block text-uppercase">
                View {isInternal === 1 ? "Internal Only" : ""} Listing:
              </strong>
              <a href={publicLink} rel="noopener noreferrer" target="_blank">
                {removeProtocol(publicLink)}
              </a>
            </p>
            <p className="ml-auto mr-md-4_25">
              <ListingCopy size="sm" text="Copy Link" value={publicLink} />
            </p>
          </Alert>
        );
      case "unpublished":
        return (
          <Alert
            className="listing-confirmation"
            color="primary"
            isOpen={visible}
            toggle={dismiss}
          >
            <p className="h4 text-uppercase">
              <strong>Done</strong>
            </p>
            <p>{!!title ? title : "Your listing"} has been unpublished.</p>
          </Alert>
        );
      default:
        return null;
    }
  }
}

ListingConfirmation.propTypes = {
  confirmation: object,
};

export default ListingConfirmation;
