/**
 * Scroll an element into view
 * @param {HTMLElement} el
 */
export const scrollIfNeeded = el => {
  const bounds = el.getBoundingClientRect();

  if (
    bounds.top < 0 ||
    bounds.top >= (window.innerHeight || document.documentElement.clientHeight)
  ) {
    el.scrollIntoView({ behavior: "smooth" });
  }
};
