import {
  api,
  CancelToken,
  getApiErrorMessage,
  getFileStamp,
} from "../../helpers";

import {
  URL_API_STATUS,
  URL_API_STATUS_DOWNLOAD_DAILY,
  URL_API_STATUS_DOWNLOAD_FORM_DATA,
  URL_API_STATUS_DOWNLOAD_HISTORICAL,
  URL_API_STATUS_DOWNLOAD_SEARCH,
} from "../../constants";

import moment from "moment";
import { saveAs } from "file-saver";

/**
 * Action types
 * @property {string} DOWNLOAD_DAILY_ERROR
 * @property {string} DOWNLOAD_DAILY_LOADING
 * @property {string} DOWNLOAD_FORM_DATA_ERROR
 * @property {string} DOWNLOAD_FORM_DATA_LOADING
 * @property {string} DOWNLOAD_HISTORICAL_ERROR
 * @property {string} DOWNLOAD_HISTORICAL_LOADING
 * @property {string} DOWNLOAD_SEARCH_ERROR
 * @property {string} DOWNLOAD_SEARCH_LOADING
 * @property {string} STATUS_ERROR
 * @property {string} STATUS_LOADING
 * @property {string} STATUS_SUCCESS
 */
export const types = {
  DOWNLOAD_DAILY_ERROR: "DOWNLOAD_DAILY_ERROR",
  DOWNLOAD_DAILY_LOADING: "DOWNLOAD_DAILY_LOADING",
  DOWNLOAD_FORM_DATA_ERROR: "DOWNLOAD_FORM_DATA_ERROR",
  DOWNLOAD_FORM_DATA_LOADING: "DOWNLOAD_FORM_DATA_LOADING",
  DOWNLOAD_HISTORICAL_ERROR: "DOWNLOAD_HISTORICAL_ERROR",
  DOWNLOAD_HISTORICAL_LOADING: "DOWNLOAD_HISTORICAL_LOADING",
  DOWNLOAD_SEARCH_ERROR: "DOWNLOAD_SEARCH_ERROR",
  DOWNLOAD_SEARCH_LOADING: "DOWNLOAD_SEARCH_LOADING",
  STATUS_ERROR: "STATUS_ERROR",
  STATUS_LOADING: "STATUS_LOADING",
  STATUS_SUCCESS: "STATUS_SUCCESS",
};

/**
 * @namespace Reducers
 */
export const reducers = {
  /**
   * Updates admin logins state
   * @param {array} state
   * @param {object} action
   * @param {string} action.type
   * @param {array} action.users
   * @memberof Reducers
   */
  adminLogins: (state = [], action) => {
    switch (action.type) {
      case types.STATUS_ERROR:
      case types.STATUS_LOADING:
        return [];
      case types.STATUS_SUCCESS:
        return action.users;
      default:
        return state;
    }
  },

  /**
   * Updates daily download error state
   * @param {string} state
   * @param {object} action
   * @param {string} action.type
   * @param {string} action.error
   * @memberof Reducers
   */
  dailyDownloadError: (state = null, action) => {
    switch (action.type) {
      case types.DOWNLOAD_DAILY_ERROR:
        return action.error;
      case types.DOWNLOAD_DAILY_LOADING:
      case types.DOWNLOAD_FORM_DATA_LOADING:
      case types.DOWNLOAD_HISTORICAL_LOADING:
      case types.DOWNLOAD_SEARCH_LOADING:
        return null;
      default:
        return state;
    }
  },

  /**
   * Updates daily download loading state
   * @param {boolean} state
   * @param {object} action
   * @param {string} action.type
   * @param {boolean} action.status
   * @memberof Reducers
   */
  dailyDownloadLoading: (state = false, action) => {
    switch (action.type) {
      case types.DOWNLOAD_DAILY_ERROR:
        return false;
      case types.DOWNLOAD_DAILY_LOADING:
        return !!action.status;
      default:
        return state;
    }
  },

  /**
   * Updates form data download error state
   * @param {string} state
   * @param {object} action
   * @param {string} action.type
   * @param {string} action.error
   * @memberof Reducers
   */
  formDataDownloadError: (state = null, action) => {
    switch (action.type) {
      case types.DOWNLOAD_FORM_DATA_ERROR:
        return action.error;
      case types.DOWNLOAD_DAILY_LOADING:
      case types.DOWNLOAD_FORM_DATA_LOADING:
      case types.DOWNLOAD_HISTORICAL_LOADING:
      case types.DOWNLOAD_SEARCH_LOADING:
        return null;
      default:
        return state;
    }
  },

  /**
   * Updates form data download loading state
   * @param {boolean} state
   * @param {object} action
   * @param {string} action.type
   * @param {boolean} action.status
   * @memberof Reducers
   */
  formDataDownloadLoading: (state = false, action) => {
    switch (action.type) {
      case types.DOWNLOAD_FORM_DATA_ERROR:
        return false;
      case types.DOWNLOAD_FORM_DATA_LOADING:
        return !!action.status;
      default:
        return state;
    }
  },

  /**
   * Updates historical data download error state
   * @param {string} state
   * @param {object} action
   * @param {string} action.type
   * @param {string} action.error
   * @memberof Reducers
   */
  historicalDownloadError: (state = null, action) => {
    switch (action.type) {
      case types.DOWNLOAD_HISTORICAL_ERROR:
        return action.error;
      case types.DOWNLOAD_DAILY_LOADING:
      case types.DOWNLOAD_FORM_DATA_LOADING:
      case types.DOWNLOAD_HISTORICAL_LOADING:
      case types.DOWNLOAD_SEARCH_LOADING:
        return null;
      default:
        return state;
    }
  },

  /**
   * Updates historical data download loading state
   * @param {boolean} state
   * @param {object} action
   * @param {string} action.type
   * @param {boolean} action.status
   * @memberof Reducers
   */
  historicalDownloadLoading: (state = false, action) => {
    switch (action.type) {
      case types.DOWNLOAD_HISTORICAL_ERROR:
        return false;
      case types.DOWNLOAD_HISTORICAL_LOADING:
        return !!action.status;
      default:
        return state;
    }
  },

  /**
   * Updates search data download error state
   * @param {string} state
   * @param {object} action
   * @param {string} action.type
   * @param {string} action.error
   * @memberof Reducers
   */
  searchDownloadError: (state = null, action) => {
    switch (action.type) {
      case types.DOWNLOAD_SEARCH_ERROR:
        return action.error;
      case types.DOWNLOAD_DAILY_LOADING:
      case types.DOWNLOAD_FORM_DATA_LOADING:
      case types.DOWNLOAD_HISTORICAL_LOADING:
      case types.DOWNLOAD_SEARCH_LOADING:
        return null;
      default:
        return state;
    }
  },

  /**
   * Updates search data download loading state
   * @param {boolean} state
   * @param {object} action
   * @param {string} action.type
   * @param {boolean} action.status
   * @memberof Reducers
   */
  searchDownloadLoading: (state = false, action) => {
    switch (action.type) {
      case types.DOWNLOAD_SEARCH_ERROR:
        return false;
      case types.DOWNLOAD_SEARCH_LOADING:
        return !!action.status;
      default:
        return state;
    }
  },

  /**
   * Updates system statuses state
   * @param {array} state
   * @param {object} action
   * @param {string} action.type
   * @param {array} action.statuses
   * @memberof Reducers
   */
  statuses: (state = [], action) => {
    switch (action.type) {
      case types.STATUS_ERROR:
      case types.STATUS_LOADING:
        return [];
      case types.STATUS_SUCCESS:
        return action.statuses;
      default:
        return state;
    }
  },

  /**
   * Updates status error state
   * @param {string} state
   * @param {object} action
   * @param {string} action.type
   * @param {string} action.error
   * @memberof Reducers
   */
  statusError: (state = null, action) => {
    switch (action.type) {
      case types.STATUS_ERROR:
        return action.error;
      case types.STATUS_LOADING:
      case types.STATUS_SUCCESS:
        return null;
      default:
        return state;
    }
  },

  /**
   * Updates status loading state
   * @param {boolean} state
   * @param {object} action
   * @param {string} action.type
   * @memberof Reducers
   */
  statusLoading: (state = false, action) => {
    switch (action.type) {
      case types.STATUS_ERROR:
      case types.STATUS_SUCCESS:
        return false;
      case types.STATUS_LOADING:
        return true;
      default:
        return state;
    }
  },

  /**
   * Status expires time
   * @param {object} state
   * @param {object} action
   * @param {string} action.type
   * @param {*} action.expires
   */
  statusExpires: (state = null, action) => {
    switch (action.type) {
      case types.STATUS_ERROR:
        return null;
      case types.STATUS_SUCCESS:
        return action.expires;
      default:
        return state;
    }
  },
};

/**
 * @namespace Actions
 */
export const actions = {
  /**
   * Cancel status API request
   * * Updated with a new Axios cancel token after an API request is initiated
   * @memberof Actions
   */
  cancelStatus: () => false,

  /**
   * Check if status needs to be updated from API
   * @memberof Actions
   */
  check: () => (dispatch, getState) => {
    const { statusExpires } = getState();
    return !!statusExpires && moment().isAfter(statusExpires);
  },

  /**
   * Download daily data from API
   * @memberof Actions
   */
  downloadDaily: () => dispatch => {
    dispatch(actions.handleDailyDownloadLoading(true));

    const errorHandler = "handleDailyDownloadError";

    return api
      .get(URL_API_STATUS_DOWNLOAD_DAILY)
      .then(response => {
        if (!!response.data) {
          const fallbackFileName = `holtused-daily-listing-data-${getFileStamp()}.csv`;
          const serverDefinedFileName =
            !!response.headers && response.headers["x-filename"];
          const fileName = serverDefinedFileName || fallbackFileName;
          saveAs(new Blob([response.data], { type: "text/csv" }), fileName);
          dispatch(actions.handleDailyDownloadLoading(false));
        } else {
          dispatch(actions.parseError(response, errorHandler));
        }
      })
      .catch(error => {
        dispatch(actions.parseError(error, errorHandler));
      });
  },
  /**
   * Download form data from API
   * @memberof Actions
   */
  downloadFormData: range => dispatch => {
    range = parseInt(range, 10);

    const errorHandler = "handleFormDataDownloadError";

    if (isNaN(range) || !range) {
      return dispatch(
        actions.parseError("Invalid form data range", errorHandler)
      );
    }

    dispatch(actions.handleFormDataDownloadLoading(true));

    return api
      .get(`${URL_API_STATUS_DOWNLOAD_FORM_DATA}?range=${range}`)
      .then(response => {
        if (!!response.data) {
          const fallbackFileName = `holtused-form-data-${getFileStamp()}.csv`;
          const serverDefinedFileName =
            !!response.headers && response.headers["x-filename"];
          const fileName = serverDefinedFileName || fallbackFileName;
          saveAs(new Blob([response.data], { type: "text/csv" }), fileName);
          dispatch(actions.handleFormDataDownloadLoading(false));
        } else {
          dispatch(actions.parseError(response, errorHandler));
        }
      })
      .catch(error => {
        dispatch(actions.parseError(error, errorHandler));
      });
  },

  /**
   * Download historical data from API
   * @memberof Actions
   */
  downloadHistorical: () => dispatch => {
    dispatch(actions.handleHistoricalDownloadLoading(true));

    const errorHandler = "handleHistoricalDownloadError";

    return api
      .get(URL_API_STATUS_DOWNLOAD_HISTORICAL)
      .then(response => {
        if (!!response.data) {
          const fallbackFileName = `holtused-historical-listing-data-${getFileStamp()}.csv`;
          const serverDefinedFileName =
            !!response.headers && response.headers["x-filename"];
          const fileName = serverDefinedFileName || fallbackFileName;
          saveAs(new Blob([response.data], { type: "text/csv" }), fileName);
          dispatch(actions.handleHistoricalDownloadLoading(false));
        } else {
          dispatch(actions.parseError(response, errorHandler));
        }
      })
      .catch(error => {
        dispatch(actions.parseError(error, errorHandler));
      });
  },

  /**
   * Download search data from API
   * @memberof Actions
   */
  downloadSearch: () => dispatch => {
    dispatch(actions.handleSearchDownloadLoading(true));

    const errorHandler = "handleSearchDownloadError";

    return api
      .get(URL_API_STATUS_DOWNLOAD_SEARCH)
      .then(response => {
        if (!!response.data) {
          const fallbackFileName = `holtused-search-parameters-data-${getFileStamp()}.csv`;
          const serverDefinedFileName =
            !!response.headers && response.headers["x-filename"];
          const fileName = serverDefinedFileName || fallbackFileName;
          saveAs(new Blob([response.data], { type: "text/csv" }), fileName);
          dispatch(actions.handleSearchDownloadLoading(false));
        } else {
          dispatch(actions.parseError(response, errorHandler));
        }
      })
      .catch(error => {
        dispatch(actions.parseError(error, errorHandler));
      });
  },

  /**
   * Fetch system status from API
   * @memberof Actions
   */
  fetch: () => dispatch => {
    dispatch(actions.handleStatusLoading());

    const errorHandler = "handleStatusError";

    return api
      .get(URL_API_STATUS, {
        cancelToken: new CancelToken(c => {
          actions.cancelStatus = c;
        }),
      })
      .then(response => {
        if (!!response && !!response.data && !!response.data.data) {
          let { statuses, users } = response.data.data;

          // Check if response is formatted as an object
          // and convert to an array if it is
          if (
            typeof users === "object" &&
            users !== null &&
            !Array.isArray(users)
          ) {
            users = Object.keys(users).map(key => ({
              name: key,
              login_count: users[key],
            }));
          }

          dispatch(actions.handleStatusSuccess(statuses, users));
        } else {
          dispatch(actions.parseError(response, errorHandler));
        }
      })
      .catch(error => {
        dispatch(actions.parseError(error, errorHandler));
      });
  },

  /**
   * Handle daily download error messages
   * @param {string} message
   * @memberof Actions
   */
  handleDailyDownloadError: message => ({
    type: types.DOWNLOAD_DAILY_ERROR,
    error: message,
  }),

  /**
   * Handle daily download loading events
   * @memberof Actions
   */
  handleDailyDownloadLoading: status => ({
    type: types.DOWNLOAD_DAILY_LOADING,
    status: status,
  }),

  /**
   * Handle form data download error messages
   * @param {string} message
   * @memberof Actions
   */
  handleFormDataDownloadError: message => ({
    type: types.DOWNLOAD_FORM_DATA_ERROR,
    error: message,
  }),

  /**
   * Handle form data download loading events
   * @memberof Actions
   */
  handleFormDataDownloadLoading: status => ({
    type: types.DOWNLOAD_FORM_DATA_LOADING,
    status: status,
  }),

  /**
   * Handle historical download error messages
   * @param {string} message
   * @memberof Actions
   */
  handleHistoricalDownloadError: message => ({
    type: types.DOWNLOAD_HISTORICAL_ERROR,
    error: message,
  }),

  /**
   * Handle historical download loading events
   * @memberof Actions
   */
  handleHistoricalDownloadLoading: status => ({
    type: types.DOWNLOAD_HISTORICAL_LOADING,
    status: status,
  }),

  /**
   * Handle search download error messages
   * @param {string} message
   * @memberof Actions
   */
  handleSearchDownloadError: message => ({
    type: types.DOWNLOAD_SEARCH_ERROR,
    error: message,
  }),

  /**
   * Handle search download loading events
   * @memberof Actions
   */
  handleSearchDownloadLoading: status => ({
    type: types.DOWNLOAD_SEARCH_LOADING,
    status: status,
  }),

  /**
   * Handle system status error messages
   * @param {string} message
   * @memberof Actions
   */
  handleStatusError: message => ({
    type: types.STATUS_ERROR,
    error: message,
  }),

  /**
   * Handle system status loading events
   * @memberof Actions
   */
  handleStatusLoading: () => ({
    type: types.STATUS_LOADING,
  }),

  /**
   * Handle system status API response
   * @memberof Actions
   */
  handleStatusSuccess: (statuses, users) => ({
    type: types.STATUS_SUCCESS,
    expires: moment().add(5, "minutes"),
    statuses: statuses,
    users: users,
  }),

  /**
   * Parse errors and dispatch error message
   * @param {*} error
   * @memberof Actions
   */
  parseError: (error, handler) => dispatch => {
    if (!!error.message && error.message === "Canceled") {
      return false;
    }

    const message = getApiErrorMessage(error);
    dispatch(actions[handler](message));
  },
};
