import React, { Component } from "react";
import { array, func, object, string } from "prop-types";
import * as Sentry from "@sentry/react";

import { Alert, Button, Spinner } from "reactstrap";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";

import PhotosGroups from "./Photos.groups";

import { REQUEST_MAX_SIZE } from "../../../constants";

class PhotosAvailable extends Component {
  constructor(props) {
    super(props);

    this.fsCrop = this.fsCrop.bind(this);
    this.fsInit = this.fsInit.bind(this);
    this.fsOnCrop = this.fsOnCrop.bind(this);
    this.fsOnError = this.fsOnError.bind(this);
    this.fsOnUpload = this.fsOnUpload.bind(this);
    this.handleCropOpen = this.handleCropOpen.bind(this);
    this.fsSelect = this.fsSelect.bind(this);
    this.fsUpload = this.fsUpload.bind(this);

    this._isMounted = false;
    this.cropper = null;
    this.uploader = null;

    this.state = {
      active: null,
      cropOpen: false,
      sortedPhotos: [],
      isUploading: false,
      fsUploadError: null,
    };
  }

  /**
   * Crop files
   * @param {array} fileHandle
   * @public
   */
  fsCrop(fileHandle) {
    const { cdn } = this.props;
    this.setState({ active: fileHandle });
    this.cropper.crop([`${cdn}/${fileHandle}`]);
  }

  /**
   * Initialize Filestack pickers for upload and crop
   * @public
   */
  fsInit() {
    try {
      const { handleCropOpen } = this;
      const { client } = this.props;

      this.cropper = client.picker({
        customText: {
          Upload: "Select",
        },
        onCancel: () => handleCropOpen(false),
        onFileUploadFailed: this.fsOnError,
        onOpen: () => handleCropOpen(true),
        onUploadDone: this.fsOnCrop,
        transformations: {
          circle: false,
          crop: true,
          rotate: true,
        },
      });

      this.uploader = client.picker({
        accept: "image/*",
        cleanupImageExif: true,
        customText: {
          "or Drag and Drop, Copy and Paste Files":
            "or Drag and Drop, Copy and Paste Photos",
          "Select Files from": "Select Photos from",
          "Select Files to Upload": "Select Photos to Upload",
          "Selected Files": "Selected Photos",
        },
        fromSources: ["box", "local_file_system"],
        maxFiles: 99,
        maxSize: REQUEST_MAX_SIZE,
        onFileUploadFailed: this.fsOnError,
        onUploadDone: this.fsOnUpload,
        transformations: {
          circle: false,
          crop: false,
          rotate: false,
        },
      });
    } catch (error) {
      this.fsOnError(error);
    }
  }

  /**
   * Handle cropped photos
   * @param {object} response
   * @public
   */
  fsOnCrop(response) {
    if (
      response.filesUploaded &&
      Array.isArray(response.filesUploaded) &&
      response.filesUploaded.length > 0
    ) {
      const { updatePhotos } = this.props;
      const { active } = this.state;
      updatePhotos("selectPhoto", response.filesUploaded[0].handle, active);
    }

    if (
      response.filesFailed &&
      Array.isArray(response.filesFailed) &&
      response.filesFailed.length > 0
    ) {
      const filenames = response.filesFailed.map(({ filename }) => filename);

      if (this._isMounted) {
        this.setState({
          active: null,
          fsUploadError: `${filenames.join(", ")} failed to be edited`,
        });
      }
    }
  }

  /**
   * Handle Filestack error
   * @param {*} error
   */
  fsOnError(error) {
    if (process.env.NODE_ENV !== "development") {
      try {
        Sentry.captureException(error);
      } catch (error) {}
    }

    if (this._isMounted) {
      this.setState({
        active: null,
        fsUploadError: error.message ? error.message : "Filestack error",
      });
    }
  }

  /**
   * Handle uploaded photos
   * @param {object} response
   * @public
   */
  fsOnUpload(response) {
    if (
      response.filesUploaded &&
      Array.isArray(response.filesUploaded) &&
      response.filesUploaded.length > 0
    ) {
      const { updatePhotos } = this.props;
      const newPhotos = response.filesUploaded.map(({ handle }) => ({
        original: handle,
        draftCropped: null,
        draftIndex: null,
        draftNew: false,
        liveCropped: null,
        liveIndex: null,
      }));
      updatePhotos("uploadPhotos", newPhotos);
    }

    if (
      response.filesFailed &&
      Array.isArray(response.filesFailed) &&
      response.filesFailed.length > 0
    ) {
      const filenames = response.filesFailed.map(({ filename }) => filename);

      if (this._isMounted) {
        this.setState({
          fsUploadError: `${filenames.join(", ")} failed to upload`,
        });
      }
    }
  }

  /**
   * Select a photo
   * @param {string} fileHandle
   * @public
   */
  fsSelect(fileHandle) {
    const { fsOnError } = this;
    const { cdn, client, updatePhotos } = this.props;

    try {
      client
        .storeURL(`${cdn}/${fileHandle}`)
        .then(response => {
          if (response && response.handle) {
            updatePhotos("selectPhoto", response.handle, fileHandle);
          } else {
            throw new Error("Photo was not selected");
          }
        })
        .catch(fsOnError);
    } catch (error) {
      fsOnError(error);
    }
  }

  /**
   * Upload photos
   * @public
   */
  fsUpload() {
    this.uploader.open();
  }

  /**
   * Handle crop open
   * @param {boolean} isOpen
   * @public
   */
  handleCropOpen(isOpen) {
    if (this._isMounted) {
      this.setState({ cropOpen: !!isOpen });
    }
  }

  componentDidMount() {
    this._isMounted = true;

    if (!!this.props.client) {
      this.fsInit();
    }

    this.setState({ sortedPhotos: getSortedPhotos(this.props.photos) });
  }

  componentDidUpdate(prevProps) {
    if (!!this.props.client && (!this.cropper || !this.picker)) {
      this.fsInit();
    }

    if (prevProps.photos?.length !== this.props.photos?.length) {
      this.setState({ sortedPhotos: getSortedPhotos(this.props.photos) });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.cropper = null;
    this.picker = null;
  }

  render() {
    const { fsCrop, fsSelect, fsUpload } = this;
    const { cdn, listingAction } = this.props;
    const { cropOpen, fsUploadError, sortedPhotos } = this.state;

    return !!cdn ? (
      <div className="border-top pt-4">
        <div className="d-md-flex mb-4">
          <h2 className="mb-0">Available Photos</h2>
          <Button
            className="font-weight-bold ml-md-auto px-3 text-uppercase"
            color="success"
            disabled={!!listingAction}
            onClick={fsUpload}
            size="sm"
            type="button"
          >
            <Icon className="mr-1_5" icon={faCloudUploadAlt} />
            {listingAction === "uploadPhotos" ? (
              <span>
                Uploading <Spinner className="ml-2" size="sm" />
              </span>
            ) : (
              "Upload Photos"
            )}
          </Button>
        </div>
        {fsUploadError && (
          <Alert className="text-center" color="danger">
            {fsUploadError}
          </Alert>
        )}
        {!!sortedPhotos.length ? (
          <PhotosGroups
            cdn={cdn}
            crop={fsCrop}
            cropOpen={cropOpen}
            photos={sortedPhotos}
            select={fsSelect}
          />
        ) : (
          <Alert className="py-5 text-center" color="primary">
            <p>No photos found. Would you like to upload some photos?</p>
            <Button
              className="font-weight-bold px-4 py-3 text-uppercase"
              color="primary"
              disabled={!!listingAction}
              onClick={fsUpload}
              type="button"
            >
              <Icon className="mr-1_5" icon={faCloudUploadAlt} />
              {listingAction === "uploadPhotos" ? (
                <span>
                  Uploading <Spinner className="ml-2" size="sm" />
                </span>
              ) : (
                "Upload Photos Now"
              )}
            </Button>
          </Alert>
        )}
      </div>
    ) : null;
  }
}

/**
 * Sort photos by lastModified date or availableIndex
 * with newest photos first
 *
 * @param {array} photos
 * @returns {array}
 */
function getSortedPhotos(photos) {
  if (!photos || !photos.length) {
    return [];
  }

  const sorted = photos.sort((a, b) => {
    // lastModifiedObj is a dayjs object created when the listing data is normalized
    if (!!a.lastModified && !!b.lastModified) {
      if (a.lastModified === b.lastModified) {
        return 0;
      } else {
        return a.lastModified < b.lastModified ? 1 : -1;
      }
    }
    // Fallback to available index which is a proxy for upload date
    // neither photo has a date object
    else if (!a.lastModified && !b.lastModified) {
      return b.availableIndex - a.availableIndex;
    }
    // Otherwise assume the photo without a date object is older
    else if (!a.lastModified) {
      return 1;
    } else if (!b.lastModified) {
      return -1;
    } else {
      return 0;
    }
  });

  // Create a new array so paged effect will fire
  return [...sorted];
}

PhotosAvailable.propTypes = {
  cdn: string.isRequired,
  client: object.isRequired,
  listingAction: string,
  photos: array.isRequired,
  updatePhotos: func.isRequired,
};

export default PhotosAvailable;
