import React, { PureComponent } from "react";
import { number } from "prop-types";
import qs from "query-string";

import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

import { history } from "../../helpers";

class PaginationNav extends PureComponent {
  constructor(props) {
    super(props);

    this.getPageNumbers = this.getPageNumbers.bind(this);
    this.requestPage = this.requestPage.bind(this);

    const { location } = history;

    this.state = {
      pathname: location.pathname,
      search: location.search,
    };
  }

  /**
   * Determine which page numbers should be displayed in navigation
   * @public
   */
  getPageNumbers() {
    const { pageCurrent, pageLast, pageNavLimit } = this.props;

    let minVisible = 1;
    let maxVisible = pageLast;

    if (pageLast > pageNavLimit) {
      const halfVisible = pageNavLimit / 2;
      if (pageCurrent - halfVisible < 0) {
        maxVisible = pageNavLimit + 1;
      } else if (pageCurrent + halfVisible > pageLast) {
        minVisible = pageLast - pageNavLimit;
      } else {
        minVisible = pageCurrent - halfVisible;
        maxVisible = pageCurrent + halfVisible;
      }
    }

    return [...Array(pageLast).keys()]
      .map(key => key + 1)
      .filter(
        pageNumber => pageNumber >= minVisible && pageNumber <= maxVisible
      );
  }

  /**
   * Push new page request to shared browser history
   * @param {number} pageNumber
   * @public
   */
  requestPage(pageNumber) {
    const { pathname, search } = this.state;
    const origQuery = qs.parse(search);
    const newQuery = { ...origQuery, page: pageNumber };

    if (pageNumber === 1) {
      delete newQuery.page;
    }

    history.push({
      pathname: pathname,
      search: qs.stringify(newQuery),
    });
  }

  componentDidUpdate() {
    const { location } = history;
    const { pathname, search } = this.state;

    if (location.pathname !== pathname || location.search !== search) {
      this.setState({
        pathname: location.pathname,
        search: location.search,
      });
    }
  }

  render() {
    const { getPageNumbers, requestPage } = this;
    const { pageCurrent, pageLast } = this.props;

    return (
      <div className="d-sm-flex">
        <Pagination
          aria-label="Page navigation"
          className="ml-sm-auto"
          listClassName="flex-wrap"
        >
          {pageCurrent > 1 && (
            <PaginationItem>
              <PaginationLink
                onClick={() => requestPage(pageCurrent - 1)}
                tag="button"
                type="button"
              >
                Previous
              </PaginationLink>
            </PaginationItem>
          )}
          {getPageNumbers().map(pageNumber => (
            <PaginationItem
              active={pageNumber === pageCurrent}
              key={pageNumber}
            >
              <PaginationLink
                onClick={() => requestPage(pageNumber)}
                tag="button"
                type="button"
              >
                {pageNumber}
              </PaginationLink>
            </PaginationItem>
          ))}
          {pageCurrent < pageLast && (
            <PaginationItem>
              <PaginationLink
                onClick={() => requestPage(pageCurrent + 1)}
                tag="button"
                type="button"
              >
                Next
              </PaginationLink>
            </PaginationItem>
          )}
        </Pagination>
      </div>
    );
  }
}

PaginationNav.propTypes = {
  /** Current page of listings results */
  pageCurrent: number,
  /** Total number of listing results pages */
  pageLast: number,
  /** Maximum number of visible pagination links */
  pageNavLimit: number,
};

PaginationNav.defaultProps = {
  pageNavLimit: 10,
};

export default PaginationNav;
