import moment from "moment-timezone";

/**
 * Get full year
 * @param {string} input
 */
export const getFullYear = input => {
  input = input.toString();

  switch (input.length) {
    case 1:
      return moment(`0${input}`, "YY").format("YYYY");
    case 2:
      return moment(input, "YY").format("YYYY");
    default:
      return input;
  }
};

/**
 * Get timestamp for filename
 */
export const getFileStamp = () => moment().format("YYYY-MM-DD-H-mm-ss");

/**
 * Get long date format
 * @param {string} timestamp
 */
export const getLongDate = timestamp =>
  moment.tz(timestamp, "UTC").tz(moment.tz.guess()).format("MMMM D, YYYY");

/**
 * Get short date timestamp localized for the user's timezone
 * @param {string} timestamp
 */
export const getShortStamp = timestamp =>
  moment
    .tz(timestamp, "UTC")
    .tz(moment.tz.guess())
    .format("MM-DD-YY @ h:mma z");

/**
 * Get date timestamp localized for the user's timezone
 * @param {string} timestamp
 */
export const getStamp = timestamp =>
  moment
    .tz(timestamp, "UTC")
    .tz(moment.tz.guess())
    .format("MM/DD/YYYY @ hh:mm:ssa z");
