import { connect } from "react-redux";

import { actions as categoriesActions } from "../categories";
import { actions as filterActions } from "../filter";
import { actions as listingActions } from "./Listing.redux";
import Listing from "./Listing.view";

const mapStateToProps = state => ({
  categoriesError: state.categoriesError,
  categoriesLoading: state.categoriesLoading,
  categoryOptions: state.categoryOptions,
  listing: state.listing,
  listingAction: state.listingAction,
  listingError: state.listingError,
  listingLoading: state.listingLoading,
  makes: state.makes,
  makesError: state.makesError,
});

const mapDispatchToProps = dispatch => ({
  clearListing: () => dispatch(listingActions.clear()),
  fetchCategoryOptions: categoryId =>
    dispatch(categoriesActions.fetchOptions()),
  fetchListing: equipmentId => dispatch(listingActions.fetch(equipmentId)),
  fetchMakes: () => dispatch(filterActions.fetchMakes()),
  updateListing: (action, update) =>
    dispatch(listingActions.update(action, update)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Listing);
