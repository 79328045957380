import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";

import { reducers as categoriesReducers } from "../modules/categories";
import { reducers as filestackReducers } from "../modules/filestack";
import { reducers as filterReducers } from "../modules/filter";
import { reducers as loginReducers } from "../modules/login";
import { reducers as listingReducers } from "../modules/listing";
import { reducers as statusReducers } from "../modules/status";
import { reducers as usersReducers } from "../modules/users";

import { HOST_ENV } from "../constants/url";

const initialState = {};

/**
 * Combines reducers imported from modules
 */
const appReducer = combineReducers({
  ...categoriesReducers,
  ...filestackReducers,
  ...filterReducers,
  ...loginReducers,
  ...listingReducers,
  ...statusReducers,
  ...usersReducers,
});

/**
 * Creates root reducer and provides method for clearing store
 * @param {object} state
 * @param {object} action
 */
const rootReducer = (state, action) => {
  if (action.type === "CLEAR_ALL") {
    state = undefined;
  }

  return appReducer(state, action);
};

/**
 * Applies Redux enhancers
 * thunk
 */
const composeEnhancers =
  (HOST_ENV !== "production" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;
const enhancer = composeEnhancers(applyMiddleware(thunkMiddleware));

/**
 * Creates Redux store
 */
const store = createStore(rootReducer, initialState, enhancer);

export default store;
