const {
  REACT_APP_GA_ID,
  REACT_APP_SENTRY_DSN,
  REACT_APP_SITE_AUTH,
  REACT_APP_SITE_DEFAULT,
  REACT_APP_SITE_TITLE,
  REACT_APP_SITE_VERSION,
} = process.env;

/**
 * `GA_ID` - Google Analytics ID
 * @constant gaid
 * @default
 */
export const GA_ID = REACT_APP_GA_ID || null;

/**
 * `SITE_AUTH` - Retrieves `REACT_APP_SITE_AUTH` from .env file,
 * key name used when saving authentication to local storage
 * @constant siteauth
 * @default
 */
export const SITE_AUTH = REACT_APP_SITE_AUTH || "auth";

/**
 * `SITE_TITLE` - Retrieves `REACT_APP_SITE_TITLE` from .env file,
 * used in <title> tag
 * @constant sitetitle
 * @default
 */
export const SITE_TITLE = REACT_APP_SITE_TITLE || "Admin";

/**
 * `SITE_DEFAULT` - Retrieves `REACT_APP_SITE_DEFAULT` from .env file,
 * redirect used for default route
 * @constant sitedefault
 * @default
 */
export const SITE_DEFAULT = REACT_APP_SITE_DEFAULT || "/pending";

/**
 * `SITE_SHOW_STATUSES` - Statuses that should be displayed
 * @constant siteshowstatuses
 * @default
 */
export const SITE_SHOW_STATUSES = ["pending", "sold"];

/**
 * `SITE_SOURCE_OPTIONS` - Source options for search filters
 * @constant sitesourceoptions
 * @default
 */
export const SITE_SOURCE_OPTIONS = [
  { label: "Attachments", value: "attachments" },
  { label: "CAT Financial", value: "catFinancial" },
  { label: "Consignment", value: "consignment" },
  { label: "Cranes", value: "crane" },
  { label: "Joint Venture", value: "jv" },
  { label: "Kyrish", value: "kyrish" },
  { label: "HOLT Heavy Rentals", value: "heavy" },
  { label: "HOLT Heavy Used Equipment", value: "heavyUsed" },
  { label: "Texas First Rentals", value: "texasFirst" },
  { label: "Trucks", value: "truck" },
];

/**
 * `SITE_SOURCE_OPTIONS` - Source options for search filters
 *
 * * Add optional `hide: true` property to omit from navigation
 *
 * @constant sitesourceoptions
 * @default
 */
export const SITE_DATA_UPLOAD_OPTIONS = [
  {
    path: "consignment",
    type: "consignment",
    title: "Consignment Machines",
    sentence: "consignment machines",
    format: "serial-numbers",
  },
  {
    path: "crane",
    type: "crane",
    title: "Cranes",
    sentence: "cranes",
    format: "serial-numbers",
  },
];

/**
 * `SITE_PHOTOS_GROUPS` - used for grouping listing photos
 *
 * * Sort order is important
 *
 * @constant sitephotosgroups
 * @default
 */
export const SITE_PHOTOS_GROUPS = [
  {
    id: "wash-rack",
    label: "Wash Rack",
    type: "x-new-from-factory-check-in",
  },
  {
    id: "other",
    label: "Other",
    type: "other",
  },
];

/**
 * `SITE_VERSION` - current package.json version
 * @constant siteversion
 * @default
 */
export const SITE_VERSION = REACT_APP_SITE_VERSION || null;

/**
 * `SENTRY_DSN` - where to send Sentry events
 * @constant sentrydsn
 * @default
 */
export const SENTRY_DSN = REACT_APP_SENTRY_DSN || null;
