import jwtDecode from "jwt-decode";
import moment from "moment";

import {
  api,
  apiAuthorize,
  apiDeauthorize,
  getApiErrorMessage,
  history,
} from "../../helpers";

import { SITE_AUTH, URL_API_LOGIN, URL_API_LOGOUT } from "../../constants";

/**
 * Action types
 * @property {string} AUTH_CLEARED
 * @property {string} AUTH_EXPIRED
 * @property {string} AUTH_SUCCESS
 */
export const types = {
  AUTH_CLEARED: "CLEAR_ALL",
  AUTH_EXPIRED: "AUTH_EXPIRED",
  AUTH_SUCCESS: "AUTH_SUCCESS",
};

// prettier-ignore
/**
 * @typedef {object} state.auth
 * @property {number} expires - UNIX timestamp retrieved from JWT
 * @property {string} message - Message displayed if the authentication token is rejected
 * @property {object} user - User information returned by initial authentication request
 * @property {string} user.name
 * @property {string} user.email
 * @property {array}  user.permissions
 */
export const initialState = {
  expires: null,
  message: null,
  user: null,
};

/**
 * @namespace Reducers
 */
export const reducers = {
  /**
   * Updates auth state
   * @param {object} state
   * @param {object} action
   * @param {string} action.type - `AUTH_SUCCESS`, `AUTH_EXPIRED`, or no change
   * @param {number} action.expires
   * @param {string} action.message
   * @param {object} action.user
   * @return {state.auth}
   * @memberof Reducers
   */
  auth: (state = initialState, action) => {
    switch (action.type) {
      case types.AUTH_SUCCESS:
        return {
          expires: action.expires,
          message: null,
          user: action.user,
        };
      case types.AUTH_EXPIRED:
        return {
          ...state,
          expires: null,
          message: action.message,
        };
      default:
        return state;
    }
  },
};

/**
 * @namespace Actions
 */
export const actions = {
  /**
   * Checks if the authentication expires time can be found
   * in the Redux store or local storage, and then either
   * adds the authentication header to API requests or
   * expires the current authentication
   * @memberof Actions
   * @returns {bool}
   */
  check: () => (dispatch, getState) => {
    let state = getState();

    if (!state.auth.expires) {
      dispatch(actions.retrieve());
      state = getState();
    }

    if (
      state.auth.expires &&
      moment.unix(state.auth.expires).isAfter(moment())
    ) {
      return true;
    }

    if (!state.auth.message) {
      dispatch(
        actions.expire(state.auth.user ? "Authentication expired" : null)
      );
    }

    return false;
  },

  /**
   * Removes the authentication header from API requests
   * and removes the authentication from local storage
   * @memberof Actions
   */
  destroy: () => dispatch => {
    apiDeauthorize();
    localStorage.removeItem(SITE_AUTH);
  },

  /**
   * Dispatches the actions to set the authentication message
   * and destroy the current authentication
   * @param {string} message
   * @param {bool} shouldRedirect
   * @memberof Actions
   */
  expire: (message, shouldRedirect) => dispatch => {
    if (message) {
      dispatch(actions.expiredMessage(message));
    }
    dispatch(actions.destroy());
    if (shouldRedirect && !!window) {
      const { pathname, search, hash } = history.location;
      const redirect = {
        pathname: "/login",
        state: { from: { pathname, search, hash } },
      };
      history.push(redirect);
    }
  },

  /**
   * Sets the authentication message displayed on the login
   * page when the user's authentication expires
   * @param {string} message
   * @memberof Actions
   * @returns {object}
   */
  expiredMessage: message => ({
    type: types.AUTH_EXPIRED,
    message: message,
  }),

  /**
   * Posts the user's login credentials to the API and then either
   * dispatches the action to update the authentication state and
   * save the authentication to local storage or handle any errors
   * returned by the API
   * @memberof Actions
   * @returns {Promise}
   */
  login: data => dispatch => {
    return api
      .post(URL_API_LOGIN, data)
      .then(response => {
        if (response.data && response.data.access_token && response.data.user) {
          const auth = {
            access_token: response.data.access_token,
            user: response.data.user,
          };

          localStorage.setItem(SITE_AUTH, JSON.stringify(auth));

          dispatch(actions.update(response.data));

          return { success: true };
        } else {
          return { success: false, message: getApiErrorMessage(response) };
        }
      })
      .catch(error => {
        const message = getApiErrorMessage(error);

        return {
          success: false,
          message: message,
        };
      });
  },

  /**
   * Requests that API invalidate the JWT currently being used
   * to authenticate the user and then dispatches actions to
   * destroy the current authentication and reset the Redux store
   * @memberof Actions
   */
  logout: () => (dispatch, getState) => {
    const { auth } = getState();

    if (auth.expires) {
      api
        .get(URL_API_LOGOUT)
        .catch(error => getApiErrorMessage)
        .then(() => {
          dispatch(actions.destroy());
        });
    } else {
      dispatch(actions.destroy());
    }

    dispatch({ type: types.AUTH_CLEARED });
    history.push("/login");
  },

  /**
   * Retrieve any authentication saved to local storage and
   * dispatch the action to update the Redux store
   * @memberof Actions
   * @returns {bool|void}
   */
  retrieve: () => dispatch => {
    const local = localStorage.getItem(SITE_AUTH);
    const auth = local && JSON.parse(local);

    if (auth) {
      dispatch(actions.update(auth));
    } else {
      return false;
    }
  },

  /**
   * Sets the authentication state with the decoded authentication
   * @param {object} decoded
   * @memberof Actions
   * @returns {object}
   */
  success: decoded => ({
    type: types.AUTH_SUCCESS,
    expires: decoded.exp,
    user: decoded.user,
  }),

  /**
   * Decodes the JWT contained within the current authentication
   * and either dispatches the action to expire the authentication
   * if something is wrong with it or dispatches the action to set
   * new authentication state and add the authentication header to
   * API requests
   * @param {object} auth
   * @param {string} access_token - JWT
   * @param {object} user
   * @memberof Actions
   */
  update: auth => dispatch => {
    try {
      const decoded = jwtDecode(auth.access_token);

      if (!decoded || !decoded.exp) {
        dispatch(actions.expire("Authentication not found"));
      }

      decoded.user = auth.user;

      // Work around late stage API change that separated
      // name field into separate first and last name fields
      if (!!decoded.user.first_name && !!decoded.user.last_name) {
        decoded.user.name = `${decoded.user.first_name} ${decoded.user.last_name}`;
      }

      dispatch(actions.success(decoded));
      apiAuthorize(auth.access_token);
    } catch (error) {
      const errorMessage =
        error.name && error.name === "InvalidTokenError"
          ? "Authentication invalid"
          : null;
      dispatch(actions.expire(errorMessage));
    }
  },

  /**
   * Updates the auth token when refreshed
   * @param {string} authToken
   */
  updateAuthToken: authToken => (dispatch, getState) => {
    try {
      const { auth } = getState();
      const decoded = jwtDecode(authToken);

      if (!decoded || !decoded.exp) {
        dispatch(actions.expire("Authentication not found"));
      }

      decoded.user = auth.user;

      localStorage.setItem(
        SITE_AUTH,
        JSON.stringify({
          access_token: authToken,
          user: auth.user,
        })
      );

      dispatch(actions.success(decoded));
    } catch (error) {
      const errorMessage =
        error.name && error.name === "InvalidTokenError"
          ? "Authentication invalid"
          : null;
      dispatch(actions.expire(errorMessage));
    }
  },
};
