import { SITE_SOURCE_OPTIONS } from "../constants";

/**
 * Return a number with the specified precision
 * without trailing zeros
 * @param {string, number} value
 * @param {number} [precision=2]
 */
export const formatPrecision = (value, precision = 2) => {
  value = parseFloat(value);
  return value.toFixed(precision).toString().replace(/.00$/, "");
};

/**
 * Return a comma-formatted number
 * @param {number, string} value
 */
export const formatNumber = value => {
  const number = formatPrecision(value);
  let parts = number.split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

/**
 * Return a user-friendly value for source division
 * @param {string} source
 */
export const formatSourceDivision = source => {
  if (!source) {
    return "–";
  }

  const found = SITE_SOURCE_OPTIONS.find(option => option.value === source);

  return (!!found && found.label) || formatTitleCase(source);
};

/**
 * Return a title-cased string
 * @param {string} value
 */
export const formatTitleCase = value =>
  !!value
    ? value
        .toString()
        .toLowerCase()
        .split(" ")
        .map(word => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
        .join(" ")
    : null;

/**
 * Return the first letter in a string upper-cased
 * @param {string} value
 */
export const formatUpperInitial = value =>
  !!value ? value.toString().charAt(0).toUpperCase() : null;

/**
 * Get base filename from path
 * @param {string} path
 */
export const getBaseName = path => path.toString().split(/[\\/]/).pop();

/**
 * Get excerpt
 * @param {string} value
 * @param {number} maxLength
 * @param {string} separator
 * @param {string} terminator
 */
export const getExcerpt = (
  value,
  maxLength = 90,
  separator = " ",
  terminator = "…"
) => {
  const text = getText(value).trim();
  const excerpt =
    text.length <= maxLength
      ? text
      : text
          .substr(0, text.lastIndexOf(separator, maxLength))
          .replace(/[^a-z0-9]$/i, "")
          .trim() + terminator;
  return excerpt;
};

/**
 * Get file size from bytes
 * @param {number} bytes
 */
export const getFileSize = bytes => {
  const sizeKb = 1024;
  const sizeMb = sizeKb * sizeKb;
  const sizeGb = sizeKb * sizeMb;
  let formatted = bytes;

  if (bytes) {
    if (bytes >= sizeGb) {
      formatted = `${bytes / sizeGb} GB`;
    } else if (bytes >= sizeMb) {
      formatted = `${bytes / sizeMb} MB`;
    } else if (bytes >= sizeKb / 2) {
      formatted = `${bytes / sizeKb} KB`;
    } else if (bytes > 1) {
      formatted = `${bytes} bytes`;
    } else {
      formatted = `${bytes} byte`;
    }
  }

  return formatted;
};

/**
 * Extract plain text from HTML
 * @param {string} html
 */
export const getText = html => {
  const tmp = document.createElement("div");
  tmp.innerHTML = html;
  const text = tmp.textContent || tmp.innerText || "";
  return text.replace(/([^\s][.?!])([^\s])/gi, "$1 $2");
};

/**
 * Remove protocol
 * @param {string} url
 */
export const removeProtocol = url => url.toString().replace(/^https?:\/\//, "");
