import React, { PureComponent } from "react";
import { number } from "prop-types";

/**
 * Primary page footer with copyright
 */
class LayoutFooter extends PureComponent {
  render() {
    const { year } = this.props;

    return (
      <footer className="layout-footer">
        <p>
          © {year} HOLT Texas, Ltd. d/b/a HOLT CAT. All rights reserved. HOLT is
          a registered trademark of HOLT Texas, Ltd.
        </p>
      </footer>
    );
  }
}

LayoutFooter.propTypes = {
  /** Current year, to be displayed in copyright */
  year: number,
};

LayoutFooter.defaultProps = {
  year: new Date().getFullYear(),
};

export default LayoutFooter;
