import { connect } from "react-redux";

import { actions as statusActions } from "../status";
import Dashboard from "./Dashboard.view";

const mapStateToProps = state => ({
  adminLogins: state.adminLogins,
  dailyDownloadError: state.dailyDownloadError,
  dailyDownloadLoading: state.dailyDownloadLoading,
  formDataDownloadError: state.formDataDownloadError,
  formDataDownloadLoading: state.formDataDownloadLoading,
  historicalDownloadError: state.historicalDownloadError,
  historicalDownloadLoading: state.historicalDownloadLoading,
  searchDownloadError: state.searchDownloadError,
  searchDownloadLoading: state.searchDownloadLoading,
  statusError: state.statusError,
  statusLoading: state.statusLoading,
});

const mapDispatchToProps = dispatch => ({
  cancelStatus: () => statusActions.cancelStatus(),
  checkStatus: () => dispatch(statusActions.check()),
  clearDailyDownloadError: () =>
    dispatch(statusActions.handleDailyDownloadError(null)),
  clearFormDataDownloadError: () =>
    dispatch(statusActions.handleFormDataDownloadError(null)),
  clearHistoricalDownloadError: () =>
    dispatch(statusActions.handleHistoricalDownloadError(null)),
  clearSearchDownloadError: () =>
    dispatch(statusActions.handleSearchDownloadError(null)),
  clearStatusError: () => dispatch(statusActions.handleStatusError(null)),
  downloadDaily: () => dispatch(statusActions.downloadDaily()),
  downloadFormData: range => dispatch(statusActions.downloadFormData(range)),
  downloadHistorical: () => dispatch(statusActions.downloadHistorical()),
  downloadSearch: () => dispatch(statusActions.downloadSearch()),
  fetchStatus: () => dispatch(statusActions.fetch()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
