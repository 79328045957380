import React, { PureComponent } from "react";
import { number, shape, string } from "prop-types";
import classNames from "classnames";

import ListingCopy from "./Listing.copy";

import { URL_PUBLIC } from "../../../constants";
import { displayMeter, formatNumber } from "../../../helpers";

/**
 * Grid for displaying listing metadata
 */
class ListingGrid extends PureComponent {
  render() {
    const { listing } = this.props;

    return (
      <div className="listing-grid">
        <ul>
          <li>
            <h6>Meter</h6>
            {displayMeter(listing) ? (
              <p>
                {formatNumber(listing.meterValue)}{" "}
                <small>{listing.meterType.toUpperCase()}</small>
              </p>
            ) : (
              <p>–</p>
            )}
          </li>
          <li>
            <h6>Equipment ID</h6>
            <p>{!!listing.equipmentId ? listing.equipmentId : "–"}</p>
          </li>
          <li>
            <h6>Serial #</h6>
            <p>{!!listing.serial ? listing.serial : "–"}</p>
          </li>
          <li>
            <h6>Account #</h6>
            <p>{!!listing.accountNumber ? listing.accountNumber : "–"}</p>
          </li>
          <li className="w66">
            <h6>Source</h6>
            <p>{!!listing.sourceDivision ? listing.sourceDivision : "–"}</p>
          </li>
          <li className="price">
            <h6>{!!listing.salePrice ? "Sale Price" : "Advertised"}</h6>
            {!!listing.salePrice ? (
              <p>
                <span className="text-special">
                  ${formatNumber(listing.salePrice)}
                </span>
                {!!listing.advertisedPrice && (
                  <s className="d-block font-weight-normal pt-1 small text-muted">
                    ${formatNumber(listing.advertisedPrice)}
                  </s>
                )}
              </p>
            ) : (
              <p
                className={classNames({
                  "text-danger":
                    !!listing.advertisedPrice &&
                    !!listing.marketPrice &&
                    listing.advertisedPrice < listing.marketPrice,
                })}
              >
                {!!listing.advertisedPrice
                  ? `$${formatNumber(listing.advertisedPrice)}`
                  : "–"}
              </p>
            )}
          </li>
          <li className="price">
            <h6>Asking Price</h6>
            <p>
              {!!listing.marketPrice
                ? `$${formatNumber(listing.marketPrice)}`
                : "–"}
            </p>
          </li>
          <li className="price">
            <h6>Support Dollars</h6>
            <p>
              {!!listing.supportPrice
                ? `$${formatNumber(listing.supportPrice)}`
                : "–"}
            </p>
          </li>
        </ul>
        {listing.publishStatus === "published" && (
          <div className="published">
            <h6>Published Version</h6>
            <p>
              <a
                href={`${URL_PUBLIC}/${listing.slug}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                View Public Listing
              </a>
              <ListingCopy
                text="Copy Link"
                size="sm"
                value={`${URL_PUBLIC}/${listing.slug}`}
              />
            </p>
          </div>
        )}
      </div>
    );
  }
}

ListingGrid.propTypes = {
  listing: shape({
    equipmentId: string,
    advertisedPrice: number,
    meterType: string,
    meterValue: number,
    marketPrice: number,
    publishStatus: string,
    serial: string,
    slug: string,
    supportPrice: number,
  }).isRequired,
};

export default ListingGrid;
