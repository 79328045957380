import React, { Component, Fragment } from "react";
import { bool, func, object, number, shape, string } from "prop-types";
import classNames from "classnames";
import moment from "moment";

import { Alert, Badge, Button, Container, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";

import Layout from "../layout";
import { SwitchGroup } from "../listing";

import { PhotosAvailable, PhotosPreview, PhotosSort } from "./components";

import { SITE_DEFAULT, SITE_SHOW_STATUSES } from "../../constants";
import { history, scrollIfNeeded } from "../../helpers";

/**
 * Allows the user to:
 * * Select photos for listing photo gallery
 * * Upload new photos
 * * Define sort order
 * Uses Filestack to manage image assets and stores
 * the current state of those assets to the API.
 * Original photos are displayed in available photos
 * and are never deleted. The original handle links
 * draft and live images to their original source.
 * Live photos are never displayed on this page
 * so that they are never deleted by mistake.
 * The photo gallery always the draft photos,
 * but status of the photo gallery only switches
 * to draft when it is out of sync with the live photos.
 */
class Photos extends Component {
  constructor(props) {
    super(props);

    this.goBack = this.goBack.bind(this);
    this.handleErrors = this.handleErrors.bind(this);
    this.handleIsDraft = this.handleIsDraft.bind(this);
    this.handleIsInternal = this.handleIsInternal.bind(this);
    this.requestUpdate = this.requestUpdate.bind(this);
    this.sortPhotos = this.sortPhotos.bind(this);
    this.togglePreview = this.togglePreview.bind(this);

    this._isMounted = false;
    this.alertError = React.createRef();
    this.gallery = React.createRef();

    this.state = {
      hasChanges: false,
      isDraft: true,
      isInternal: true,
      listingErrors: [],
      preview: false,
      removeInProgress: null,
      sortedPhotos: [],
      showStatus: false,
      title: "Review Photos",
    };
  }

  /**
   * Go back to previous page, if known,
   * or default page, if not known
   * @param {event|object} e
   * @public
   */
  goBack(e) {
    const destination =
      history.location.state && history.location.state.goBack
        ? history.location.state && history.location.state.goBack
        : SITE_DEFAULT;
    const confirmation = e.confirmation ? e : null;
    history.push(destination, confirmation);
  }

  /**
   * Handle errors
   * @param {array} errors
   * @public
   */
  handleErrors(errors) {
    if (
      this._isMounted &&
      JSON.stringify(errors) !== JSON.stringify(this.state.listingErrors)
    ) {
      this.setState({ listingErrors: errors }, () => {
        if (errors.length > 0 && this.alertError.current) {
          scrollIfNeeded(this.alertError.current);
        }
      });
    }
  }

  /**
   * Handle change to isDraft state
   * @param {bool} next
   * @public
   */
  handleIsDraft(next) {
    this.setState({ isDraft: !!next });
  }

  /**
   * Handle change to isInternal state
   * @param {bool} next
   * @public
   */
  handleIsInternal(next) {
    this.setState({ isInternal: !!next });
  }

  /**
   * Request to update listing
   * @param {string} action
   * @param {object} updatedPhotos
   * @param {string} original
   */
  requestUpdate(action, updatedPhoto, original) {
    const { listing, updateListing } = this.props;
    const { draftPhotos, photos } = listing;
    const { isDraft, isInternal } = this.state;
    let changes = {};

    if (action === "selectPhoto") {
      changes.photos = photos.map(photo => {
        if (photo.original === original) {
          photo.draftCropped = updatedPhoto;
          photo.draftIndex = draftPhotos.length;
          photo.draftNew = true;
        }
        return photo;
      });
    } else if (action === "uploadPhotos") {
      changes.photos = photos.concat(updatedPhoto);
    } else if (["saveFromPhotos", "saveGoToForm"].includes(action)) {
      changes.draftPhotos = this.state.sortedPhotos;

      if (isDraft) {
        changes.publishStatus = "unpublished";
      } else if (listing.originalValues.publishStatus !== "unpublished") {
        changes.publishStatus = listing.originalValues.publishStatus;
      } else {
        changes.publishStatus = "published";
      }

      changes.isInternal = isInternal ? 1 : 0;
    } else {
      changes = updatedPhoto;
    }

    if (action === "removeDraftPhoto") {
      this.setState({ removeInProgress: updatedPhoto });
    }

    updateListing(action, changes)
      .then(response => {
        if (this._isMounted) {
          switch (action) {
            case "saveGoToForm":
              history.push({
                pathname: `/listing/${listing.equipmentId}`,
                state: history.location.state,
              });
              break;
            case "saveFromPhotos":
              this.goBack({
                confirmation: {
                  type: isDraft ? "unpublished" : "published",
                  slug: listing.slug,
                  isInternal: changes.isInternal,
                  equipmentId: listing.equipmentId,
                },
              });
              break;
            case "removeDraftPhoto":
              this.setState({ removeInProgress: null });
              break;
            default:
              break;
          }
        }
      })
      .catch(error => {
        if (this._isMounted && action === "removeDraftPhoto") {
          this.setState({ removeInProgress: null });
        }
        return false;
      });
  }

  /**
   * Update sorting of photos
   * @param {array} sorted
   * @public
   */
  sortPhotos(sorted) {
    this.setState({
      sortedPhotos: sorted,
    });
  }

  /**
   * Toggle preview
   */
  togglePreview() {
    this.setState(prevState => ({
      preview: !prevState.preview,
    }));
  }

  componentDidMount() {
    this._isMounted = true;

    const { filestack, fetchListing, filestackInit, match } = this.props;

    if (!match || !match.params || !match.params.equipmentId) {
      this.handleErrors(["Request missing Equipment ID"]);
      return false;
    }

    if (!filestack || moment.unix(filestack.expires).isBefore(moment())) {
      filestackInit();
    }

    fetchListing(match.params.equipmentId);
  }

  componentDidUpdate(prevProps, prevState) {
    const { filestackError, listing, listingError } = this.props;
    const errors = [];

    if (!!filestackError) {
      errors.push(filestackError);
    }

    if (!!listingError) {
      errors.push(listingError);
    }

    if (errors !== prevState.errors) {
      this.handleErrors(errors);
    }

    // Set page title
    if (
      !!listing &&
      (!prevProps.listing ||
        listing.equipmentId !== prevProps.listing.equipmentId)
    ) {
      this.setState({
        showStatus:
          !!listing.status && SITE_SHOW_STATUSES.includes(listing.status),
        title: `Photos for ${listing.title})`,
      });
    }

    // Deal with draft photo sorting
    if (
      !prevProps.listing ||
      (!!listing &&
        JSON.stringify(listing.draftPhotos) !==
          JSON.stringify(prevProps.listing.draftPhotos))
    ) {
      const { sortedPhotos } = this.state;
      const newSort = !!listing.draftPhotos
        ? listing.draftPhotos.slice().sort((a, b) => {
            let aIndex = sortedPhotos.findIndex(
              photo => photo.draftCropped === a.draftCropped
            );
            let bIndex = sortedPhotos.findIndex(
              photo => photo.draftCropped === b.draftCropped
            );
            aIndex = aIndex > -1 ? aIndex : 999;
            bIndex = bIndex > -1 ? bIndex : 999;
            return aIndex - bIndex;
          })
        : [];
      this.setState({
        sortedPhotos: newSort,
      });
    }

    if (!!listing && !!listing.originalValues) {
      const { originalValues } = listing;
      const { isDraft, isInternal } = this.state;
      const hasChanges =
        (isDraft && originalValues.publishStatus !== "unpublished") ||
        (!isDraft && originalValues.publishStatus === "unpublished") ||
        (isInternal && parseInt(originalValues.isInternal, 10) !== 1) ||
        (!isInternal && parseInt(originalValues.isInternal, 10) === 1);

      if (hasChanges !== prevState.hasChanges) {
        this.setState({ hasChanges: hasChanges });
      }

      // Derive boolean isDraft value from publishStatus prop string
      // and boolean isInternal value from isInternal prop integer
      // on initial load
      if (
        !!listing &&
        (!prevProps.listing ||
          listing.equipmentId !== prevProps.listing.equipmentId)
      ) {
        const initIsDraft = listing.publishStatus === "unpublished";
        if (initIsDraft !== isDraft) {
          this.setState({ isDraft: initIsDraft });
        }

        const initIsInternal = parseInt(listing.isInternal, 10) === 1;
        if (initIsInternal !== isInternal) {
          this.setState({ isInternal: initIsInternal });
        }
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.props.clearListing();
  }

  render() {
    const {
      alertError,
      gallery,
      goBack,
      handleIsDraft,
      handleIsInternal,
      requestUpdate,
      sortPhotos,
      togglePreview,
    } = this;
    const { filestack, listing, listingAction, listingLoading } = this.props;
    const { hasDraftPhotos } = listing;
    const {
      hasChanges,
      isDraft,
      isInternal,
      listingErrors,
      preview,
      removeInProgress,
      sortedPhotos,
      showStatus,
      title,
    } = this.state;

    return (
      <Layout title={title}>
        <Container tag="article">
          <header>
            <p className="h1">
              {!!listing && !!listing.sourceType && (
                <span className="d-block h6 mt-n3 position-absolute text-muted">
                  {listing.sourceType} Equipment
                </span>
              )}
              Review Photos
            </p>
          </header>
          <section>
            <div className="layout-content">
              {listingLoading && (
                <Alert className="p-4" color="info">
                  <div className="align-items-center d-flex justify-content-center">
                    <Spinner />
                    <p className="mb-0 ml-3 pr-5">
                      <strong>Loading…</strong>
                    </p>
                  </div>
                </Alert>
              )}
              <div ref={alertError}>
                {!listingLoading && listingErrors.length > 0 && (
                  <Alert className="mb-4_5 p-4 text-center" color="danger">
                    {listingErrors.map((error, index) => (
                      <p
                        className={classNames({
                          "mb-0": index + 1 === listingErrors.length,
                        })}
                        key={`error-${index}`}
                      >
                        <strong>{error}</strong>
                      </p>
                    ))}
                  </Alert>
                )}
              </div>
              {!listingLoading &&
                !!listing &&
                listing.photos &&
                !!filestack &&
                !!filestack.cdn && (
                  <Fragment>
                    <h1
                      className={classNames({
                        "align-items-sm-end d-sm-flex":
                          hasDraftPhotos || showStatus,
                      })}
                    >
                      <span>
                        {!!listing.year && (
                          <Fragment>
                            <strong>{listing.year}</strong>{" "}
                          </Fragment>
                        )}
                        <span>
                          {listing.make}{" "}
                          {!!listing.make && !!listing.model && "–"}{" "}
                          {listing.model}
                        </span>
                      </span>
                      {hasDraftPhotos && (
                        <span className="d-block d-sm-inline smallest">
                          <Badge
                            className="mb-sm-2 text-uppercase ml-sm-4"
                            color="secondary"
                          >
                            Draft Photos
                          </Badge>
                        </span>
                      )}
                      {showStatus && (
                        <span className="d-block d-sm-inline smallest">
                          <Badge
                            className={classNames({
                              "mb-2 text-uppercase": true,
                              "ml-sm-2": !!hasDraftPhotos,
                              "ml-sm-4": !hasDraftPhotos,
                            })}
                            color="danger"
                          >
                            Sold
                          </Badge>
                        </span>
                      )}
                    </h1>
                    <div
                      className="align-items-baseline d-md-flex mb-3 mt-4"
                      ref={gallery}
                    >
                      <h2>Listing Photo Gallery</h2>
                      {sortedPhotos.length > 0 && (
                        <Button
                          className="ml-md-auto px-0"
                          color="link"
                          onClick={togglePreview}
                          size="sm"
                          type="button"
                        >
                          <Icon className="mr-1_5" icon={faImage} />
                          PREVIEW Photo Gallery
                        </Button>
                      )}
                    </div>
                    <PhotosSort
                      cdn={filestack.cdn}
                      client={filestack.client}
                      photos={sortedPhotos}
                      removeInProgress={removeInProgress}
                      sortPhotos={sortPhotos}
                      updatePhotos={requestUpdate}
                    />
                    <PhotosAvailable
                      cdn={filestack.cdn}
                      client={filestack.client}
                      listingAction={listingAction}
                      photos={listing.photos.filter(
                        photo => !photo.draftCropped
                      )}
                      updatePhotos={requestUpdate}
                    />
                    <div className="listing-form">
                      <div className="form-actions pt-4">
                        <SwitchGroup
                          checked={isInternal}
                          handleChange={handleIsInternal}
                          id="isInternal"
                          offLabel="Public-Facing"
                          onColor="brand"
                          onLabel="Internal Only"
                        />
                        <SwitchGroup
                          checked={isDraft}
                          className="ml-lg-auto"
                          handleChange={handleIsDraft}
                          id="isDraft"
                          offLabel="Live"
                          onLabel="Draft"
                        />
                      </div>
                      <div className="form-actions">
                        {hasDraftPhotos || hasChanges ? (
                          <Button
                            color="info"
                            disabled={!!listingAction}
                            onClick={() => requestUpdate("saveGoToForm")}
                            size="sm"
                            type="button"
                          >
                            {listingAction === "saveGoToForm" ? (
                              <span>
                                Saving <Spinner className="ml-2" size="sm" />
                              </span>
                            ) : (
                              "Save + Edit Content"
                            )}
                          </Button>
                        ) : (
                          <Button
                            color="primary"
                            disabled={!!listingAction}
                            size="sm"
                            tag={Link}
                            to={{
                              pathname: `/listing/${listing.equipmentId}`,
                              state: history.location.state,
                            }}
                          >
                            Edit Content
                          </Button>
                        )}
                        <Button
                          className="ml-lg-auto"
                          color="danger"
                          disabled={!!listingAction}
                          onClick={goBack}
                          size="sm"
                          type="button"
                        >
                          Cancel
                        </Button>
                        <Button
                          color="success"
                          disabled={!!listingAction}
                          onClick={() => requestUpdate("saveFromPhotos")}
                          size="sm"
                          type="button"
                        >
                          {listingAction === "saveFromPhotos" ? (
                            <span>
                              Saving <Spinner className="ml-2" size="sm" />
                            </span>
                          ) : (
                            "Save"
                          )}
                        </Button>
                      </div>
                    </div>
                    {sortedPhotos.length > 0 && (
                      <PhotosPreview
                        cdn={filestack.cdn}
                        photos={sortedPhotos.filter(
                          photo => !!photo.draftCropped
                        )}
                        open={preview}
                        sold={showStatus}
                        toggle={togglePreview}
                      />
                    )}
                  </Fragment>
                )}
            </div>
          </section>
        </Container>
      </Layout>
    );
  }
}

Photos.propTypes = {
  clearListing: func.isRequired,
  listing: object,
  listingAction: string,
  listingError: string,
  listingLoading: bool,
  /** Route match information from React Router */
  match: object.isRequired,
  fetchListing: func.isRequired,
  /** Filestack integration */
  filestack: shape({
    client: object.isRequired,
    cdn: string.isRequired,
    expires: number.isRequired,
  }),
  filestackError: string,
  filestackInit: func.isRequired,
  updateListing: func.isRequired,
};

export default Photos;
