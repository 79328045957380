import React, { PureComponent } from "react";

import ReactSelect from "react-select";

class ListingSelect extends PureComponent {
  render() {
    return (
      <ReactSelect
        {...this.props}
        styles={{
          container: (provided, state) => ({
            ...provided,
            fontSize: "1.25rem",
          }),
          control: (provided, state) => ({
            ...provided,
            borderColor: state.isFocused ? "#37bbff" : provided.borderColor,
            boxShadow: state.isFocused
              ? "0 0 0 0.2rem rgba(0, 120, 182, 0.25)"
              : provided.boxShadow,
          }),
          dropdownIndicator: (provided, state) => ({
            ...provided,
            color: "#333333",
            ":hover": "#333333",
          }),
          menu: provided => ({ ...provided, zIndex: 10 }),
          option: (provided, state) => ({
            ...provided,
            padding: "0.375rem 1rem",
          }),
          valueContainer: (provided, state) => ({
            ...provided,
            padding: "0.375rem 1rem",
          }),
        }}
        theme={theme => ({
          ...theme,
          borderRadius: "0.25rem",
          colors: {
            ...theme.colors,
            neutral20: "#ced4da",
            neutral50: "#aab1b0",
            neutral80: "#495057",
          },
          spacing: {
            ...theme.spacing,
            controlHeight: 48,
          },
        })}
      />
    );
  }
}

export default ListingSelect;
