import * as client from "filestack-js";

import { FS_API_KEY, FS_CDN, URL_API_FS_SIGNATURE } from "../../constants";
import { api, getApiErrorMessage } from "../../helpers";

/**
 * Action types
 * @property {string} FILESTACK_SUCCESS
 * @property {string} FILESTACK_ERROR
 */
export const types = {
  FILESTACK_SUCCESS: "FILESTACK_SUCCESS",
  FILESTACK_ERROR: "FILESTACK_ERROR",
};

/**
 * @namespace Reducers
 */
export const reducers = {
  /**
   * Update Filestack integration state
   * @param {object} state
   * @param {object} action
   * @param {string} action.type
   * @param {object} action.filestack
   * @param {object} action.filestack.client
   * @param {string} action.filestack.cdn
   * @param {number} action.filestack.expires
   * @memberof Reducers
   */
  filestack: (state = null, action) => {
    switch (action.type) {
      case types.FILESTACK_ERROR:
        return null;
      case types.FILESTACK_SUCCESS:
        return action.filestack;
      default:
        return state;
    }
  },

  /**
   * Update Filestack error state
   * @param {string} state
   * @param {object} action
   * @param {string} action.type
   * @param {string} action.error
   * @memberof Reducers
   */
  filestackError: (state = null, action) => {
    switch (action.type) {
      case types.FILESTACK_ERROR:
        return action.error;
      default:
        return state;
    }
  },
};

/**
 * @namespace Actions
 */
export const actions = {
  /**
   * Initialize the Filestack integration
   * @memberof Actions
   */
  init: () => dispatch => {
    const call = ["pick", "remove", "runWorkflows", "store", "convert"];

    return api
      .get(`${URL_API_FS_SIGNATURE}?methods=${call.join("|")}`)
      .then(response => {
        if (response && response.data && response.data.data) {
          try {
            const { expires, policy, signature } = response.data.data;
            const filestack = {
              client: client.init(FS_API_KEY, {
                security: {
                  policy: policy,
                  signature: signature,
                },
                sessionCache: true,
              }),
              cdn: `${FS_CDN}/security=p:${policy},s:${signature}`,
              expires: expires,
            };
            dispatch(actions.handleSuccess(filestack));
            return filestack;
          } catch (error) {
            dispatch(actions.handleError(actions.parseError(error)));
            return null;
          }
        } else {
          dispatch(actions.handleError(actions.parseError(response)));
          return null;
        }
      })
      .catch(error => {
        const message = actions.parseError(error);
        dispatch(actions.handleError(message));
        return null;
      });
  },

  /**
   * Handle error messages
   * @param {string} message
   * @memberof Actions
   */
  handleError: message => ({
    type: types.FILESTACK_ERROR,
    error: `Filestack Error: ${message}`,
  }),

  /**
   * Handle initialization of Filestack integration
   * @param {object} filestack
   * @memberof Actions
   */
  handleSuccess: filestack => ({
    type: types.FILESTACK_SUCCESS,
    filestack: filestack,
  }),

  /**
   * Parse errors and dispatch error message
   * @param {*} error
   * @memberof Actions
   */
  parseError: error => {
    const message = getApiErrorMessage(error);
    return message;
  },
};
