/** @format */

import { connect } from "react-redux";

import { actions as loginActions } from "./Login.redux";
import Login from "./Login.view";

const mapStateToProps = state => ({
  authMessage: state.auth.message,
});

const mapDispatchToProps = dispatch => ({
  login: data => dispatch(loginActions.login(data)),
  logout: () => dispatch(loginActions.logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
