/**
 * Countries and regions
 */
export const countries = [
  {
    abbr: "US",
    name: "United States",
    regions: [
      { abbr: "AL", name: "Alabama" },
      { abbr: "AK", name: "Alaska" },
      { abbr: "AS", name: "American Samoa" },
      { abbr: "AZ", name: "Arizona" },
      { abbr: "AR", name: "Arkansas" },
      { abbr: "AA", name: "Armed Frcs America" },
      { abbr: "AE", name: "Armed Frcs Europe" },
      { abbr: "AP", name: "Armed Frcs Pacific" },
      { abbr: "CA", name: "California" },
      { abbr: "CO", name: "Colorado" },
      { abbr: "CT", name: "Connecticut" },
      { abbr: "DE", name: "Delaware" },
      { abbr: "DC", name: "District of Columbia" },
      { abbr: "FL", name: "Florida" },
      { abbr: "GA", name: "Georgia" },
      { abbr: "GU", name: "Guam" },
      { abbr: "HI", name: "Hawaii" },
      { abbr: "ID", name: "Idaho" },
      { abbr: "IL", name: "Illinois" },
      { abbr: "IN", name: "Indiana" },
      { abbr: "IA", name: "Iowa" },
      { abbr: "KS", name: "Kansas" },
      { abbr: "KY", name: "Kentucky" },
      { abbr: "LA", name: "Louisiana" },
      { abbr: "ME", name: "Maine" },
      { abbr: "MH", name: "Marshall Islands" },
      { abbr: "MD", name: "Maryland" },
      { abbr: "MA", name: "Massachusetts" },
      { abbr: "MI", name: "Michigan" },
      { abbr: "FM", name: "Micronesia (FSM)" },
      { abbr: "MN", name: "Minnesota" },
      { abbr: "MS", name: "Mississippi" },
      { abbr: "MO", name: "Missouri" },
      { abbr: "MT", name: "Montana" },
      { abbr: "NE", name: "Nebraska" },
      { abbr: "NV", name: "Nevada" },
      { abbr: "NH", name: "New Hampshire" },
      { abbr: "NJ", name: "New Jersey" },
      { abbr: "NM", name: "New Mexico" },
      { abbr: "NY", name: "New York" },
      { abbr: "NC", name: "North Carolina" },
      { abbr: "ND", name: "North Dakota" },
      { abbr: "MP", name: "Northern Mariana Is." },
      { abbr: "OH", name: "Ohio" },
      { abbr: "OK", name: "Oklahoma" },
      { abbr: "OR", name: "Oregon" },
      { abbr: "PW", name: "Palau" },
      { abbr: "PA", name: "Pennsylvania" },
      { abbr: "PR", name: "Puerto Rico" },
      { abbr: "RI", name: "Rhode Island" },
      { abbr: "SC", name: "South Carolina" },
      { abbr: "SD", name: "South Dakota" },
      { abbr: "TN", name: "Tennessee" },
      { abbr: "TX", name: "Texas" },
      { abbr: "UT", name: "Utah" },
      { abbr: "VT", name: "Vermont" },
      { abbr: "VI", name: "Virgin Islands" },
      { abbr: "VA", name: "Virginia" },
      { abbr: "WA", name: "Washington" },
      { abbr: "WV", name: "West Virginia" },
      { abbr: "WI", name: "Wisconsin" },
      { abbr: "WY", name: "Wyoming" },
    ],
  },
];

/**
 * Lookup location
 * @param {string} input
 */
export const lookupLocation = input => {
  input = input.toString().toUpperCase();
  let found = null;

  for (let i = 0; i < countries.length; i++) {
    const region = countries[i].regions.find(
      region => input === region.abbr || input === region.name.toUpperCase()
    );
    if (region) {
      const { abbr, name } = countries[i];
      found = { country: { abbr: abbr, name: name }, region: region };
      break;
    }
  }

  return found;
};

/**
 * Get region abbreviation
 * @param {string} input
 */
export const getRegionAbbr = input => {
  input = input.toString();

  if (input.length === 2) {
    return input.toUpperCase();
  } else {
    const location = lookupLocation(input);
    return location && location.region ? location.region.abbr : input;
  }
};
