import React, { Component } from "react";
import { array, bool, func, string } from "prop-types";

import { CardDeck, Container } from "reactstrap";

import Layout from "../layout";

import { DashboardExport, DashboardLogins } from "./components";

class Dashboard extends Component {
  componentDidMount() {
    const { checkStatus, fetchStatus } = this.props;

    if (!checkStatus()) {
      fetchStatus();
    }
  }

  componentWillUnmount() {
    const {
      cancelStatus,
      clearDailyDownloadError,
      clearFormDataDownloadError,
      clearHistoricalDownloadError,
      clearSearchDownloadError,
      clearStatusError,
      statusLoading,
    } = this.props;

    clearDailyDownloadError();
    clearFormDataDownloadError();
    clearHistoricalDownloadError();
    clearSearchDownloadError();
    clearStatusError();

    if (statusLoading) {
      cancelStatus();
    }
  }

  render() {
    const {
      adminLogins,
      downloadDaily,
      downloadFormData,
      downloadHistorical,
      downloadSearch,
      dailyDownloadError,
      dailyDownloadLoading,
      formDataDownloadError,
      formDataDownloadLoading,
      historicalDownloadError,
      historicalDownloadLoading,
      searchDownloadError,
      searchDownloadLoading,
      statusError,
      statusLoading,
    } = this.props;

    return (
      <Layout title="System Dashboard">
        <Container tag="article">
          <header>
            <p className="h1">System Dashboard</p>
          </header>
          <section>
            <div className="layout-content">
              <CardDeck>
                <DashboardLogins
                  error={statusError}
                  loading={statusLoading}
                  logins={adminLogins}
                />
                <DashboardExport
                  daily={downloadDaily}
                  dailyError={dailyDownloadError}
                  dailyLoading={dailyDownloadLoading}
                  formData={downloadFormData}
                  formDataError={formDataDownloadError}
                  formDataLoading={formDataDownloadLoading}
                  historical={downloadHistorical}
                  historicalError={historicalDownloadError}
                  historicalLoading={historicalDownloadLoading}
                  search={downloadSearch}
                  searchError={searchDownloadError}
                  searchLoading={searchDownloadLoading}
                />
              </CardDeck>
            </div>
          </section>
        </Container>
      </Layout>
    );
  }
}

Dashboard.propTypes = {
  adminLogins: array,
  cancelStatus: func.isRequired,
  checkStatus: func.isRequired,
  clearStatusError: func.isRequired,
  clearDailyDownloadError: func.isRequired,
  clearFormDataDownloadError: func.isRequired,
  clearHistoricalDownloadError: func.isRequired,
  clearSearchDownloadError: func.isRequired,
  downloadDaily: func.isRequired,
  downloadFormData: func.isRequired,
  downloadHistorical: func.isRequired,
  downloadSearch: func.isRequired,
  fetchStatus: func.isRequired,
  dailyDownloadError: string,
  dailyDownloadLoading: bool,
  formDataDownloadError: string,
  formDataDownloadLoading: bool,
  historicalDownloadError: string,
  historicalDownloadLoading: bool,
  searchDownloadError: string,
  statusError: string,
  searchDownloadLoading: bool,
  statusLoading: bool,
};

export default Dashboard;
