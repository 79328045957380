import React from "react";
import { string, number } from "prop-types";

import logoCertified from "../../../assets/img/logos/logo-cat-certified-used.svg";
import logoCertifiedOpt from "../../../assets/img/logos/logo-cat-certified-used-opt.svg";

/**
 * Returns the appropriate certification logo
 * or null if no matching status is found
 */
const ListingCertification = props => {
  const { status, width } = props;

  switch (status) {
    case "included":
      return (
        <img alt="CAT Certified Used logo" src={logoCertified} width={width} />
      );
    case "optional":
      return (
        <img
          alt="CAT Certified Used Optional logo"
          src={logoCertifiedOpt}
          width={width}
        />
      );
    default:
      return null;
  }
};

ListingCertification.propTypes = {
  /** Certification status, should be `included`, `none`, or `optional` */
  status: string,
  width: number,
};

ListingCertification.defaultProps = {
  width: 109,
};

export default ListingCertification;
