import React, { useState } from "react";
import { bool, func, string } from "prop-types";

import { SITE_DATA_UPLOAD_OPTIONS } from "../../../constants";

import {
  Link,
  matchPath,
  NavLink as RouterNavLink,
  useLocation,
} from "react-router-dom";
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  UncontrolledDropdown,
} from "reactstrap";

import LayoutUser from "./Layout.user";

import logo from "../../../assets/img/logos/logo-holt-used.svg";

/**
 * Primary page header with logo and navigation
 */
const LayoutHeader = ({ isAdmin, logout, userName }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { pathname } = useLocation();

  const getDropDownActive = parentPath =>
    matchPath(pathname, { path: parentPath }) ? "active" : "";

  /**
   * Captures click on navbar toggler and
   * tells state if the navigation menu
   * should be open or closed (mobile only)
   * @public
   */
  const toggle = () => setIsOpen(!isOpen);

  return (
    <header className="layout-header">
      <Navbar expand="xl">
        <NavbarBrand tag={Link} to="/">
          <img alt="HOLT Used logo" src={logo} width={285} />
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <div className="navbar-collapse-inner">
            <LayoutUser logout={logout} userName={userName} />
            <Nav navbar>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle
                  caret
                  className={getDropDownActive("/listings/used")}
                  nav
                >
                  Used Equipment
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    activeClassName="active"
                    tag={RouterNavLink}
                    to="/listings/used/all"
                  >
                    All Listings
                  </DropdownItem>
                  <DropdownItem
                    activeClassName="active"
                    tag={RouterNavLink}
                    to="/listings/used/internal"
                  >
                    Internal Listings
                  </DropdownItem>
                  <DropdownItem
                    activeClassName="active"
                    tag={RouterNavLink}
                    to="/listings/used/public"
                  >
                    Public Listings
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
                <NavLink
                  activeClassName="active"
                  tag={RouterNavLink}
                  to="/listings/new/all/live"
                >
                  New Equipment
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  activeClassName="active"
                  tag={RouterNavLink}
                  to="/system-dashboard"
                >
                  System Dashboard
                </NavLink>
              </NavItem>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle
                  caret
                  className={getDropDownActive("/data-uploads")}
                  nav
                >
                  Data Uploads
                </DropdownToggle>
                <DropdownMenu>
                  {SITE_DATA_UPLOAD_OPTIONS.filter(option => !option.hide).map(
                    (option, index) => (
                      <DropdownItem
                        activeClassName="active"
                        key={`${option.type}-${index}`}
                        tag={RouterNavLink}
                        to={`/data-uploads/${option.path}`}
                      >
                        {option.title}
                      </DropdownItem>
                    )
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>
              {isAdmin && (
                <NavItem>
                  <NavLink
                    activeClassName="active"
                    tag={RouterNavLink}
                    to="/users"
                  >
                    Users
                  </NavLink>
                </NavItem>
              )}
            </Nav>
          </div>
        </Collapse>
      </Navbar>
    </header>
  );
};

LayoutHeader.propTypes = {
  isAdmin: bool,
  /** Dispatches logout action to Redux store */
  logout: func.isRequired,
  /** User's first and last name */
  userName: string.isRequired,
};

LayoutHeader.defaultProps = {
  isAdmin: false,
};

export default LayoutHeader;
