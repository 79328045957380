const {
  REACT_APP_HOST_ENV,
  REACT_APP_URL_API_DEVELOPMENT,
  REACT_APP_URL_API_PRODUCTION,
  REACT_APP_URL_API_STAGING,
  REACT_APP_URL_API_VERSION,
  REACT_APP_URL_ASSETS,
  REACT_APP_URL_ADMIN_DEVELOPMENT,
  REACT_APP_URL_ADMIN_PRODUCTION,
  REACT_APP_URL_PUBLIC_DEVELOPMENT,
  REACT_APP_URL_PUBLIC_PRODUCTION,
  REACT_APP_URL_PUBLIC_STAGING,
} = process.env;

const origin =
  window &&
  window.location &&
  `${window.location.protocol}//${window.location.hostname}` +
    (window.location.port ? `:${window.location.port}` : "");

const getEnvironment = origin => {
  if (!!REACT_APP_HOST_ENV) {
    return REACT_APP_HOST_ENV;
  } else {
    switch (origin) {
      case REACT_APP_URL_ADMIN_DEVELOPMENT:
        return "development";
      case REACT_APP_URL_ADMIN_PRODUCTION:
        return "production";
      default:
        return "staging";
    }
  }
};

const getApiDomain = environment => {
  switch (environment) {
    case "development":
      return REACT_APP_URL_API_DEVELOPMENT;
    case "production":
      return REACT_APP_URL_API_PRODUCTION;
    default:
      return REACT_APP_URL_API_STAGING;
  }
};

const getPublicUrl = environment => {
  switch (environment) {
    case "development":
      return REACT_APP_URL_PUBLIC_DEVELOPMENT;
    case "production":
      return REACT_APP_URL_PUBLIC_PRODUCTION;
    default:
      return REACT_APP_URL_PUBLIC_STAGING;
  }
};

/**
 * `HOST_ENV`
 * * First, checks if an environment is specified in the .env configuration, then, if not, falls back checking if the origin URL matches the production URL
 * * Used to determine authentication is required to view listing data
 * @constant {string} hostenv
 * @default
 */
export const HOST_ENV = getEnvironment(origin);

/**
 * `URL_API_DOMAIN` - Retrieves `REACT_APP_URL_API_PRODUCTION`,
 * `REACT_APP_URL_API_STAGING`, and `REACT_APP_URL_ADMIN_PRODUCTION`
 * from .env and determines whether to use the production or staging API
 * if the current window.location matches the production client URL
 * @constant {string} apidomain
 * @default
 */
export const URL_API_DOMAIN = getApiDomain(HOST_ENV);

/**
 * `URL_API_BASE` - Adds the API version to `URL_API_DOMAIN`
 * @constant {string} apibase
 * @default
 */
export const URL_API_BASE = URL_API_DOMAIN + REACT_APP_URL_API_VERSION;

/**
 * `URL_API_ATTACHMENTS` - API endpoint for listings
 * @constant {string} apiattachments
 * @default
 */
export const URL_API_ATTACHMENTS = "/attachments";

/**
 * `URL_API_CATEGORIES` - API endpoint for listings
 * @constant {string} apicategories
 * @default
 */
export const URL_API_CATEGORIES = "/categories";

/**
 * `URL_API_FS_SIGNATURE` - API endpoint for getting Filestack security signature
 * @constant {string} apifssignature
 * @default
 */
export const URL_API_FS_SIGNATURE = "/signature";

/**
 * `URL_API_LISTINGS` - API endpoint for listings
 * @constant {string} apilistings
 * @default
 */
export const URL_API_LISTINGS = "/listings";

/**
 * `URL_API_LOGIN` - API endpoint for login
 * @constant {string} apilogin
 * @default
 */
export const URL_API_LOGIN = "/auth/login";

/**
 * `URL_API_LOGIN_REFRESH` - API endpoint for login refresh
 * @constant {string} apilogin
 * @default
 */
export const URL_API_LOGIN_REFRESH = "/auth/refresh";

/**
 * `URL_API_LOGOUT` - API endpoint for logout
 * @constant {string} apilogout
 * @default
 */
export const URL_API_LOGOUT = "/auth/logout";

/**
 * `URL_API_MAKES_MODELS` - API endpoint for retrieving makes and models
 * @constant {string} apimakesmodels
 * @default
 */
export const URL_API_MAKES_MODELS = "/makes-models";

/**
 * `URL_API_PUBLISH` - API endpoint for publishing a listing
 * @constant {string} apipublish
 * @default
 */
export const URL_API_PUBLISH = "/listings/publish";

/**
 * `URL_API_SOURCES` - API endpoint for retrieving sources
 * @constant {string} apisources
 * @default
 */
export const URL_API_SOURCES = "/sources";

/**
 * `URL_API_STATUS` - API endpoint for system status
 * @constant {string} apistatus
 * @default
 */
export const URL_API_STATUS = "/system-status";

/**
 * `URL_API_STATUS_DOWNLOAD_DAILY` - API endpoint for downloading daily data
 * @constant {string} apistatusdownloaddaily
 * @default
 */
export const URL_API_STATUS_DOWNLOAD_DAILY = `${URL_API_STATUS}/csv/updated`;

/**
 * `URL_API_STATUS_DOWNLOAD_FORM_DATA` - API endpoint for downloading form data
 * @constant {string} apistatusdownloadformdata
 * @default
 */
export const URL_API_STATUS_DOWNLOAD_FORM_DATA = `${URL_API_STATUS}/csv/form-data`;

/**
 * `URL_API_STATUS_DOWNLOAD_HISTORICAL` - API endpoint for downloading historical data
 * @constant {string} apistatusdownloadhistorical
 * @default
 */
export const URL_API_STATUS_DOWNLOAD_HISTORICAL = `${URL_API_STATUS}/csv/historical`;

/**
 * `URL_API_STATUS_DOWNLOAD_SEARCH` - API endpoint for downloading search data
 * @constant {string} apistatusdownloadsearch
 * @default
 */
export const URL_API_STATUS_DOWNLOAD_SEARCH = `${URL_API_STATUS}/csv/search`;

/**
 * `URL_API_USERS` - API endpoint for users
 * @constant {string} apiusers
 * @default
 */
export const URL_API_USERS = "/users";

/**
 * `URL_API_USERS_IMPORT` - API endpoint for users bulk import
 * @constant {string} apiusersimport
 * @default
 */
export const URL_API_USERS_IMPORT = "/users/import";

/**
 * `URL_API_DATA_UPLOADS` - API endpoint for data uploads
 * @constant {string} apidatauploads
 * @default
 */
export const URL_API_DATA_UPLOADS = "/csv/upload";

/**
 * `URL_ASSETS` - Retrieves `REACT_APP_URL_ASSETS` from .env
 * @constant {string} apiassets
 * @default
 */
export const URL_ASSETS = REACT_APP_URL_ASSETS;

/**
 * `URL_ORIGIN` - Base URL derived from the current window.location
 * @constant {string} origin
 * @default
 */
export const URL_ORIGIN = origin;

/**
 * `URL_PUBLIC` - Retrieves `REACT_APP_URL_PUBLIC_PRODUCTION`,
 * `REACT_APP_URL_PUBLIC_STAGING`, and `REACT_APP_URL_ADMIN_PRODUCTION`
 * from .env and determines whether to use the production or staging
 * if the current window.location matches the production client URL
 * @constant {string} public
 * @default
 */
export const URL_PUBLIC = getPublicUrl(HOST_ENV);

/**
 * `URL_PUBLIC_FORGOT` - Public endpoint for forgot password form
 * @constant {string} publicforgot
 * @default
 */
export const URL_PUBLIC_FORGOT = `${URL_PUBLIC}/password/forgot`;

/**
 * `URL_PUBLIC_PDF` - Public endpoint for downloading listings PDF
 * @constant {string} publicpdf
 * @default
 */
export const URL_PUBLIC_PDF = `${URL_PUBLIC}/download`;
