import React, { Component, Fragment } from "react";
import { array, func, number, shape } from "prop-types";

import { Alert, Button, Table } from "reactstrap";
import { Link } from "react-router-dom";

import { URL_API_USERS } from "../../../constants";
import {
  api,
  getApiErrorMessage,
  getShortStamp,
  formatTitleCase,
} from "../../../helpers";

class UsersTable extends Component {
  constructor(props) {
    super(props);

    this.deleteUser = this.deleteUser.bind(this);
    this.handleApiError = this.handleApiError.bind(this);

    this._isMounted = false;

    this.state = {
      deleteError: null,
      deleteId: null,
      inProgress: false,
    };
  }

  /**
   * Delete user
   * @param {number} id
   * @public
   */
  deleteUser(id) {
    this.setState({ deleteId: id, inProgress: true });

    const { handleApiError } = this;
    const { onSuccess } = this.props;

    api.delete(`${URL_API_USERS}/${id}`).then(response => {
      if (!!response && !!response.data && response.data.success) {
        if (this._isMounted) {
          this.setState(
            { deleteError: null, deleteId: null, inProgress: false },
            () => onSuccess()
          );
        }
      } else {
        handleApiError(response);
      }
    });
  }

  /**
   * Handle API error
   * @param {*}
   * @public
   */
  handleApiError(error) {
    const message = getApiErrorMessage(error);

    if (this._isMounted) {
      this.setState({ deleteError: message, inProgress: false });
    }
  }

  componentDidMount() {
    this._isMounted = true;
    this.props.setTitle();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { deleteUser } = this;
    const { currentUser, users } = this.props;
    const { deleteError, deleteId, inProgress } = this.state;

    if (!!users && !!users.length) {
      return (
        <Table className="listings-table" responsive>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email Address</th>
              <th>Role</th>
              <th>Last Login</th>
              <th className="text-center"># of Logins</th>
              <th>
                <span className="sr-only">Actions</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {users.map(user => (
              <tr
                className={deleteId === user.id ? "table-danger" : ""}
                key={`user-${user.id}`}
              >
                <td>
                  <Link to={`/users/${user.id}`}>
                    {user.first_name} {user.last_name}
                  </Link>
                </td>
                <td>
                  <a href={`mailto:${user.email}`}>{user.email}</a>
                </td>
                <td>{formatTitleCase(user.role)}</td>
                <td className="text-muted">
                  {!!user.last_login && getShortStamp(user.last_login)}
                </td>
                <td className="text-center">
                  {!!user.login_count || user.login_count === 0
                    ? user.login_count
                    : "–"}
                </td>
                <td className="text-right">
                  {currentUser.id !== user.id && (
                    <Button
                      color="danger"
                      disabled={inProgress}
                      onClick={() => deleteUser(user.id)}
                      outline
                      size="sm"
                      type="button"
                    >
                      {deleteId === user.id ? (
                        <Fragment>
                          {inProgress
                            ? "Deleting…"
                            : !!deleteError
                            ? "Error"
                            : "Deleted"}
                        </Fragment>
                      ) : (
                        "Delete"
                      )}
                    </Button>
                  )}
                  <Button
                    color="success"
                    outline
                    size="sm"
                    tag={Link}
                    to={`/users/${user.id}`}
                  >
                    Edit
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    } else {
      return (
        <div className="layout-content">
          <Alert className="p-4 text-center" color="info">
            <p className="mb-0">No users found.</p>
          </Alert>
        </div>
      );
    }
  }
}

UsersTable.propTypes = {
  currentUser: shape({
    id: number,
  }),
  onSuccess: func.isRequired,
  setTitle: func.isRequired,
  users: array,
};

export default UsersTable;
