import React, { Component } from "react";
import { func, string } from "prop-types";
import ReactQuill from "react-quill";

/**
 * WYSIWYG Editor for Listing Form
 */
class ListingEditor extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  /**
   * Pass the updated value back to the parent component
   * @param {string} content
   * @param {object} delta
   * @param {*} source
   * @param {object} editor
   * @public
   */
  handleChange(content, delta, source, editor) {
    const { handleInput, id } = this.props;
    const text = editor.getText() ? editor.getText().trim() : "";
    const nextValue = !!text ? content : "";

    handleInput({ target: { id: id, value: nextValue } });
  }

  render() {
    const { handleChange } = this;
    const { id, value } = this.props;

    return (
      <div className="form-control-plaintext">
        <ReactQuill
          className="listing-editor"
          id={id}
          modules={{
            toolbar: [
              "clean",
              { list: "bullet" },
              "bold",
              "italic",
              "underline",
              "link",
            ],
          }}
          onChange={handleChange}
          preserveWhitespace
          value={value}
        />
      </div>
    );
  }
}

ListingEditor.propTypes = {
  /** Form input ID, should correspond to property in parent state */
  id: string.isRequired,
  /** Pass form input back to parent component */
  handleInput: func.isRequired,
  value: string,
};

ListingEditor.defaultProps = {
  value: "",
};

export default ListingEditor;
