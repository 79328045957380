import React, { Component } from "react";
import { string } from "prop-types";
import classNames from "classnames";

class PhotosImage extends Component {
  constructor(props) {
    super(props);

    this.handleError = this.handleError.bind(this);
    this.handleLoad = this.handleLoad.bind(this);

    this._isMounted = false;

    this.state = {
      error: false,
      loading: true,
    };
  }

  /**
   * Handle image error
   * @public
   */
  handleError() {
    if (this._isMounted) {
      this.setState({ error: true });
    }
  }

  /**
   * Handle image loaded
   * @public
   */
  handleLoad() {
    if (this._isMounted) {
      this.setState({ loading: false });
    }
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { handleError, handleLoad } = this;
    const { alt, className, src } = this.props;
    const { error, loading } = this.state;

    return (
      <img
        {...this.props}
        alt={alt}
        className={`${className} ${classNames({
          "image-error": error,
          "image-loading": loading,
        })}`}
        onError={handleError}
        onLoad={handleLoad}
        src={src}
      />
    );
  }
}

PhotosImage.propTypes = {
  alt: string.isRequired,
  className: string,
  src: string.isRequired,
  srcSet: string,
};

PhotosImage.defaultProps = {
  className: "",
};

export default PhotosImage;
