import moment from "moment";
import { actions as filestackActions } from "../filestack";
import { URL_API_LISTINGS, URL_API_PUBLISH } from "../../constants";
import { api, getApiErrorMessage, normalizeListing } from "../../helpers";

/**
 * Action types
 * @property {string} LISTING_ERROR
 * @property {string} LISTING_LOADING
 * @property {string} LISTING_SUCCESS
 * @property {string} LISTING_UPDATING
 * @property {string} SIGNATURE_ERROR
 * @property {string} SIGNATURE_SUCCESS
 */
export const types = {
  LISTING_CLEAR: "LISTING_CLEAR",
  LISTING_ERROR: "LISTING_ERROR",
  LISTING_LOADING: "LISTING_LOADING",
  LISTING_SUCCESS: "LISTING_SUCCESS",
  LISTING_UPDATING: "LISTING_UPDATING",
  SIGNATURE_ERROR: "SIGNATURE_ERROR",
  SIGNATURE_SUCCESS: "SIGNATURE_SUCCESS",
};

/**
 * @namespace Reducers
 */
export const reducers = {
  /**
   * Updates listing state
   * @param {object} state
   * @param {object} action
   * @param {string} action.type
   * @param {object} action.listing
   * @memberof Reducers
   */
  listing: (state = {}, action) => {
    switch (action.type) {
      case types.LISTING_CLEAR:
        return {};
      case types.LISTING_SUCCESS:
        return action.listing;
      default:
        return state;
    }
  },

  /**
   * Updates listing form action state
   * @param {string} state
   * @param {object} action
   * @param {string} action.type
   * @param {string} action.name
   * @memberof Reducers
   */
  listingAction: (state = null, action) => {
    switch (action.type) {
      case types.LISTING_UPDATING:
        return action.name;
      case types.LISTING_CLEAR:
      case types.LISTING_ERROR:
      case types.LISTING_SUCCESS:
        return null;
      default:
        return state;
    }
  },

  /**
   * Updates listing error state
   * @param {string} state
   * @param {object} action
   * @param {string} action.type
   * @param {string} action.error
   * @memberof Reducers
   */
  listingError: (state = null, action) => {
    switch (action.type) {
      case types.LISTING_ERROR:
        return action.error;
      case types.LISTING_CLEAR:
      case types.LISTING_SUCCESS:
        return null;
      default:
        return state;
    }
  },

  /**
   * Updates listing loading state
   * @param {bool} state
   * @param {object} action
   * @param {string} action.type
   * @memberof Reducers
   */
  listingLoading: (state = true, action) => {
    switch (action.type) {
      case types.LISTING_ERROR:
      case types.LISTING_SUCCESS:
        return false;
      case types.LISTING_LOADING:
        return true;
      default:
        return state;
    }
  },
};

/**
 * @namespace Actions
 */
export const actions = {
  /**
   * Clear listing state
   * @memberof Actions
   */
  clear: () => dispatch => {
    dispatch(actions.handleClear());
  },

  /**
   * Fetch listing from API
   * @param {number} equipmentId
   * @memberof Actions
   */
  fetch: equipmentId => dispatch => {
    dispatch(actions.handleLoading());

    return api
      .get(`${URL_API_LISTINGS}/${equipmentId}`)
      .then(response => {
        if (!!response && !!response.data && !!response.data.data) {
          dispatch(actions.parse(response.data.data));
        } else {
          dispatch(actions.parseError(response));
        }
      })
      .catch(error => {
        dispatch(actions.parseError(error));
      });
  },

  /**
   * Handle clear listing events
   * @memberof Actions
   */
  handleClear: () => ({
    type: types.LISTING_CLEAR,
  }),

  /**
   * Handle error messages
   * @param {string} message
   * @memberof Actions
   */
  handleError: message => ({
    type: types.LISTING_ERROR,
    error: message,
  }),

  /**
   * Handle listing loading events
   * @memberof Actions
   */
  handleLoading: () => ({
    type: types.LISTING_LOADING,
  }),

  /**
   * Handle listing API response
   * @param {array} listing
   * @memberof Actions
   */
  handleSuccess: listing => ({
    type: types.LISTING_SUCCESS,
    listing: listing,
  }),

  /**
   * Handle listing form action
   * @param {string} actionName
   * @memberof Actions
   */
  handleUpdating: actionName => ({
    type: types.LISTING_UPDATING,
    name: actionName,
  }),

  /**
   * Parse listing API response for additional
   * information needed in the UI
   * @param {object} data
   * @memberof Actions
   */
  parse: data => dispatch => {
    data = Array.isArray(data) && data.length > 0 ? data[0] : data;
    const listing = normalizeListing(data) || {};

    // Save original values for comparison
    listing.originalValues = (({
      adminNotes,
      category_id,
      description,
      features,
      forceStatusSold,
      isInternal,
      makeOverride,
      overridePrice,
      publishStatus,
    }) => ({
      adminNotes,
      category_id,
      description,
      features,
      forceStatusSold,
      isInternal,
      makeOverride: makeOverride || "",
      overridePrice,
      publishStatus,
    }))(data);

    // Set listing title
    let titleParts = [];

    if (!!listing.year) {
      titleParts.push(listing.year);
    }

    if (!!listing.make) {
      titleParts.push(listing.make);
    }

    if (!!listing.make && !!listing.model) {
      titleParts.push("–");
    }

    if (!!listing.model) {
      titleParts.push(listing.model);
    }

    if (!!listing.equipmentId) {
      titleParts.push(`(${listing.equipmentId})`);
    }

    if (titleParts.length > 0) {
      listing.title = titleParts.join(" ");
    }

    dispatch(actions.handleSuccess(listing));
  },

  /**
   * Parse errors and dispatch error message
   * @param {*} error
   * @memberof Actions
   */
  parseError: error => dispatch => {
    const message = getApiErrorMessage(error);
    dispatch(actions.handleError(`Listing Error: ${message}`));
  },

  /**
   * Prepare and send listing update request to the API
   * @param {string} action
   * @param {*} changes
   * @memberof Actions
   */
  sendUpdate: (action, changes) => (dispatch, getState) => {
    dispatch(actions.handleUpdating(action));

    return new Promise((resolve, reject) => {
      try {
        const { listing } = getState();
        const { id, isInternal, publishStatus } = listing;

        // Exit early if missing listing ID
        if (!id && id !== 0) {
          throw new Error("Missing Listing ID");
        }

        // Begin prepping form data
        const formData = new FormData();
        let formUrl = URL_API_LISTINGS;

        let newContent = {
          category_id: listing.category_id,
          description: listing.description,
          features: listing.features,
          forceStatusSold: listing.forceStatusSold,
          makeOverride: listing.makeOverride,
          overridePrice: listing.overridePrice,
        };

        if (["saveGoToPhotos", "saveFromListing"].includes(action)) {
          newContent.category_id = changes.category_id || null;
          newContent.description = changes.description;
          newContent.features = changes.features;
          newContent.forceStatusSold = changes.forceStatusSold;
          newContent.makeOverride = changes.makeOverride;
          newContent.overridePrice = changes.overridePrice;
        }

        formData.append("draftContent", JSON.stringify(newContent));

        // Save admin notes
        if (changes.hasOwnProperty("adminNotes")) {
          formData.append("adminNotes", changes.adminNotes);
        }

        // Set is internal and publish status
        if (
          [
            "saveFromListing",
            "saveFromPhotos",
            "saveGoToForm",
            "saveGoToPhotos",
          ].includes(action)
        ) {
          formData.append("isInternal", changes.isInternal);
          formData.append("publishStatus", changes.publishStatus);

          if (changes.publishStatus === "published") {
            formUrl = URL_API_PUBLISH;
          }
        } else {
          formData.append("isInternal", isInternal);
          formData.append("publishStatus", publishStatus);
        }

        // Set photos
        if (!!changes && !!changes.photos) {
          formData.append("photos", JSON.stringify(changes.photos));
        }

        // Send update to the API
        api
          .post(`${formUrl}/${id}`, formData)
          .then(response => {
            if (!!response && !!response.data && !!response.data.data) {
              dispatch(actions.parse(response.data.data));
              resolve();
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error);
          });
      } catch (error) {
        reject(error);
      }
    });
  },

  /**
   * Evaluate whether the listing update request needs to
   * modify photos and route the request accordingly
   * @param {string} action
   * @param {*} changes
   * @memberof Actions
   */
  update: (action, changes) => (dispatch, getState) =>
    new Promise(async (resolve, reject) => {
      if (
        [
          "removeDraftPhoto",
          "saveGoToForm",
          "saveGoToPhotos",
          "saveFromListing",
          "saveFromPhotos",
        ].includes(action)
      ) {
        // Get Filestack integration
        let { filestack, listing } = getState();

        if (!filestack || moment.unix(filestack.expires).isBefore(moment())) {
          await dispatch(filestackActions.init()).then(response => {
            if (response instanceof Error === false) {
              filestack = response;
            }
          });
        }

        prepPhotos(action, changes, filestack, listing)
          .then(photos => {
            changes =
              typeof changes === "object" && changes !== null ? changes : {};
            changes.photos = photos;
            dispatch(actions.sendUpdate(action, changes))
              .then(response => {
                if (response instanceof Error) {
                  dispatch(actions.parseError(response));
                  reject();
                }

                resolve();
              })
              .catch(error => {
                dispatch(actions.parseError(error));
                reject();
              });
          })
          .catch(error => {
            dispatch(actions.parseError(error));
            reject();
          });
      } else {
        dispatch(actions.sendUpdate(action, changes))
          .then(response => {
            if (response instanceof Error) {
              dispatch(actions.parseError(response));
              reject();
            }

            resolve(response);
          })
          .catch(error => {
            dispatch(actions.parseError(error));
            reject();
          });
      }
    }),
};

/**
 * Process the photos in Filestack and prepare the photos
 * object for inclusion in the listing update request
 * @param {string} action
 * @param {*} changes
 * @param {object} filestack
 * @param {object} listing
 */
const prepPhotos = (action, changes, filestack, listing) =>
  new Promise((resolve, reject) => {
    try {
      if (!filestack || !filestack.client) {
        throw new Error("Cannot save photos without Filestack client");
      }

      if (!listing) {
        throw new Error("Cannot save photos without listing");
      }

      const { cdn, client } = filestack;
      const { photos } = listing;

      let updatedPhotos;

      if (action === "removeDraftPhoto") {
        updatedPhotos = Promise.all(
          photos.map(async photo => {
            if (photo.draftCropped === changes) {
              let notFound = false;
              const remove = await client
                .remove(photo.draftCropped)
                .catch(error => {
                  if (
                    !!error &&
                    !!error.response &&
                    error.response.status === 404
                  ) {
                    notFound = true;
                  } else {
                    throw error;
                  }
                });

              if (
                (!remove && !notFound) ||
                (!!remove && (!remove.data || remove.data !== "success"))
              ) {
                throw new Error(`Photo was not removed (${changes})`);
              }

              photo.draftCropped = null;
              photo.draftIndex = null;
              photo.draftNew = false;
            }

            return photo;
          })
        );
      } else {
        updatedPhotos = Promise.all(
          photos.map(async photo => {
            // Update the draft index if needed
            if (!!changes.draftPhotos) {
              const sort = changes.draftPhotos.findIndex(
                sortPhoto => sortPhoto.draftCropped === photo.draftCropped
              );

              if (sort > -1) {
                photo.draftIndex = sort;
              } else {
                photo.draftIndex = null;
              }
            }

            // Remove the current live photo if the user has either
            // changed the cropping or removed it from the draft gallery
            if (
              !!photo.liveCropped &&
              (photo.draftNew || !photo.draftCropped)
            ) {
              let notFound = false;
              const remove = await client
                .remove(photo.liveCropped)
                .catch(error => {
                  if (
                    !!error &&
                    !!error.response &&
                    error.response.status === 404
                  ) {
                    notFound = true;
                  } else {
                    throw error;
                  }
                });

              photo.liveCropped = null;

              if (
                (!remove && !notFound) ||
                (!!remove && (!remove.data || remove.data !== "success"))
              ) {
                throw new Error(`Photo was not removed (${photo.liveCropped})`);
              }
            }

            // Create a new live photo if needed
            if (
              !!photo.draftCropped &&
              (photo.draftNew || !photo.liveCropped)
            ) {
              const live = await client
                .storeURL(`${cdn}/${photo.draftCropped}`)
                .catch(error => {
                  throw error;
                });

              if (live && live.handle) {
                photo.liveCropped = live.handle;
              } else {
                throw new Error(
                  `Photo was not published (${photo.draftCropped})`
                );
              }
            }

            // Update live photo sort index
            photo.draftNew = false;
            photo.liveIndex = photo.draftIndex;

            return photo;
          })
        );
      }

      resolve(updatedPhotos);
    } catch (error) {
      reject(error);
    }
  });
