import React, { Fragment, useState } from "react";
import { string } from "prop-types";
import classNames from "classnames";

import {
  Alert,
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Spinner,
} from "reactstrap";

import { api, getFileSize, getApiErrorMessage } from "../../../helpers";
import { REQUEST_MAX_SIZE, URL_API_DATA_UPLOADS } from "../../../constants";

const DataUploadsCsvForm = ({ sentence, type }) => {
  const [file, setFile] = useState("");
  const [fileError, setFileError] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedError, setIsCheckedError] = useState(null);
  const [inProgress, setInProgress] = useState(false);
  const [message, setMessage] = useState("");
  const [messageColor, setMessageColor] = useState();

  const clearErrors = () => {
    setFileError("");
    setIsCheckedError("");
  };

  const handleSubmit = e => {
    e.preventDefault();
    clearErrors();
    setInProgress(true);
    let isValid = true;
    if (!file) {
      isValid = false;
      setFileError("Please select the CSV file to upload");
    }
    if (!isChecked) {
      isValid = false;
      setIsCheckedError("Please confirm");
    }
    if (isValid) {
      // Prep form data
      const formData = new FormData();
      formData.append("type", type);
      formData.append("csv", file, `${type}.csv`);
      api
        .post(URL_API_DATA_UPLOADS, formData)
        .then(response => {
          if (!!response && !!response.data && response.data.success) {
            setMessage("CSV uploaded successfully");
            setMessageColor("success");
            setInProgress(false);
          } else {
            setMessage(getApiErrorMessage(response));
            setMessageColor("danger");
            setInProgress(false);
          }
        })
        .catch(error => {
          setMessage(getApiErrorMessage(error));
          setMessageColor("danger");
          setInProgress(false);
        });
    } else {
      setInProgress(false);
    }
  };

  const handleFileInput = e => {
    const files = Array.from(e.target.files);
    if (!!files.length) {
      const errors = [];

      if (files[0].size > REQUEST_MAX_SIZE) {
        setFileError(
          `File cannot be larger than ${getFileSize(REQUEST_MAX_SIZE)}`
        );
      }

      if (files[0].type !== "text/csv") {
        setFileError("File must be a CSV");
      }

      if (!!errors.length) {
        setFileError(errors.join(", "));
      } else {
        setFile(files[0]);
      }
    }
  };

  return (
    <Form className="listing-form mx-auto" noValidate onSubmit={handleSubmit}>
      <FormGroup>
        <div className="custom-file">
          <Input
            accept="text/csv"
            className="custom-file-input"
            id="file"
            label={(file && file.name) || "Choose file"}
            onChange={handleFileInput}
            type="file"
            invalid={!!fileError}
          />
          <Label
            className={classNames({
              "custom-file-label": true,
              placeholder: !file || !file.name,
            })}
            for="file"
          >
            {(file && file.name) || "Choose file"}
          </Label>
          {!!fileError && <FormFeedback>{fileError}</FormFeedback>}
        </div>
      </FormGroup>

      <FormGroup check>
        <Label check>
          <Input
            type="checkbox"
            value={isChecked}
            onClick={e => setIsChecked(!isChecked)}
            invalid={!!isCheckedError}
          />{" "}
          PLEASE CONFIRM you are uploading a {sentence} CSV. This action CANNOT
          BE UNDONE. Check the box to confirm. Thank you!
          {!!isCheckedError && <FormFeedback>{isCheckedError}</FormFeedback>}
        </Label>
      </FormGroup>
      <div className="form-actions border-top-0">
        <Button color="success" disabled={inProgress} type="submit">
          {inProgress ? (
            <Fragment>
              <Spinner className="ml-2" size="sm" />
            </Fragment>
          ) : (
            "Submit"
          )}
        </Button>
      </div>
      {message && (
        <Alert className="mt-2" color={messageColor}>
          {message}
        </Alert>
      )}
    </Form>
  );
};

DataUploadsCsvForm.propTypes = {
  sentence: string.isRequired,
  type: string.isRequired,
};

export default DataUploadsCsvForm;
