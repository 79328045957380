/** @format */

import React, { Component } from "react";
import { bool, func, node, objectOf, string } from "prop-types";
import classNames from "classnames";

import { SITE_TITLE } from "../../constants";

import { LayoutFooter, LayoutHeader } from "./components";

/**
 * Primary page layout
 * * Displays navigation header for authenticated users
 * * Also updates the page title after a route change
 */
class Layout extends Component {
  constructor(props) {
    super(props);

    this.layout = React.createRef();

    this.state = {
      title: SITE_TITLE,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (!props.title) {
      document.title = SITE_TITLE;
      return { title: props.title };
    } else if (props.title !== state.title) {
      document.title = `${props.title} - ${SITE_TITLE}`;
      return { title: props.title };
    } else {
      return null;
    }
  }

  componentDidMount() {
    if (window.scrollY) {
      this.layout.current.scrollIntoView({ behavior: "smooth" });
    }
  }

  render() {
    const { layout } = this;
    const {
      authenticated,
      isAdmin,
      children,
      layoutClasses,
      layoutMainClasses,
      logout,
      userName,
    } = this.props;
    return (
      <div
        className={classNames({
          layout: true,
          "layout-has-header": true,
          ...layoutClasses,
        })}
        ref={layout}
      >
        {authenticated && (
          <LayoutHeader isAdmin={isAdmin} logout={logout} userName={userName} />
        )}
        <main
          className={classNames({ "layout-main": true, ...layoutMainClasses })}
        >
          {children}
        </main>
        <LayoutFooter />
      </div>
    );
  }
}

Layout.propTypes = {
  /** User authentication status */
  authenticated: bool,
  /** Component that contains the requested content */
  children: node.isRequired,
  isAdmin: bool,
  /**
   * Changes the appearance the layout element.
   *
   * * For example, changing the CSS grid rows
   * when the header is not displayed
   * * Expects classNames object, i.e.,
   * `{"your-class": true, "another-class": true}`
   */
  layoutClasses: objectOf(bool),
  /**
   * Changes the appearance of the `<main>` element.
   *
   * * For example, the setting the image background
   * for the login form
   * * Expects classNames object, i.e.,
   * `{"your-class": true, "another-class": true}`
   */
  layoutMainClasses: objectOf(bool),
  /** Dispatches logout action to Redux store */
  logout: func.isRequired,
  /** User's first and last name */
  userName: string.isRequired,
};

Layout.defaultProps = {
  authenticated: false,
  isAdmin: false,
};

export default Layout;
