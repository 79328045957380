import React, { Component } from "react";
import { array, func, object, string } from "prop-types";
import arrayMove from "array-move";

import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { Badge, Spinner } from "reactstrap";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";

import PhotosImage from "./Photos.image";

const SortableItem = SortableElement(
  ({ cdn, removeInProgress, onRemove, value }) => (
    <li className="photos-sort-item">
      <div className="photos-sort-item-container">
        {!!value.draftCropped && (
          <PhotosImage
            alt="sortable"
            className={
              !!removeInProgress && removeInProgress === value.draftCropped
                ? "delete-in-progress"
                : ""
            }
            src={`${cdn}/resize=width:154,height:154,fit:crop/cache=expiry:max/${value.draftCropped}`}
            srcSet={`${cdn}/resize=width:154,height:154,fit:crop/cache=expiry:max/${value.draftCropped} 1x, ${cdn}/resize=width:308,height:308,fit:crop/cache=expiry:max/${value.draftCropped} 2x`}
          />
        )}
      </div>
      <button
        aria-label="remove"
        className="photos-sort-remove"
        disabled={!!removeInProgress}
        onClick={() => onRemove(value.draftCropped)}
        type="button"
      >
        <Icon icon={faMinusCircle} />
      </button>
      {!!removeInProgress && removeInProgress === value.draftCropped && (
        <div className="photos-sort-spinner">
          <Spinner color="danger" />
        </div>
      )}
      {value.draftNew && <Badge className="photos-sort-draft">Draft</Badge>}
    </li>
  )
);

const SortableList = SortableContainer(
  ({ cdn, removeInProgress, items, onRemove }) => {
    const row = 6;
    const min = row * 3;
    const placeholders =
      items.length < min ? min - items.length : row - (items.length % row);

    return (
      <ul className="photos-sort">
        {items.map((value, index) => (
          <SortableItem
            cdn={cdn}
            removeInProgress={removeInProgress}
            key={`item-${index}`}
            index={index}
            onRemove={onRemove}
            value={value}
          />
        ))}
        {placeholders &&
          [...Array(placeholders).keys()].map((_, index) => (
            <li
              className="photos-sort-placeholder"
              key={`placeholder-${index}`}
            >
              <div className="photos-sort-placeholder-container">
                {!items.length && index === 0 ? (
                  <div className="photos-sort-placeholder-featured">
                    <span>Featured Image</span>
                  </div>
                ) : (
                  <div className="photos-sort-placeholder-border">
                    <span className="sr-only">placeholder</span>
                  </div>
                )}
              </div>
            </li>
          ))}
      </ul>
    );
  }
);

class PhotosSort extends Component {
  constructor(props) {
    super(props);

    this.handleRemove = this.handleRemove.bind(this);
    this.handleSort = this.handleSort.bind(this);
  }

  /**
   * Handle removing photo from galler
   * @param {string} photoHandle
   * @public
   */
  handleRemove(photoHandle) {
    this.props.updatePhotos("removeDraftPhoto", photoHandle);
  }

  /**
   * Handle sort
   * @public
   */
  handleSort({ oldIndex, newIndex }) {
    const { sortPhotos } = this.props;
    const oldSort = [...this.props.photos];
    const newSort = arrayMove(oldSort, oldIndex, newIndex);

    sortPhotos(newSort);
  }

  render() {
    const { handleRemove, handleSort } = this;
    const { cdn, photos, removeInProgress } = this.props;

    return !!cdn ? (
      <SortableList
        axis="xy"
        cdn={cdn}
        removeInProgress={removeInProgress}
        items={photos}
        onRemove={handleRemove}
        onSortEnd={handleSort}
      />
    ) : null;
  }
}

PhotosSort.propTypes = {
  cdn: string.isRequired,
  client: object.isRequired,
  photos: array,
  removeInProgress: string,
  sortPhotos: func.isRequired,
  updatePhotos: func.isRequired,
};

PhotosSort.defaultProps = {
  photos: [],
  removeInProgress: null,
};

export default PhotosSort;
