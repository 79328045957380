import { api, getApiErrorMessage } from "../../helpers";

import { URL_API_CATEGORIES } from "../../constants";

/**
 * Action types
 * @property {string} CATEGORIES_UPDATED
 */
export const types = {
  CATEGORIES_ERROR: "CATEGORIES_ERROR",
  CATEGORIES_LOADING: "CATEGORIES_LOADING",
  CATEGORIES_UPDATED: "CATEGORIES_UPDATED",
  CATEGORY_OPTIONS_UPDATED: "CATEGORY_OPTIONS_UPDATED",
};

/**
 * @namespace Reducers
 */
export const reducers = {
  /**
   * Updates categories state
   * @param {object} state
   * @param {object} action
   * @param {string} action.type - `CATEGORIES_UPDATED`, or no change
   * @param {object} action.categories
   * @param {array} action.categories.search
   * @param {tree} action.categories.tree
   * @memberof Reducers
   */
  categories: (state = { search: [], tree: [] }, action) => {
    switch (action.type) {
      case types.CATEGORIES_UPDATED:
        return action.categories;
      default:
        return state;
    }
  },

  /**
   * Updates categories error state
   * @param {string} state
   * @param {object} action
   * @param {string} action.type
   * @param {string} action.error
   * @memberof Reducers
   */
  categoriesError: (state = null, action) => {
    switch (action.type) {
      case types.CATEGORIES_ERROR:
        return action.error;
      case types.CATEGORIES_UPDATED:
        return null;
      default:
        return state;
    }
  },

  /**
   * Updates categories loading state
   * @param {bool} state
   * @param {object} action
   * @param {string} action.type
   * @memberof Reducers
   */
  categoriesLoading: (state = true, action) => {
    switch (action.type) {
      case types.CATEGORIES_ERROR:
      case types.CATEGORIES_UPDATED:
        return false;
      case types.CATEGORIES_LOADING:
        return true;
      default:
        return state;
    }
  },
  /**
   * Updates category options state
   * @param {object} state
   * @param {object} action
   * @param {string} action.type - `CATEGORY_OPTIONS_UPDATED`, or no change
   * @param {object} action.categories
   * @param {array} action.categories.search
   * @param {tree} action.categories.tree
   * @memberof Reducers
   */
  categoryOptions: (state = [], action) => {
    switch (action.type) {
      case types.CATEGORY_OPTIONS_UPDATED:
        return action.options;
      default:
        return state;
    }
  },
};

/**
 * @namespace Actions
 */
export const actions = {
  /**
   * Lookup categories
   * @param {number} categoryId
   * @memberof Actions
   */
  lookup: categoryId => dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(actions.fetch()).then(response => {
        const { categories, success, message } = response;

        if (!success) {
          reject({ success: false, message: message });
        }

        const findCategory = id => {
          let found = [];
          const cat = categories.search.find(c => c.id === id);
          if (cat) {
            found.push(cat.name);
            found = found.concat(...findCategory(cat.parent_id));
          }
          return found;
        };

        const category = findCategory(categoryId);

        if (category.length > 0) {
          resolve({
            success: true,
            category: category.reverse(),
          });
        } else {
          reject({
            success: false,
            message: `Unknown category (ID: ${categoryId})`,
          });
        }
      });
    });
  },

  /**
   * Fetch categories from API
   * @memberof Actions
   */
  fetch: () => (dispatch, getState) => {
    let { categories } = getState();

    if (categories.search.length > 0) {
      return new Promise(resolve => {
        resolve({
          success: true,
          categories: categories,
        });
      });
    } else {
      dispatch(actions.handleLoading());

      return api
        .get(URL_API_CATEGORIES)
        .then(response => {
          if (
            response &&
            response.data &&
            response.data.data &&
            Array.isArray(response.data.data)
          ) {
            const flatten = a => a.concat(...a.map(b => flatten(b.children)));
            const categories = {
              search: flatten(response.data.data),
              tree: response.data.data,
            };

            dispatch(actions.update(categories));

            return {
              success: true,
              categories: categories,
            };
          } else {
            return dispatch(actions.parseError(response));
          }
        })
        .catch(error => dispatch(actions.parseError(error)));
    }
  },

  /**
   * Fetch categories from API
   * @memberof Actions
   */
  fetchOptions: () => dispatch => {
    dispatch(actions.fetch()).then(response => {
      const { categories, success } = response;

      if (!success || !categories.search) {
        return;
      }

      const options = categories.search
        .map(category => {
          const findCategory = id => {
            let found = [];
            const cat = categories.search.find(c => c.id === id);
            if (cat) {
              found.push(cat.name);
              found = found.concat(...findCategory(cat.parent_id));
            }
            return found;
          };

          let label;
          const ancestors = findCategory(category.id);

          if (ancestors.length > 0) {
            label = ancestors.reverse().join(" / ");
          } else {
            label = category.name || `Unknown category (ID: ${category.id})`;
          }

          return {
            label: label,
            value: category.id,
          };
        })
        .sort((a, b) => {
          const labelA = a.label.toUpperCase();
          const labelB = b.label.toUpperCase();

          if (labelA < labelB) {
            return -1;
          }

          if (labelB > labelA) {
            return 1;
          }

          return 0;
        });

      dispatch(actions.updateOptions(options));
    });
  },

  /**
   * Handle error messages
   * @param {string} message
   * @memberof Actions
   */
  handleError: message => ({
    type: types.CATEGORIES_ERROR,
    error: message,
  }),

  /**
   * Handle categories loading events
   * @memberof Actions
   */
  handleLoading: () => ({
    type: types.CATEGORIES_LOADING,
  }),

  /**
   * Parse errors and dispatch error message
   * @param {*} error
   * @memberof Actions
   */
  parseError: error => dispatch => {
    const message = getApiErrorMessage(error);
    dispatch(actions.handleError(`Categories Error: ${message}`));

    return {
      success: false,
      message: message,
    };
  },

  /**
   * Update categories
   * @param {array} categories
   * @memberof Actions
   */
  update: categories => ({
    type: types.CATEGORIES_UPDATED,
    categories: categories,
  }),

  /**
   * Update category options
   * @param {array} options
   * @memberof Actions
   */
  updateOptions: options => ({
    type: types.CATEGORY_OPTIONS_UPDATED,
    options: options,
  }),
};
