import React, { Fragment, useState } from "react";
import { string } from "prop-types";

import {
  Alert,
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Spinner,
} from "reactstrap";

import { api, getApiErrorMessage } from "../../../helpers";
import { URL_API_DATA_UPLOADS } from "../../../constants";

const DataUploadsSerialNumbersForm = ({ sentence, type }) => {
  const [serialNumbers, setSerialNumbers] = useState("");
  const [serialNumbersError, setSerialNumbersError] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedError, setIsCheckedError] = useState(null);
  const [inProgress, setInProgress] = useState(false);
  const [message, setMessage] = useState("");
  const [messageColor, setMessageColor] = useState();

  const clearErrors = () => {
    setSerialNumbersError("");
    setIsCheckedError("");
  };

  const handleInput = ({ target }) => {
    const { value } = target;
    setSerialNumbers(value);
  };

  const handleSubmit = e => {
    e.preventDefault();
    clearErrors();
    setInProgress(true);
    let isValid = true;
    if (!serialNumbers) {
      isValid = false;
      setSerialNumbersError("Please enter the serial numbers to upload");
    }
    if (!isChecked) {
      isValid = false;
      setIsCheckedError("Please confirm");
    }
    if (isValid) {
      // Prep form data
      const formData = new FormData();
      formData.append("type", type);
      formData.append("serialNumbers", serialNumbers);
      api
        .post(URL_API_DATA_UPLOADS, formData)
        .then(response => {
          if (!!response && !!response.data && response.data.success) {
            setMessage("Serial numbers uploaded successfully");
            setMessageColor("success");
            setInProgress(false);
          } else {
            setMessage(getApiErrorMessage(response));
            setMessageColor("danger");
            setInProgress(false);
          }
        })
        .catch(error => {
          setMessage(getApiErrorMessage(error));
          setMessageColor("danger");
          setInProgress(false);
        });
    } else {
      setInProgress(false);
    }
  };

  return (
    <Form className="listing-form mx-auto" noValidate onSubmit={handleSubmit}>
      <FormGroup>
        <p>
          Please paste in the serial numbers of {sentence} you would like to add
          from CatUsed. Each machine’s serial number must be on a separate line.
        </p>
        <Label for="serialNumbers" hidden>
          Serial Numbers
        </Label>
        <Input
          id="serialNumbers"
          invalid={!!serialNumbersError}
          onChange={handleInput}
          rows="10"
          type="textarea"
        />
        {!!serialNumbersError && (
          <FormFeedback>{serialNumbersError}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup check>
        <Label check>
          <Input
            type="checkbox"
            value={isChecked}
            onClick={e => setIsChecked(!isChecked)}
            invalid={!!isCheckedError}
          />{" "}
          PLEASE CONFIRM you are uploading a list of{" "}
          <span className="text-uppercase">{sentence}</span>. This action CANNOT
          BE UNDONE. Check the box to confirm. Thank you!
          {!!isCheckedError && <FormFeedback>{isCheckedError}</FormFeedback>}
        </Label>
      </FormGroup>
      <div className="form-actions border-top-0">
        <Button color="success" disabled={inProgress} type="submit">
          {inProgress ? (
            <Fragment>
              <Spinner className="ml-2" size="sm" />
            </Fragment>
          ) : (
            "Submit"
          )}
        </Button>
      </div>
      {message && (
        <Alert className="mt-2" color={messageColor}>
          {message}
        </Alert>
      )}
    </Form>
  );
};

DataUploadsSerialNumbersForm.propTypes = {
  sentence: string.isRequired,
  type: string.isRequired,
};

export default DataUploadsSerialNumbersForm;
