import React, { Component } from "react";
import ReactGA from "react-ga";

import history from "../../helpers/history";
import { GA_ID } from "../../constants";

const withAnalytics = WrappedComponent => {
  const Analytics = class extends Component {
    constructor(props) {
      super(props);

      this.trackEvent = this.trackEvent.bind(this);
      this.trackModalView = this.trackModalView.bind(this);
      this.trackPageView = this.trackPageView.bind(this);

      this.state = {
        currentPath: null,
        currentUrl: null,
        isActive: false,
      };
    }

    /**
     * Track event
     * @param {object} event
     * @public
     */
    trackEvent(event) {
      if (this.state.isActive && !!event) {
        ReactGA.event(event);
      }
    }

    /**
     * Track modal view
     * @param {*} modalName
     * @public
     */
    trackModalView(modalName) {
      if (this.state.isActive && !!modalName) {
        const { currentPath } = this.state;
        const path = currentPath !== "/" ? currentPath : "";
        const modalPath = `${path}/${modalName}`;

        ReactGA.modalview(modalPath);
      }
    }

    /**
     * Track page view
     * @param {object} location
     * @public
     */
    trackPageView(location) {
      const { currentUrl, isActive } = this.state;

      if (isActive) {
        const url = location.pathname + location.search;

        if (url !== currentUrl) {
          this.setState({ currentPath: location.pathname, currentUrl: url });
          ReactGA.pageview(url);
        }
      }
    }

    componentDidMount() {
      if (!!history && !!GA_ID) {
        ReactGA.initialize(GA_ID);

        this.setState({ isActive: true }, () => {
          this.unlisten = history.listen(this.trackPageView);
          this.trackPageView(history.location);
        });
      }
    }

    componentWillUnmount() {
      if (this.state.isActive) {
        this.unlisten();
      }
    }

    render() {
      return (
        <WrappedComponent
          trackEvent={this.trackEvent}
          trackModalView={this.trackModalView}
          {...this.props}
        />
      );
    }
  };

  return Analytics;
};

export default withAnalytics;
