const { REACT_APP_REQUEST_MAX_SIZE, REACT_APP_REQUEST_MAX_TIME } = process.env;

/**
 * `REQUEST_MAX_SIZE` - Retrieves `REACT_APP_REQUEST_MAX_SIZE` from .env file
 * and casts to integer, maximum upload size
 * @constant maxsize
 * @default
 */
export const REQUEST_MAX_SIZE =
  parseInt(REACT_APP_REQUEST_MAX_SIZE, 10) || 15 * 1024 * 1024;

/**
 * `REQUEST_MAX_TIME` - Retrieves `REACT_APP_REQUEST_MAX_TIME` from .env file
 * and casts to integer, API request timeout
 * @constant maxtime
 * @default
 */
export const REQUEST_MAX_TIME =
  parseInt(REACT_APP_REQUEST_MAX_TIME, 10) || 5 * 60 * 1000;
