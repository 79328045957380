import React from "react";
import { bool, func, string } from "prop-types";
import { CustomInput, Label } from "reactstrap";

const SwitchGroup = ({
  checked,
  className,
  handleChange,
  id,
  offLabel,
  onColor,
  onLabel,
}) => {
  return (
    <div className={`switch-group ${!!className ? className : ""}`}>
      <Label onClick={() => handleChange(false)}>{offLabel}</Label>
      <CustomInput
        checked={checked}
        className={`on-${onColor}`}
        onChange={() => handleChange(!checked)}
        type="switch"
        id={id}
        label={<span className="sr-only">{onLabel}?</span>}
      />
      <Label onClick={() => handleChange(true)}>{onLabel}</Label>
    </div>
  );
};

SwitchGroup.propTypes = {
  checked: bool,
  className: string,
  handleChange: func.isRequired,
  id: string.isRequired,
  offLabel: string.isRequired,
  onColor: string,
  onLabel: string.isRequired,
};

SwitchGroup.defaultProps = {
  onColor: "danger",
};

export default SwitchGroup;
