/** @format */

import { connect } from "react-redux";

import { actions as loginActions } from "../login";

import Layout from "./Layout.view";

const mapStateToProps = state => ({
  authenticated: !!state.auth.expires,
  isAdmin: !!state.auth.user && state.auth.user.role === "admin",
  userName: (state.auth.user && state.auth.user.name) || "Admin",
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(loginActions.logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
