import React from "react";
import { func, string } from "prop-types";

import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";

/**
 * Meta navigation for user actions
 */
const LayoutUser = ({ logout, userName }) => {
  return (
    <div className="navbar-nav navbar-user">
      <UncontrolledDropdown inNavbar>
        <DropdownToggle caret color="link">
          <Icon icon={faUser} /> <span className="ml-1">{userName}</span>
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem onClick={logout}>Logout</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
};

LayoutUser.propTypes = {
  /** Dispatches logout action to Redux store */
  logout: func.isRequired,
  /** User's first and last name */
  userName: string.isRequired,
};

export default LayoutUser;
