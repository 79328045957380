import { connect } from "react-redux";

import { actions as filestackActions } from "../filestack";
import { actions as listingActions } from "../listing";
import Photos from "./Photos.view";

const mapStateToProps = state => ({
  filestack: state.filestack,
  filestackError: state.filestackError,
  listing: state.listing,
  listingAction: state.listingAction,
  listingError: state.listingError,
  listingLoading: state.listingLoading,
});

const mapDispatchToProps = dispatch => ({
  clearListing: () => dispatch(listingActions.clear()),
  filestackInit: () => dispatch(filestackActions.init()),
  fetchListing: equipmentId => dispatch(listingActions.fetch(equipmentId)),
  updateListing: (action, update) =>
    dispatch(listingActions.update(action, update)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Photos);
