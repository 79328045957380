import { connect } from "react-redux";
import App from "./App.view";
import withAnalytics from "../analytics";

import { actions as loginActions } from "../login";

const mapStateToProps = state => ({
  isAdmin: !!state.auth.user && state.auth.user.role === "admin",
});

const mapDispatchToProps = dispatch => ({
  checkAuth: () => dispatch(loginActions.check()),
});

export default withAnalytics(connect(mapStateToProps, mapDispatchToProps)(App));
