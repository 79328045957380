import React, { Component, Fragment } from "react";
import { array, func, number, shape } from "prop-types";

import {
  Alert,
  Button,
  Col,
  CustomInput,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";

import { URL_API_USERS } from "../../../constants";
import {
  api,
  getApiErrorMessage,
  formatTitleCase,
  scrollIfNeeded,
} from "../../../helpers";

class UsersEdit extends Component {
  constructor(props) {
    super(props);

    this.deleteUser = this.deleteUser.bind(this);
    this.handleApiError = this.handleApiError.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.putData = this.putData.bind(this);
    this.setUser = this.setUser.bind(this);

    this._isMounted = false;
    this.defaultTitle = "Edit User";
    this.headline = React.createRef();

    this.initialState = {
      deleteError: null,
      email: "",
      emailError: null,
      found: false,
      inProgress: false,
      isCurrent: false,
      first_name: "",
      first_nameError: null,
      last_name: "",
      last_nameError: null,
      role: "",
      roleError: null,
      serverError: null,
    };

    this.state = this.initialState;
  }

  /**
   * Delete user
   * @public
   */
  deleteUser() {
    this.setState({ inProgress: "delete" });

    const { handleApiError } = this;
    const { id, onSuccess } = this.props;

    api.delete(`${URL_API_USERS}/${id}`).then(response => {
      if (!!response && !!response.data && response.data.success) {
        if (this._isMounted) {
          this.setState({ deleteError: null, inProgress: false }, () =>
            onSuccess()
          );
        }
      } else {
        handleApiError(response);
      }
    });
  }

  /**
   * Handle API error
   * @param {*}
   * @public
   */
  handleApiError(error) {
    const message = getApiErrorMessage(error);

    if (this._isMounted) {
      this.setState({ inProgress: false, serverError: message }, () =>
        scrollIfNeeded(this.headline.current)
      );
    }
  }

  /**
   * Handle form input
   * * Expects an event or psuedo-event object
   * * Assumes target.id corresponds to a state property
   * @param {event|object} e
   * @public
   */
  handleInput(e) {
    const { target } = e;

    if (target && target.id) {
      this.setState({ [target.id]: target.value });
    }
  }

  /**
   * Handle submit
   * @param {event} e
   * @public
   */
  handleSubmit(e) {
    e.preventDefault();

    let isValid = true;
    const nextState = {};
    const { email, first_name, last_name, role } = this.state;

    if (!first_name) {
      isValid = false;
      nextState.first_nameError = "Required";
    } else {
      nextState.first_nameError = null;
    }

    if (!last_name) {
      isValid = false;
      nextState.last_nameError = "Required";
    } else {
      nextState.last_nameError = null;
    }

    if (!email) {
      isValid = false;
      nextState.emailError = "Required";
    } else if (!/[^\s@]+@[^\s@]+\.[^\s@]+/.test(email)) {
      isValid = false;
      nextState.emailError = "Must be a valid email address";
    } else {
      nextState.emailError = null;
    }

    if (!role) {
      isValid = false;
      nextState.roleError = "Required";
    } else {
      nextState.roleError = null;
    }

    this.setState(nextState);

    if (isValid) {
      this.putData();
    } else {
      scrollIfNeeded(this.headline.current);
    }
  }

  /**
   * Send data to API
   * @public
   */
  putData() {
    this.setState({ inProgress: "edit" });

    const { handleApiError } = this;
    const { id, onSuccess } = this.props;
    const data = {
      email: this.state.email,
      first_name: this.state.first_name,
      id: id,
      last_name: this.state.last_name,
      role: this.state.role,
    };

    api
      .put(`${URL_API_USERS}/${id}`, data)
      .then(response => {
        if (!!response && !!response.data && response.data.success) {
          if (this._isMounted) {
            this.setState({ inProgress: false, serverError: null }, () =>
              onSuccess()
            );
          }
        } else {
          handleApiError(response);
        }
      })
      .catch(error => handleApiError(error));
  }

  /**
   * Set user field values
   * @public
   */
  setUser() {
    const { currentUser, id, setTitle, users } = this.props;
    const editUser = users.find(user => user.id === id);

    if (!!editUser) {
      setTitle(
        `${this.defaultTitle} (${editUser.first_name} ${editUser.last_name})`
      );

      this.setState({
        email: editUser.email,
        found: true,
        first_name: editUser.first_name,
        isCurrent: !!currentUser && currentUser.id === id,
        last_name: editUser.last_name,
        role: editUser.role,
      });
    } else {
      this.setState(this.initialState);
    }
  }

  componentDidMount() {
    this._isMounted = true;
    const { setUser } = this;
    const { setTitle, users, usersError, usersLoading } = this.props;

    if (!usersError && !usersLoading && !!users) {
      setUser();
    } else {
      setTitle(this.defaultTitle);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.id !== this.props.id) {
      this.setUser();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { deleteUser, handleInput, handleSubmit, headline } = this;
    const {
      deleteError,
      email,
      emailError,
      found,
      inProgress,
      first_name,
      first_nameError,
      isCurrent,
      last_name,
      last_nameError,
      role,
      roleError,
      serverError,
    } = this.state;

    return found ? (
      <div className="layout-content">
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <Form
              className="listing-form mx-auto"
              noValidate
              onSubmit={handleSubmit}
            >
              <h2 className="mb-4" ref={headline}>
                Edit User
              </h2>
              {!!serverError && (
                <Alert className="p-4 text-center" color="danger">
                  <p className="mb-0">{serverError}</p>
                </Alert>
              )}
              {!!deleteError && (
                <Alert className="p-4 text-center" color="danger">
                  <p className="mb-0">{deleteError}</p>
                </Alert>
              )}
              <FormGroup>
                <Label className="col-form-label" for="name">
                  First Name
                </Label>
                <Input
                  bsSize="lg"
                  id="first_name"
                  invalid={!!first_nameError}
                  maxLength={200}
                  onChange={handleInput}
                  type="text"
                  value={first_name}
                />
                {!!first_nameError && (
                  <FormFeedback>{first_nameError}</FormFeedback>
                )}
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label" for="last_name">
                  Last Name
                </Label>
                <Input
                  bsSize="lg"
                  id="last_name"
                  invalid={!!last_nameError}
                  maxLength={200}
                  onChange={handleInput}
                  type="text"
                  value={last_name}
                />
                {!!last_nameError && (
                  <FormFeedback>{last_nameError}</FormFeedback>
                )}
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label" for="email">
                  Email
                </Label>
                <Input
                  bsSize="lg"
                  id="email"
                  invalid={!!emailError}
                  maxLength={200}
                  onChange={handleInput}
                  type="email"
                  value={email}
                />
                {!!emailError && <FormFeedback>{emailError}</FormFeedback>}
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label" for="role">
                  Role
                </Label>
                {isCurrent ? (
                  <Input
                    defaultValue={formatTitleCase(role)}
                    bsSize="lg"
                    id="role"
                    invalid={!!roleError}
                    readOnly
                  />
                ) : (
                  <CustomInput
                    bsSize="lg"
                    id="role"
                    invalid={!!roleError}
                    onChange={handleInput}
                    type="select"
                    value={role}
                  >
                    <option value="">— Select —</option>
                    <option value="admin">Admin</option>
                    <option value="sales">Sales</option>
                  </CustomInput>
                )}
                {!!roleError && <FormFeedback>{roleError}</FormFeedback>}
              </FormGroup>
              <div className="form-actions border-top-0">
                <Button color="success" disabled={!!inProgress} type="submit">
                  {inProgress === "edit" ? (
                    <Fragment>
                      Editing <Spinner className="ml-2" size="sm" />
                    </Fragment>
                  ) : (
                    "Edit User"
                  )}
                </Button>
                {!isCurrent && (
                  <Button
                    className="ml-auto"
                    color="danger"
                    disabled={!!inProgress}
                    onClick={deleteUser}
                    outline
                    type="button"
                  >
                    {inProgress === "delete" ? (
                      <Fragment>
                        Deleting <Spinner className="ml-2" size="sm" />
                      </Fragment>
                    ) : (
                      "Delete User"
                    )}
                  </Button>
                )}
              </div>
            </Form>
          </Col>
        </Row>
      </div>
    ) : (
      <div className="layout-content">
        <Alert className="p-4 text-center" color="info">
          <p>No user found with that ID.</p>
          <Link to={"/users"}>
            <strong>Back to Manage Users</strong>
          </Link>
        </Alert>
      </div>
    );
  }
}

UsersEdit.propTypes = {
  currentUser: shape({
    id: number,
  }),
  id: number,
  onSuccess: func.isRequired,
  setTitle: func.isRequired,
  users: array,
};

export default UsersEdit;
