import React, { useEffect, useRef } from "react";
import { func, string } from "prop-types";
import { useLocation } from "react-router-dom";

import { Alert, Button, Container } from "reactstrap";
import Layout from "../layout";

/**
 * Displays error message
 * @param {object} props
 * @param {string} props.name
 * @param {string} props.message
 * @param {func}   props.resetError
 */
const Error = ({ message = "Unknown Route", name = "Error", resetError }) => {
  const { pathname } = useLocation();
  const initialPathname = useRef(pathname);

  const handleClick = () => {
    if (!!resetError) {
      resetError();
    }
    window.location.reload(true);
  };

  useEffect(() => {
    if (!!resetError && pathname !== initialPathname.current) {
      resetError();
    }
  }, [pathname, resetError]);

  return (
    <Layout title="Error">
      <Container tag="article">
        <header>
          <h1>Something went&nbsp;wrong</h1>
        </header>
        <section>
          <div className="layout-content">
            <Alert className="p-4" color="danger">
              <h4 className="alert-heading">
                {message}{" "}
                {!!name && (
                  <>
                    &nbsp; <small className="text-nowrap">[ {name} ]</small>
                  </>
                )}
              </h4>
              <hr />
              <p>
                Please reload the page and try again. If&nbsp;the problem
                persists please contact support.
              </p>
              <Button color="danger" onClick={handleClick}>
                Reload Now
              </Button>
            </Alert>
          </div>
        </section>
      </Container>
    </Layout>
  );
};

Error.propTypes = {
  message: string,
  name: string,
  resetError: func,
};

export default Error;
