import React, { Component, Fragment } from "react";
import { func } from "prop-types";

import {
  Alert,
  Button,
  Col,
  CustomInput,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";

import { URL_API_USERS } from "../../../constants";
import { api, getApiErrorMessage, scrollIfNeeded } from "../../../helpers";

class UsersAdd extends Component {
  constructor(props) {
    super(props);

    this.handleApiError = this.handleApiError.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.postData = this.postData.bind(this);

    this._isMounted = false;
    this.headline = React.createRef();

    this.state = {
      email: "",
      emailError: null,
      inProgress: false,
      first_name: "",
      first_nameError: null,
      last_name: "",
      last_nameError: null,
      role: "",
      roleError: null,
      serverError: null,
    };
  }

  /**
   * Handle API error
   * @param {*}
   * @public
   */
  handleApiError(error) {
    const message = getApiErrorMessage(error);

    if (this._isMounted) {
      this.setState({ inProgress: false, serverError: message }, () =>
        scrollIfNeeded(this.headline.current)
      );
    }
  }

  /**
   * Handle form input
   * * Expects an event or psuedo-event object
   * * Assumes target.id corresponds to a state property
   * @param {event|object} e
   * @public
   */
  handleInput(e) {
    const { target } = e;

    if (target && target.id) {
      this.setState({ [target.id]: target.value });
    }
  }

  /**
   * Handle submit
   * @param {event} e
   * @public
   */
  handleSubmit(e) {
    e.preventDefault();

    let isValid = true;
    const nextState = {};
    const { email, first_name, last_name, role } = this.state;

    if (!first_name) {
      isValid = false;
      nextState.first_nameError = "Required";
    } else {
      nextState.first_nameError = null;
    }

    if (!last_name) {
      isValid = false;
      nextState.last_nameError = "Required";
    } else {
      nextState.last_nameError = null;
    }

    if (!email) {
      isValid = false;
      nextState.emailError = "Required";
    } else if (!/[^\s@]+@[^\s@]+\.[^\s@]+/.test(email)) {
      isValid = false;
      nextState.emailError = "Must be a valid email address";
    } else {
      nextState.emailError = null;
    }

    if (!role) {
      isValid = false;
      nextState.roleError = "Required";
    } else {
      nextState.roleError = null;
    }

    this.setState(nextState);

    if (isValid) {
      this.postData();
    } else {
      scrollIfNeeded(this.headline.current);
    }
  }

  /**
   * Send data to API
   * @public
   */
  postData() {
    this.setState({ inProgress: true });

    const { handleApiError } = this;
    const { onSuccess } = this.props;
    const data = {
      email: this.state.email,
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      role: this.state.role,
    };

    api
      .post(URL_API_USERS, data)
      .then(response => {
        if (!!response && !!response.data && response.data.success) {
          if (this._isMounted) {
            this.setState({ inProgress: false, serverError: null }, () =>
              onSuccess()
            );
          }
        } else {
          handleApiError(response);
        }
      })
      .catch(error => handleApiError(error));
  }

  componentDidMount() {
    this._isMounted = true;
    this.props.setTitle("Add a New User");
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { handleInput, handleSubmit, headline } = this;
    const {
      email,
      emailError,
      inProgress,
      first_name,
      first_nameError,
      last_name,
      last_nameError,
      role,
      roleError,
      serverError,
    } = this.state;

    return (
      <div className="layout-content">
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <Form
              className="listing-form mx-auto"
              noValidate
              onSubmit={handleSubmit}
            >
              <h2 className="mb-4" ref={headline}>
                Add a New User
              </h2>
              {!!serverError && (
                <Alert className="p-4 text-center" color="danger">
                  <p className="mb-0">{serverError}</p>
                </Alert>
              )}
              <FormGroup>
                <Label className="col-form-label" for="name">
                  First Name
                </Label>
                <Input
                  bsSize="lg"
                  id="first_name"
                  invalid={!!first_nameError}
                  maxLength={200}
                  onChange={handleInput}
                  type="text"
                  value={first_name}
                />
                {!!first_nameError && (
                  <FormFeedback>{first_nameError}</FormFeedback>
                )}
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label" for="last_name">
                  Last Name
                </Label>
                <Input
                  bsSize="lg"
                  id="last_name"
                  invalid={!!last_nameError}
                  maxLength={200}
                  onChange={handleInput}
                  type="text"
                  value={last_name}
                />
                {!!last_nameError && (
                  <FormFeedback>{last_nameError}</FormFeedback>
                )}
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label" for="email">
                  Email
                </Label>
                <Input
                  bsSize="lg"
                  id="email"
                  invalid={!!emailError}
                  maxLength={200}
                  onChange={handleInput}
                  type="email"
                  value={email}
                />
                {!!emailError && <FormFeedback>{emailError}</FormFeedback>}
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label" for="role">
                  Role
                </Label>
                <CustomInput
                  bsSize="lg"
                  id="role"
                  invalid={!!roleError}
                  onChange={handleInput}
                  type="select"
                  value={role}
                >
                  <option value="">— Select —</option>
                  <option value="admin">Admin</option>
                  <option value="sales">Sales</option>
                </CustomInput>
                {!!roleError && <FormFeedback>{roleError}</FormFeedback>}
              </FormGroup>
              <div className="form-actions border-top-0">
                <Button color="success" disabled={inProgress} type="submit">
                  {inProgress ? (
                    <Fragment>
                      Adding <Spinner className="ml-2" size="sm" />
                    </Fragment>
                  ) : (
                    "Add User"
                  )}
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

UsersAdd.propTypes = {
  onSuccess: func.isRequired,
  setTitle: func.isRequired,
};

export default UsersAdd;
