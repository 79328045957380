import { api, getApiErrorMessage } from "../../helpers";

import { URL_API_USERS } from "../../constants";

/**
 * Action types
 * @property {string} USERS_ERROR
 * @property {string} USERS_LOADING
 * @property {string} USERS_SUCCESS
 */
export const types = {
  USERS_ERROR: "USERS_ERROR",
  USERS_LOADING: "USERS_LOADING",
  USERS_SUCCESS: "USERS_SUCCESS",
};

/**
 * @namespace Reducers
 */
export const reducers = {
  /**
   * Updates users state
   * @param {object} state
   * @param {object} action
   * @param {string} action.type
   * @param {object} action.users
   * @memberof Reducers
   */
  users: (state = [], action) => {
    switch (action.type) {
      case types.USERS_SUCCESS:
        return action.users;
      default:
        return state;
    }
  },

  /**
   * Updates users error state
   * @param {object} state
   * @param {object} action
   * @param {string} action.type
   * @param {string} action.error
   * @memberof Reducers
   */

  usersError: (state = null, action) => {
    switch (action.type) {
      case types.USERS_ERROR:
        return action.error;
      case types.USERS_SUCCESS:
        return null;
      default:
        return state;
    }
  },

  /**
   * Updates users loading state
   * @param {object} state
   * @param {object} action
   * @param {string} action.type
   * @memberof Reducers
   */
  usersLoading: (state = true, action) => {
    switch (action.type) {
      case types.USERS_ERROR:
      case types.USERS_SUCCESS:
        return false;
      case types.USERS_LOADING:
        return true;
      default:
        return state;
    }
  },
};

/**
 * @namespace Actions
 */
export const actions = {
  /**
   * Fetch users from API
   * @memberof Actions
   */
  fetch: () => dispatch => {
    dispatch(actions.handleLoading());

    return api
      .get(URL_API_USERS)
      .then(response => {
        if (!!response && !!response.data && !!response.data.data) {
          dispatch(actions.handleSuccess(response.data.data));
        } else {
          dispatch(actions.parseError(response));
        }
      })
      .catch(error => {
        dispatch(actions.parseError(error));
      });
  },

  /**
   * Handle error messages
   * @param {string} message
   * @memberof Actions
   */
  handleError: message => ({
    type: types.USERS_ERROR,
    error: message,
  }),

  /**
   * Handle users loading events
   * @memberof Actions
   */
  handleLoading: () => ({
    type: types.USERS_LOADING,
  }),

  /**
   * Handle users API response
   * @param {array} users
   * @memberof Actions
   */
  handleSuccess: users => ({
    type: types.USERS_SUCCESS,
    users: users,
  }),

  /**
   * Parse errors and dispatch error message
   * @param {*} error
   * @memberof Actions
   */
  parseError: error => dispatch => {
    const message = getApiErrorMessage(error);
    dispatch(actions.handleError(message));
  },
};
