import React, { PureComponent } from "react";
import { array, func, string, object } from "prop-types";
import classNames from "classnames";
import qs from "query-string";
import { debounce } from "lodash";

import {
  CustomInput,
  Form,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
} from "reactstrap";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

// import { URL_API_MAKES_MODELS } from "../../constants";
import {
  // api,
  // CancelToken,
  // getApiErrorMessage,
  formatSourceDivision,
} from "../../helpers";

class Filter extends PureComponent {
  constructor(props) {
    super(props);

    this.getStatusValue = this.getStatusValue.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.handleModelsError = this.handleModelsError.bind(this);
    // this.lookupModels = this.lookupModels.bind(this);
    this.requestFiltered = this.requestFiltered.bind(this);
    this.requestFiltered = this.requestFiltered.bind(this);
    this.updateFilters = this.updateFilters.bind(this);
    this.updateSourcesForType = this.updateSourcesForType.bind(this);

    this._isMounted = false;
    this.apiCancel = () => false;

    this.state = {
      search: "",
      hasStatusConflict: false,
      make: "",
      model: "",
      // models: [],
      // modelsError: null,
      // modelsLoading: false,
      publishStatus: "",
      source: "",
      sourcesForType: [],
      attachments: "",
    };
  }

  /**
   * Get status value
   * @returns string
   * @public
   */
  getStatusValue() {
    switch (this.props.type) {
      case "draft":
        return "unpublished";
      case "live":
        return "published";
      default:
        return this.state.publishStatus;
    }
  }

  /**
   * Handle form input
   * * Expects an event or psuedo-event object
   * * Assumes target.id corresponds to a state property
   * @param {event|object} e
   * @public
   */
  handleInput(e) {
    const { target } = e;

    if (target && target.id) {
      this.setState({ [target.id]: target.value });
    }
  }

  /**
   * Handle models error
   * @param {error|object} error
   * @public
   */
  // handleModelsError(error) {
  //   const message = getApiErrorMessage(error);

  //   if (this._isMounted) {
  //     this.setState({
  //       models: [],
  //       modelsError: message,
  //     });
  //   }
  // }

  /**
   * Prevent default form submit behavior
   * @param {event} e
   * @public
   */
  handleSubmit(e) {
    e.preventDefault();
  }

  /**
   * Lookup available models for selected make
   * @param {string} makeName
   */
  // lookupModels(makeName) {
  //   const { makes } = this.props;
  //   const found = makes.find(make => make.name === makeName);

  //   if (this.state.modelsLoading) {
  //     this.apiCancel("Canceled");
  //   }

  //   if (!!found && found.id) {
  //     this.setState({ modelsLoading: true });

  //     api
  //       .get(`${URL_API_MAKES_MODELS}?makeID=${found.id}`, {
  //         cancelToken: new CancelToken(c => {
  //           this.apiCancel = c;
  //         }),
  //       })
  //       .then(response => {
  //         if (
  //           response &&
  //           response.data &&
  //           response.data.data &&
  //           Array.isArray(response.data.data)
  //         ) {
  //           if (this._isMounted) {
  //             const { data } = response.data;
  //             const models = [
  //               ...new Set(data.map(model => model.model)),
  //             ].sort();
  //             this.setState({ models: models, modelsError: null });
  //           }
  //         } else {
  //           this.handleModelsError(response);
  //         }
  //       })
  //       .catch(this.handleModelsError)
  //       .then(() => {
  //         if (this._isMounted) {
  //           this.setState({ modelsLoading: false });
  //         }
  //       });
  //   }
  // }

  /**
   * Request filtered listings
   * * debounced
   * @public
   */
  requestFiltered = debounce(() => {
    const { history } = this.props;
    const {
      search,
      hasStatusConflict,
      make,
      model,
      source,
      attachments,
    } = this.state;
    const query = {};
    const publishStatus = this.getStatusValue();

    if (search) {
      query.search = search;
    }

    if (make) {
      query.make = make;
    }

    if (model) {
      query.model = model;
    }

    if (source) {
      query.source = source;
    }

    if (publishStatus && !hasStatusConflict) {
      query.publishStatus = publishStatus;
    }

    if (!!attachments) {
      query.attachments = attachments;
    }

    if (!!history) {
      history.push({ search: qs.stringify(query) });
    }
  }, 1000);

  /**
   * Update filters from location.search values
   * @public
   */
  updateFilters() {
    const { location, type } = this.props;
    const { search, make, model, publishStatus, source, attachments } = location
      ? qs.parse(location.search)
      : {};

    this.setState({
      search: search || "",
      hasStatusConflict: ["draft", "live"].indexOf(type) > -1,
      make: make || "",
      model: model || "",
      // models: [],
      // modelsError: null,
      // modelsLoading: false,
      publishStatus: publishStatus || "",
      source: source || "",
      attachments: attachments || "",
    });
  }

  /**
   * Updated sources for active sourceType
   * @public
   */
  updateSourcesForType() {
    const sourceType = this.props.match?.params?.sourceType;
    const { sources } = this.props;

    if (sourceType === "new") {
      this.setState({
        sourcesForType: sources.filter(source =>
          ["kyrish", "truck"].includes(source)
        ),
      });
    } else {
      this.setState({ sourcesForType: sources });
    }
  }

  componentDidMount() {
    this._isMounted = true;
    this.updateFilters();

    const {
      // fetchMakes,
      fetchSources,
      // makes,
      sources,
    } = this.props;

    // if (makes.length === 0) {
    //   fetchMakes();
    // }

    if (sources.length === 0) {
      fetchSources();
    } else {
      this.updateSourcesForType();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      // lookupModels,
      requestFiltered,
    } = this;
    const {
      location,
      // makes,
      type,
    } = this.props;
    const {
      search,
      make,
      model,
      publishStatus,
      source,
      attachments,
    } = this.state;

    if (
      search !== prevState.search ||
      make !== prevState.make ||
      model !== prevState.model ||
      publishStatus !== prevState.publishStatus ||
      source !== prevState.source ||
      attachments !== prevState.attachments
    ) {
      requestFiltered();
    }

    // if (make && prevProps.makes.length === 0 && makes.length > 0) {
    //   lookupModels(make);
    // } else if (make !== prevState.make && makes.length > 0) {
    //   lookupModels(make);
    // }

    // Handle edge case where user re-clicks main navigation
    // or selected type creates a status conflict
    if (
      (location &&
        !location.search &&
        prevProps.location.search !== location.search) ||
      prevProps.type !== type
    ) {
      this.updateFilters();
    }

    // Handle case where user resets make input
    // if (!!prevState.make && prevState.make !== make) {
    //   this.setState({ model: "" }, () => {
    //     this.requestFiltered();
    //   });
    // }

    if (
      this.props.sources !== prevProps.sources ||
      this.props.match?.params?.sourceType !==
        prevProps.match?.params?.sourceType
    ) {
      this.updateSourcesForType();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.apiCancel();
  }

  render() {
    const { getStatusValue, handleInput, handleSubmit } = this;
    const {
      // makes,
      // makesError,
      sourcesError,
    } = this.props;
    const {
      search,
      // make,
      // model,
      // models,
      // modelsError,
      // modelsLoading,
      publishStatus,
      source,
      sourcesForType,
      hasStatusConflict,
      attachments,
    } = this.state;
    const sourceType = this.props.match?.params?.sourceType;

    return (
      <Form className="listings-filter" noValidate onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="attachments">Digital Attachments</Label>
          <CustomInput
            className={classNames({ placeholder: !attachments })}
            id="attachments"
            onChange={handleInput}
            type="select"
            value={attachments}
          >
            <option value="">All Listings</option>
            <option value="false">
              All listings without any digital attachments
            </option>
            <option value="true">
              All listings with at least one digital attachment
            </option>
          </CustomInput>
        </FormGroup>
        {/* <FormGroup>
          <Label for="make">Manufacturer</Label>
          <CustomInput
            className={classNames({ placeholder: !make })}
            disabled={makes.length === 0}
            id="make"
            onChange={handleInput}
            type="select"
            value={make}
          >
            <option value="">- - Select Manufacturer - -</option>
            {makes.map(make => (
              <option key={make.id} value={make.name}>
                {make.name}
              </option>
            ))}
          </CustomInput>
          {!!makesError && (
            <FormText className="position-absolute" color="danger">
              {makesError}
            </FormText>
          )}
          {makes.length === 0 && !makesError && (
            <FormText className="font-italic position-absolute">
              Loading…
            </FormText>
          )}
        </FormGroup>
        <FormGroup>
          <Label for="model">Model</Label>
          <CustomInput
            className={classNames({ placeholder: !model })}
            disabled={!make}
            id="model"
            onChange={handleInput}
            type="select"
            value={model}
          >
            <option value="">- - Select Model - -</option>
            {models.length > 0 &&
              models.map((model, index) => (
                <option key={index} value={model}>
                  {model}
                </option>
              ))}
          </CustomInput>
          {!!modelsError && (
            <FormText className="position-absolute" color="danger">
              {modelsError}
            </FormText>
          )}
          {modelsLoading && (
            <FormText className="font-italic position-absolute">
              Loading…
            </FormText>
          )}
        </FormGroup> */}
        <FormGroup>
          <Label for="source">Source</Label>
          <CustomInput
            className={classNames({ placeholder: !source })}
            id="source"
            onChange={handleInput}
            type="select"
            value={source}
          >
            <option value="">- - Select Source - -</option>
            {sourcesForType.map(
              source =>
                !!source && (
                  <option key={source} value={source}>
                    {formatSourceDivision(source)}
                  </option>
                )
            )}
          </CustomInput>
          {!!sourcesError && (
            <FormText className="position-absolute" color="danger">
              {sourcesError}
            </FormText>
          )}
          {sourcesForType.length === 0 && !sourcesError && (
            <FormText className="font-italic position-absolute">
              Loading…
            </FormText>
          )}
        </FormGroup>
        {sourceType !== "new" && (
          <FormGroup>
            <Label for="publishStatus">Status</Label>
            <CustomInput
              className={classNames({ placeholder: !publishStatus })}
              disabled={hasStatusConflict}
              id="publishStatus"
              onChange={handleInput}
              type="select"
              value={getStatusValue()}
            >
              <option value="">All Listings</option>
              <option value="published">Live Only</option>
              <option value="unpublished">Draft Only</option>
            </CustomInput>
          </FormGroup>
        )}
        <FormGroup>
          <Label for="search">Search</Label>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <span className="input-group-text">
                <Icon icon={faSearch} />
              </span>
            </InputGroupAddon>
            <Input
              addon
              id="search"
              onChange={handleInput}
              placeholder="Search ID, Serial, etc."
              type="text"
              value={search}
            />
          </InputGroup>
        </FormGroup>
      </Form>
    );
  }
}

Filter.propTypes = {
  fetchMakes: func,
  fetchSources: func,
  history: object,
  location: object,
  makes: array,
  makesError: string,
  sources: array,
  sourcesError: string,
  type: string,
};

export default Filter;
