import React, { Component, Fragment } from "react";
import { array, bool, func, object, shape, string } from "prop-types";

import { Alert, Container, Nav, NavLink, Spinner } from "reactstrap";
import { Route, Switch, NavLink as RouterNavLink } from "react-router-dom";

import Layout from "../layout";
import UsersAdd from "./components/Users.add";
import UsersEdit from "./components/Users.edit";
import UsersTable from "./components/Users.table";
import UsersUpload from "./components/Users.upload";

class Users extends Component {
  constructor(props) {
    super(props);

    this.handleUsersUpdate = this.handleUsersUpdate.bind(this);
    this.setTitle = this.setTitle.bind(this);

    const { match } = this.props;

    this.defaultTitle = "Users";

    this.state = {
      routeBase: match && match.path && `/${match.path.split("/")[1]}`,
      title: this.defaultTitle,
    };
  }

  /**
   * Handle new users
   * @public
   */
  handleUsersUpdate() {
    this.props.fetchUsers();
    this.props.history.push(this.state.routeBase);
  }

  /**
   * Set page title
   * @param {string} title
   * @param
   */
  setTitle(title) {
    this.setState({ title: !!title ? title : this.defaultTitle });
  }

  componentDidMount() {
    const { fetchUsers, user, users } = this.props;

    if (
      (!!user && user.role === "admin" && !Array.isArray(users)) ||
      users.length < 1
    ) {
      fetchUsers();
    }
  }

  render() {
    const { handleUsersUpdate, setTitle } = this;
    const { match, user, users, usersError, usersLoading } = this.props;
    const { routeBase, title } = this.state;
    const sharedProps = {
      onSuccess: handleUsersUpdate,
      setTitle: setTitle,
    };
    return (
      <Layout title={title}>
        <Container tag="article">
          <header>
            <p className="h1">Users</p>
          </header>
          <section>
            {!!user && user.role === "admin" ? (
              <Fragment>
                <Nav className="listings-nav d-block d-lg-flex" tag="nav">
                  <NavLink
                    activeClassName="active"
                    exact
                    tag={RouterNavLink}
                    to={`${routeBase}`}
                  >
                    Manage
                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    exact
                    tag={RouterNavLink}
                    to={`${routeBase}/add`}
                  >
                    Add
                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    exact
                    tag={RouterNavLink}
                    to={`${routeBase}/upload`}
                  >
                    Upload
                  </NavLink>
                </Nav>
                {usersLoading && (
                  <div className="layout-content">
                    <Alert className="p-4" color="info">
                      <div className="align-items-center d-flex justify-content-center">
                        <Spinner />
                        <p className="mb-0 ml-3 pr-5">
                          <strong>Loading…</strong>
                        </p>
                      </div>
                    </Alert>
                  </div>
                )}
                {!!usersError && (
                  <div className="layout-content">
                    <Alert className="p-4 text-center" color="danger">
                      <p className="mb-0">{usersError}</p>
                    </Alert>
                  </div>
                )}
                {!usersLoading && !usersError && (
                  <Switch>
                    <Route
                      exact
                      path={`${routeBase}/add`}
                      render={() => <UsersAdd {...sharedProps} />}
                    />
                    <Route
                      exact
                      path={`${routeBase}/upload`}
                      render={() => <UsersUpload {...sharedProps} />}
                    />
                    <Route
                      exact
                      path={`${routeBase}/:id`}
                      render={() => (
                        <UsersEdit
                          {...sharedProps}
                          id={
                            !!match.params.id
                              ? parseInt(match.params.id, 10)
                              : null
                          }
                          currentUser={user}
                          error={usersError}
                          loading={usersLoading}
                          users={users}
                        />
                      )}
                    />
                    <Route
                      render={() => (
                        <UsersTable
                          {...sharedProps}
                          currentUser={user}
                          error={usersError}
                          loading={usersLoading}
                          users={users}
                        />
                      )}
                    />
                  </Switch>
                )}
              </Fragment>
            ) : (
              <div className="layout-content">
                <Alert className="font-weight-bold text-center" color="danger">
                  You don’t have permission to view this page.
                </Alert>
              </div>
            )}
          </section>
        </Container>
      </Layout>
    );
  }
}

Users.propTypes = {
  fetchUsers: func.isRequired,
  history: object.isRequired,
  match: object.isRequired,
  user: shape({
    role: string,
  }),
  users: array,
  usersError: string,
  usersLoading: bool,
};

export default Users;
