import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";

import { Col, Container, Row } from "reactstrap";

import { SITE_DATA_UPLOAD_OPTIONS } from "../../constants";

import { DataUploadsCsvForm, DataUploadsSerialNumbersForm } from "./components";
import Layout from "../layout";

const DataUploads = () => {
  const { type: uploadTypeParam } = useParams();
  const [type, setType] = useState("");
  const [title, setTitle] = useState("");
  const [sentence, setSentence] = useState("");
  const [format, setFormat] = useState("");
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    const uploadType = SITE_DATA_UPLOAD_OPTIONS.find(
      option => uploadTypeParam === option.path
    );
    if (!!uploadType) {
      setType(uploadType.type);
      setTitle(uploadType.title);
      setSentence(uploadType.sentence);
      setFormat(uploadType.format);
      setNotFound(false);
    } else {
      setNotFound(true);
    }
  }, [uploadTypeParam]);

  if (notFound) {
    return (
      <Redirect to={`/data-uploads/${SITE_DATA_UPLOAD_OPTIONS[0].path}`} />
    );
  }

  return !type ? null : (
    <Layout title={`${title} - Data Uploads`}>
      <Container tag="article">
        <header>
          <p className="h1">
            <span className="d-block h6 mt-n3 position-absolute text-muted">
              Data Uploads
            </span>
            {title}
          </p>
        </header>
        <section>
          <div className="layout-content">
            <Row>
              <Col lg={{ size: 6, offset: 3 }}>
                {format === "csv" && (
                  <DataUploadsCsvForm sentence={sentence} type={type} />
                )}
                {format === "serial-numbers" && (
                  <DataUploadsSerialNumbersForm
                    sentence={sentence}
                    type={type}
                  />
                )}
              </Col>
            </Row>
          </div>
        </section>
      </Container>
    </Layout>
  );
};

export default DataUploads;
