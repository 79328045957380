import React, { Component } from "react";
import * as Sentry from "@sentry/react";
import { func, object, string } from "prop-types";
import {
  Alert,
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

import Layout from "../layout";

import { SITE_DEFAULT, URL_PUBLIC_FORGOT } from "../../constants";

import logo from "../../assets/img/logos/logo-holt-used.svg";

/**
 * User authentication form
 */
class Login extends Component {
  constructor(props) {
    super(props);

    this.email = React.createRef();
    this.password = React.createRef();

    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      errors: [],
      emailError: "",
      passwordError: "",
      redirect: SITE_DEFAULT,
    };
  }

  /**
   * Handle form submission
   *
   * * Validate form input
   *  * Email defined and uses valid pattern
   *  * Password defined
   * * Execute login action
   *  * If succeeds, redirect to `SITE_DEFAULT` or last location
   *  * If fails, display error message
   * @param {event} e
   * @public
   */
  handleSubmit(e) {
    e.preventDefault();

    const input = {
      email: this.email.current.value,
      password: this.password.current.value,
    };

    let emailError = "";
    let passwordError = "";

    if (!input.email) {
      emailError = "Email Address required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input.email)) {
      emailError = "Email Address not valid";
    }

    if (!input.password) {
      passwordError = "Password required";
    }

    this.setState({
      emailError: emailError,
      passwordError: passwordError,
    });

    if (emailError === "" && passwordError === "") {
      this.props.login(input).then(response => {
        if (response.success) {
          this.props.history.push(this.state.redirect);
        } else {
          this.setState({ errors: [response.message || "Error"] });
        }
      });
    }
  }

  componentDidMount() {
    const { authMessage, location, logout } = this.props;

    if (location.state && location.state.from) {
      const { pathname, search, hash } = location.state.from;
      this.setState({ redirect: `${pathname}${search}${hash}` });
    }

    if (!authMessage) {
      logout();
    }
  }

  render() {
    const { email, handleSubmit, password } = this;
    const { authMessage } = this.props;
    const { emailError, errors, passwordError } = this.state;

    return (
      <Layout
        layoutClasses={{ "layout-has-header": false }}
        layoutMainClasses={{ "layout-main-login": true }}
        title="Login"
      >
        <div className="d-flex flex-column h-100 justify-content-center">
          <div className="login-container">
            <Form className="login-form" noValidate onSubmit={handleSubmit}>
              <img
                alt="HOLT Used logo"
                className="login-logo"
                src={logo}
                width={372}
              />
              {authMessage && errors.length === 0 && (
                <Alert className="mb-4_5" color="info">
                  {authMessage}
                </Alert>
              )}
              {errors.length > 0 && (
                <Alert className="mb-4_5" color="danger">
                  <ul className="mb-0">
                    {errors.map((error, index) => (
                      <li key={`${index}`}>{error}</li>
                    ))}
                  </ul>
                </Alert>
              )}
              <FormGroup>
                <Label for="email">Email Address</Label>
                <Input
                  id="email"
                  innerRef={email}
                  invalid={emailError !== ""}
                  name="email"
                  bsSize="lg"
                  type="email"
                />
                {emailError && (
                  <FormFeedback tooltip>{emailError}</FormFeedback>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="password">Password</Label>
                <Input
                  id="password"
                  innerRef={password}
                  invalid={passwordError !== ""}
                  name="password"
                  bsSize="lg"
                  type="password"
                />
                {passwordError && (
                  <FormFeedback tooltip>{passwordError}</FormFeedback>
                )}
              </FormGroup>
              <div className="login-form-actions">
                <Button color="info" size="sm" type="submit">
                  Login
                </Button>
                <p>
                  <a
                    href={URL_PUBLIC_FORGOT}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Forgot Password?
                  </a>
                </p>
              </div>
            </Form>
          </div>
        </div>
      </Layout>
    );
  }
}

Login.getDerivedStateFromError = error => {
  if (error instanceof Error && process.env.NODE_ENV !== "development") {
    try {
      Sentry.captureException(error);
    } catch (error) {}
  }

  return {
    errors: [error.message || "Unknown error"],
  };
};

Login.propTypes = {
  /**
   * Message from Redux store displayed to the user
   * when their authentication has expired
   */
  authMessage: string,
  /** Shared browser history helper */
  history: object.isRequired,
  /**
   * mailto: link for creating new forgot password email
   */
  forgotHref: string,
  /** React Router location */
  location: object.isRequired,
  /** Dispatches action to post credentials to API */
  login: func.isRequired,
  /**
   * Dispatches logout action to Redux store,
   * only called when no authentication message is present
   */
  logout: func.isRequired,
};

export default Login;
