import { getFullYear } from "./dates";
import { formatSourceDivision, formatTitleCase } from "./format";
import { lookupLocation } from "./locations";

export const normalizeListing = listing => {
  // end early if undefined
  if (!listing || listing.constructor.name !== "Object") {
    return null;
  }

  // handle null value for adminNotes
  if (listing.hasOwnProperty("adminNotes") && listing.adminNotes === null) {
    listing.adminNotes = "";
  }

  // handle null or zero value for category_id
  if (
    listing.hasOwnProperty("category_id") &&
    [null, 0, "0"].includes(listing.category_id)
  ) {
    listing.category_id = "";
  }

  // handle null or zero value for overridePrice
  if (
    listing.hasOwnProperty("overridePrice") &&
    [null, 0, "0"].includes(listing.overridePrice)
  ) {
    listing.overridePrice = "";
  }

  // Check if this listing has photos
  if (!!listing.photos && typeof listing.photos === "string") {
    listing.photos = JSON.parse(listing.photos);
  }

  listing.photos = Array.isArray(listing.photos) ? listing.photos : [];

  // Process photos into a predictable shape and count
  const {
    photos,
    draftPhotos,
    hasDraftPhotos,
    photoCounts,
  } = listing.photos.reduce(
    (processed, photo) => {
      try {
        if (!photo || typeof photo === "string" || photo instanceof String) {
          throw new Error("Unexpected value");
        }

        if (!photo.hasOwnProperty("original")) {
          throw new Error("Invalid shape");
        }

        if (!photo.hasOwnProperty("availableIndex")) {
          photo.availableIndex = 0;
        } else if (isNaN(photo.availableIndex)) {
          const availableIndex = parseInt(photo.availableIndex);
          photo.availableIndex = !isNaN(availableIndex) ? availableIndex : 0;
        }

        if (!photo.hasOwnProperty("lastModified")) {
          photo.lastModified = "";
        } else if (photo.lastModified.hasOwnProperty("date")) {
          photo.lastModified = photo.lastModified.date;
        }

        if (!!photo.liveCropped) {
          if (!photo.hasOwnProperty("liveIndex")) {
            photo.liveIndex = 0;
          } else if (isNaN(photo.liveIndex)) {
            const liveIndex = parseInt(photo.liveIndex);
            photo.liveIndex = !isNaN(liveIndex) ? liveIndex : 0;
          }

          processed.photoCounts.live++;
        }

        if (!!photo.draftCropped) {
          if (!photo.hasOwnProperty("draftIndex")) {
            photo.draftIndex = 0;
          } else if (isNaN(photo.draftIndex)) {
            const draftIndex = parseInt(photo.draftIndex);
            photo.draftIndex = !isNaN(draftIndex) ? draftIndex : 0;
          }

          if (photo.draftNew || photo.draftIndex !== photo.liveIndex) {
            if (!processed.hasDraftPhotos) {
              processed.hasDraftPhotos = true;
            }

            processed.photoCounts.draft++;
          }

          processed.draftPhotos.push(photo);
        }

        processed.photos.push(photo);
        processed.photoCounts.total++;
      } catch (err) {}

      return processed;
    },
    {
      photos: [],
      draftPhotos: [],
      hasDraftPhotos: false,
      photoCounts: { total: 0, draft: 0, live: 0 },
    }
  );

  listing.photos = photos.sort((a, b) => a.availableIndex - b.availableIndex);
  listing.draftPhotos = draftPhotos.sort((a, b) => a.draftIndex - b.draftIndex);
  listing.hasDraftPhotos = hasDraftPhotos;
  listing.photoCounts = photoCounts;

  // Normalize formatting
  if (!!listing.year) {
    listing.year = getFullYear(listing.year);
  }

  if (!!listing.make) {
    listing.make = listing.make.toUpperCase();
  }

  if (!listing.meterValue && listing.meterValue !== 0) {
    listing.meterValue = undefined;
  }

  if (!!listing.model) {
    listing.model = listing.model.toUpperCase();
  }

  if (!!listing.locationCity) {
    listing.locationCity = formatTitleCase(listing.locationCity);
  }

  if (!!listing.locationState) {
    const loc = lookupLocation(listing.locationState);

    if (loc) {
      listing.locationState = loc.region.abbr;

      if (!listing.locationCountry) {
        listing.locationCountry = loc.country.name;
      }
    }
  }

  // Normalize isInternal flag
  if (listing.hasOwnProperty("isInternal")) {
    listing.isInternal = parseInt(listing.isInternal, 10);
  }

  // Get user-friendly source division
  if (listing.hasOwnProperty("sourceDivision")) {
    listing.sourceDivision = formatSourceDivision(listing.sourceDivision);
  }

  return listing;
};

export const normalizeListingsData = listingsData => {
  listingsData =
    !!listingsData && Array.isArray(listingsData) ? listingsData : [];

  if (!!listingsData.length) {
    listingsData = listingsData
      .map(normalizeListing)
      .filter(listing => !!listing);
  }

  return listingsData;
};

export const displayMeter = ({ meterValue, sourceDivision }) =>
  (meterValue || meterValue === 0) &&
  !["attachments", "attachemnts"].includes(`${sourceDivision.toLowerCase()}`);
