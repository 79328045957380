import React, { Component, Fragment } from "react";
import { array, bool, func, number, string } from "prop-types";
import classNames from "classnames";

import { Spinner } from "reactstrap";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faCrop,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";

import { getShortStamp } from "../../../helpers";

import PhotosImage from "./Photos.image";

class PhotosLibrary extends Component {
  constructor(props) {
    super(props);

    this.choosePhoto = this.choosePhoto.bind(this);
    this.getRoll = this.getRoll.bind(this);
    this.show = this.show.bind(this);

    const { photos, rollLimit } = this.props;

    this.state = {
      active: 0,
      animate: false,
      activeDirection: "next",
      choosing: false,
      roll: [],
      rollMax: Math.ceil(photos.length / rollLimit),
      rollPage: 0,
    };
  }

  /**
   * Choose photo
   * @param {string} action
   * @param {string} handle
   * @public
   */
  choosePhoto(action, handle) {
    if (this.props[action]) {
      this.setState({ choosing: true });
      this.props[action](handle);
    }
  }

  /**
   * Get paged results for photos roll
   * @param {number} page
   * @public
   */
  getRoll(page) {
    page = page || 0;

    const { photos, rollLimit } = this.props;
    const start = page * rollLimit;
    const end = start + rollLimit;
    const roll = photos.filter((photo, index) => index >= start && index < end);

    let active = this.state.active;
    let direction = this.state.direction;

    if (active < start) {
      active = start;
      direction = "next";
    } else if (active >= end) {
      active = (end < photos.length ? end : photos.length) - 1;
      direction = "prev";
    }

    this.setState({
      active: active,
      activeDirection: direction,
      roll: roll,
      rollPage: page,
    });
  }

  /**
   * Show active photo
   * @param {number} index
   * @param {boolean} isNew
   * @public
   */
  show(index, isNew) {
    const { rollLimit } = this.props;
    const { active, rollPage } = this.state;
    const direction = index < active ? "prev" : "next";
    const page = Math.floor(index / rollLimit);

    if (!isNew && page !== rollPage) {
      this.getRoll(page);
    }

    this.setState(
      {
        active: index,
        activeDirection: direction,
      },
      () => {
        if (isNew) {
          this.getRoll(page);
        }
      }
    );
  }

  componentDidMount() {
    this.getRoll();
    this.setState({ animate: true });
  }

  componentDidUpdate(prevProps) {
    // Set first new photo to active after upload
    const { getRoll, show } = this;
    const { photos, rollLimit } = this.props;
    let newPhoto = 0;

    photos.every((photo, index) => {
      if (
        prevProps.photos.some(
          prevPhoto => prevPhoto.original === photo.original
        )
      ) {
        return true;
      } else {
        newPhoto = index;
        return false;
      }
    });

    if (newPhoto) {
      show(newPhoto, true);
    }

    // Update photos roll after selection or removal
    if (prevProps.photos.length !== photos.length) {
      if (!photos.length) {
        this.setState({ choosing: false, roll: [], rollMax: 0, rollPage: 0 });
      } else {
        const { active } = this.state;

        if (prevProps.photos.length > photos.length) {
          const nextPhoto = !photos[active] ? active - 1 : active;
          show(nextPhoto, true);
        } else {
          if (prevProps.photos[active].original !== photos[active].original) {
            show(prevProps.photos[active].groupIndex, true);
          } else {
            getRoll();
          }
        }

        this.setState({
          choosing: false,
          rollMax: Math.ceil(photos.length / rollLimit),
        });
      }
    } else if (
      prevProps.cropOpen !== this.props.cropOpen &&
      !this.props.cropOpen
    ) {
      this.setState({ choosing: false });
    }
  }

  render() {
    const { choosePhoto, getRoll, show } = this;
    const { cdn, photos } = this.props;
    const {
      active,
      animate,
      activeDirection,
      choosing,
      roll,
      rollMax,
      rollPage,
    } = this.state;

    return !!cdn ? (
      <Fragment>
        {photos.length > 1 && (
          <div className="photos-library-roll">
            {rollMax > 1 && (
              <button
                className="photos-library-roll-nav photos-library-roll-prev"
                disabled={rollPage - 1 < 0}
                onClick={() => getRoll(rollPage - 1)}
                type="button"
              >
                <Icon icon={faChevronLeft} />
                <span className="sr-only">Previous</span>
              </button>
            )}
            <TransitionGroup
              className={classNames({
                "photos-library-roll-transition": true,
                next: activeDirection === "next",
                prev: activeDirection === "prev",
              })}
            >
              <CSSTransition
                appear={animate}
                key={roll}
                timeout={{
                  enter: 0,
                  exit: 400,
                }}
                unmountOnExit
              >
                <div className="photos-library-slide">
                  {roll.map(photo => {
                    return (
                      <button
                        className={classNames({
                          "photos-library-thumb": true,
                          active: photo.groupIndex === active,
                        })}
                        key={photo.original}
                        onClick={() => show(photo.groupIndex)}
                        type="button"
                      >
                        {choosing && photo.groupIndex === active ? (
                          <Spinner color="warning" />
                        ) : (
                          <PhotosImage
                            alt="thumbnail"
                            src={`${cdn}/resize=width:50,height:50,fit:crop/cache=expiry:max/${photo.original}`}
                            srcSet={`${cdn}/resize=width:50,height:50,fit:crop/cache=expiry:max/${photo.original} 1x, ${cdn}/resize=width:100,height:100,fit:crop/cache=expiry:max/${photo.original} 2x`}
                          />
                        )}
                      </button>
                    );
                  })}
                </div>
              </CSSTransition>
            </TransitionGroup>
            {rollMax > 1 && (
              <button
                className="photos-library-roll-nav photos-library-roll-next"
                disabled={rollPage + 1 >= rollMax}
                onClick={() => getRoll(rollPage + 1)}
                type="button"
              >
                <Icon icon={faChevronRight} />
                <span className="sr-only">Previous</span>
              </button>
            )}
          </div>
        )}
        {rollMax > 1 && (
          <ul className="photos-library-roll-pages">
            {[...Array(rollMax).keys()].map((_, i) => (
              <li key={i}>
                <button
                  className={classNames({
                    active: i === rollPage,
                  })}
                  onClick={() => getRoll(i)}
                  type="button"
                >
                  <span className="sr-only">Page {i + 1}</span>
                </button>
              </li>
            ))}
          </ul>
        )}
        <div className="photos-library-active">
          <div className="photos-library-active-meta">
            <div className="small">
              <strong>Photo Source:</strong>{" "}
              {!!photos[active] && !!photos[active].source
                ? photos[active].source
                : "–"}
            </div>
            <div className="small">
              <strong>Photo Last Modified:</strong>{" "}
              {!!photos[active] && !!photos[active].lastModified
                ? getShortStamp(photos[active].lastModified)
                : "–"}
            </div>
          </div>
          <div className="photos-library-active-slider">
            <button
              className="photos-library-active-nav photos-library-active-prev"
              disabled={active - 1 < 0}
              onClick={() => show(active - 1)}
              type="button"
            >
              <Icon icon={faChevronLeft} />
              <span className="sr-only">Previous</span>
            </button>
            <TransitionGroup
              className={classNames({
                "photos-library-active-transition": true,
                next: activeDirection === "next",
                prev: activeDirection === "prev",
              })}
            >
              <CSSTransition
                appear={animate}
                key={active}
                timeout={{
                  enter: 0,
                  exit: 400,
                }}
              >
                <div className="photos-libary-active-img">
                  {photos[active] && photos[active].original && (
                    <Fragment>
                      {choosing ? (
                        <Spinner
                          color="primary"
                          style={{ width: "6rem", height: "6rem" }}
                        />
                      ) : (
                        <PhotosImage
                          alt="active"
                          src={`${cdn}/resize=height:600,fit:max/cache=expiry:max/${photos[active].original}`}
                          srcSet={`${cdn}/resize=height:600,fit:max/cache=expiry:max/${photos[active].original} 1x, ${cdn}/resize=height:1200,fit:max/cache=expiry:max/${photos[active].original} 2x`}
                        />
                      )}
                    </Fragment>
                  )}
                </div>
              </CSSTransition>
            </TransitionGroup>
            <button
              className="photos-library-active-nav photos-library-active-next"
              disabled={active + 1 >= photos.length}
              onClick={() => show(active + 1)}
              type="button"
            >
              <Icon icon={faChevronRight} />
              <span className="sr-only">Next</span>
            </button>
          </div>
          <div className="photos-library-actions">
            <button
              onClick={() => {
                choosePhoto("crop", photos[active].original);
              }}
              type="button"
            >
              <Icon icon={faCrop} />
              <span className="sr-only">Crop</span>
            </button>
            <button
              onClick={() => choosePhoto("select", photos[active].original)}
              type="button"
            >
              <Icon icon={faPlusCircle} />
              <span className="sr-only">Select</span>
            </button>
          </div>
        </div>
      </Fragment>
    ) : null;
  }
}

PhotosLibrary.propTypes = {
  cdn: string.isRequired,
  crop: func.isRequired,
  cropOpen: bool,
  photos: array.isRequired,
  rollLimit: number,
  select: func.isRequired,
};

PhotosLibrary.defaultProps = {
  cropOpen: false,
  rollLimit: 13,
};

export default PhotosLibrary;
