import React, { Component, Fragment } from "react";
import { func } from "prop-types";
import classNames from "classnames";

import {
  Alert,
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";

import {
  REQUEST_MAX_SIZE,
  URL_API_USERS_IMPORT,
  URL_ORIGIN,
  URL_PUBLIC,
} from "../../../constants";
import {
  api,
  CancelToken,
  getApiErrorMessage,
  getFileSize,
  removeProtocol,
  scrollIfNeeded,
} from "../../../helpers";

import sampleCsv from "../../../assets/csv/holtused-batchuserupload-example.csv";

class UsersUpload extends Component {
  constructor(props) {
    super(props);

    this.handleApiError = this.handleApiError.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this._isMounted = false;
    this.apiCancel = () => false;
    this.headline = React.createRef();

    this.state = {
      error: null,
      file: "",
      inProgress: false,
      serverError: null,
    };
  }

  /**
   * Handle API error
   * @param {*}
   * @public
   */
  handleApiError(error) {
    const message = getApiErrorMessage(error);

    if (this._isMounted) {
      this.setState({ inProgress: false, serverError: message }, () =>
        scrollIfNeeded(this.headline.current)
      );
    }
  }

  /**
   * Pass the selected file back to the parent component
   * @param {event} e
   * @public
   */
  handleInput(e) {
    const files = Array.from(e.target.files);

    if (!!files.length) {
      const errors = [];

      if (files[0].size > REQUEST_MAX_SIZE) {
        errors.push(
          `File cannot be larger than ${getFileSize(REQUEST_MAX_SIZE)}`
        );
      }

      if (files[0].type !== "text/csv") {
        errors.push("File must be a CSV");
      }

      if (!!errors.length) {
        this.setState({ error: errors.join(", ") });
      } else {
        this.setState({ error: null, file: files[0] });
      }
    }
  }

  /**
   * Handle form submit
   * @param {event} e
   * @publid
   */
  handleSubmit(e) {
    e.preventDefault();

    const { handleApiError } = this;
    const { onSuccess } = this.props;
    const { error, file } = this.state;

    if (!!error) {
      return false;
    } else if (!file) {
      this.setState({ error: "Required" });
      return false;
    }

    this.setState({ inProgress: true });

    // Prep form data
    const formData = new FormData();

    formData.append("file", file);

    api
      .post(URL_API_USERS_IMPORT, formData, {
        cancelToken: new CancelToken(c => {
          this.apiCancel = c;
        }),
      })
      .then(response => {
        if (!!response && !!response.data && response.data.success) {
          if (this._isMounted) {
            this.setState(
              {
                error: null,
                file: "",
                inProgress: false,
                serverError: null,
              },
              () => onSuccess()
            );
          }
        } else {
          handleApiError(response);
        }
      })
      .catch(error => handleApiError(error));
  }

  componentDidMount() {
    this._isMounted = true;
    this.props.setTitle("Batch Upload New Users");
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.apiCancel();
  }

  render() {
    const { handleInput, handleSubmit, headline } = this;
    const { error, file, inProgress, serverError } = this.state;

    return (
      <div className="layout-content">
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <Form
              className="listing-form mx-auto"
              noValidate
              onSubmit={handleSubmit}
            >
              <h2 className="mb-4" ref={headline}>
                Batch Upload (CSV)
              </h2>
              <p>
                Upload multiple new users for password-protected access to{" "}
                {removeProtocol(URL_PUBLIC)} and {removeProtocol(URL_ORIGIN)}.
                Download a sample csv{" "}
                <a download href={sampleCsv}>
                  here
                </a>
                , which also shows the available roles (aka, user levels).
              </p>
              {!!serverError && (
                <Alert className="p-4 text-center" color="danger">
                  <p className="mb-0">{serverError}</p>
                </Alert>
              )}
              <FormGroup>
                <div className="custom-file">
                  <Input
                    accept="text/csv"
                    className="custom-file-input"
                    id="file"
                    invalid={!!error}
                    label={(file && file.name) || "Choose file"}
                    onChange={handleInput}
                    type="file"
                  />
                  <Label
                    className={classNames({
                      "custom-file-label": true,
                      placeholder: !file || !file.name,
                    })}
                    for="file"
                  >
                    {(file && file.name) || "Choose file"}
                  </Label>
                  {!!error && <FormFeedback>{error}</FormFeedback>}
                </div>
              </FormGroup>
              <div className="form-actions border-top-0">
                <Button color="success" disabled={inProgress} type="submit">
                  {inProgress ? (
                    <Fragment>
                      Uploading <Spinner className="ml-2" size="sm" />
                    </Fragment>
                  ) : (
                    "Upload"
                  )}
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

UsersUpload.propTypes = {
  onSuccess: func.isRequired,
  setTitle: func.isRequired,
};

export default UsersUpload;
