import { useCallback } from "react";
import {
  element,
  elementType,
  func,
  node,
  oneOfType,
  string,
} from "prop-types";

import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";

import Error from "../../error";

function AppError({ children }) {
  const getErrorProps = useCallback((error, resetError) => {
    let { name = "Error", message = "Unknown error" } = error || {};

    return { message, name, resetError };
  }, []);

  return (
    <SentryErrorBoundary
      fallback={({ error, resetError }) => (
        <Error {...getErrorProps(error, resetError)} />
      )}
    >
      {children}
    </SentryErrorBoundary>
  );
}

AppError.propTypes = {
  children: node.isRequired,
  fallback: oneOfType([element, elementType, node, func, string]),
  location: string,
};

export default AppError;
