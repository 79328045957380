import React from "react";
import { bool } from "prop-types";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import * as Sentry from "@sentry/react";

import {
  HOST_ENV,
  SITE_DEFAULT,
  SENTRY_DSN,
  SITE_VERSION,
} from "../../constants";
import { history } from "../../helpers";

import DataUploads from "../data-uploads";
import Dashboard from "../dashboard";
import Error from "../error";
import Listing from "../listing";
import Login from "../login";
import Listings from "../listings";
import Photos from "../photos";
import Users from "../users";
import { PrivateRoute } from "./components";

import "../../assets/scss/styles.scss";

if (process.env.NODE_ENV !== "development" && !!SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: HOST_ENV,
    release: `holt.used.admin-${SITE_VERSION}`,
    integrations: [new Sentry.BrowserTracing()], // TO-DO: integrate w/ react-router after v6 upgrade
    tracesSampleRate: 0.1,
    beforeSend(event, hint) {
      const { name } = hint?.originalException || {};

      // Don't send these errors to Sentry
      if (/AbortError|ChunkLoadError/gi.test(name)) {
        return null;
      }

      if (event.exception) {
        Sentry.showReportDialog({ eventId: event.event_id });
      }

      return event;
    },
    // Not the most elegant solution, but think this error is thrown
    // by sentry/tracing and it's consuming our Sentry error quota
    ignoreErrors: ["Cannot read properties of undefined (reading 'prototype')"],
  });
}

/**
 * Wraps the application with React Router,
 * connects React Router to the shared browser history,
 * and imports the Bootstrap theme styles.
 * * Each route returns a component or redirect
 * * Public routes use React Routers default Route component
 * * Protected routes use the custom PrivateRoute component
 *   to check the user's authentication
 * * Falls back to the Error component for unknown routes
 * @param {object} props
 */
const App = props => (
  <Router history={history}>
    <Switch>
      <Route exact path="/" render={() => <Redirect to={SITE_DEFAULT} />} />
      <Route exact path="/login" component={Login} />
      <PrivateRoute
        {...props}
        exact
        path="/listings/:sourceType?/:status?/:filter?"
        component={Listings}
      />
      <PrivateRoute
        {...props}
        exact
        path="/listing/photos/:equipmentId"
        component={Photos}
      />
      <PrivateRoute
        {...props}
        exact
        path="/listing/:equipmentId"
        component={Listing}
      />
      {props.isAdmin && (
        <PrivateRoute
          {...props}
          exact
          path={["/users", "/users/add", "/users/upload", "/users/:id"]}
          component={Users}
        />
      )}
      <PrivateRoute
        {...props}
        exact
        path="/system-dashboard"
        component={Dashboard}
      />
      <PrivateRoute
        {...props}
        exact
        path="/data-uploads/:type?"
        component={DataUploads}
      />
      <PrivateRoute {...props} component={Error} />
    </Switch>
  </Router>
);

App.propTypes = {
  isAdmin: bool,
};

App.defaultProps = {
  isAdmin: false,
};

export default App;
