import React, { Fragment, PureComponent } from "react";
import { number, string } from "prop-types";

import { Button } from "reactstrap";

/**
 * Button for copying text to clipboard
 */
class ListingCopy extends PureComponent {
  constructor(props) {
    super(props);

    this.input = React.createRef();

    this.copy = this.copy.bind(this);

    this.state = {
      hasCopied: false,
    };
  }

  /**
   * Copy to clipboard
   * @param {event} e
   * @public
   */
  copy(e) {
    e.preventDefault();

    const { input } = this;
    const { delay } = this.props;

    input.current.select();
    document.execCommand("copy");
    e.target.focus();

    this.setState({ hasCopied: true }, () => {
      setTimeout(() => {
        this.setState({ hasCopied: false });
      }, delay);
    });
  }

  render() {
    if (!document.queryCommandSupported("copy")) {
      return null;
    }

    const { copy, input } = this;
    const { color, confirmation, size, text, value } = this.props;
    const { hasCopied } = this.state;

    return (
      <Fragment>
        <Button
          className="text-nowrap"
          color={color}
          disabled={!!hasCopied}
          onClick={copy}
          size={size}
          type="button"
        >
          {hasCopied ? confirmation : text}
        </Button>
        <textarea
          className="sr-only"
          defaultValue={value}
          readOnly
          ref={input}
        />
      </Fragment>
    );
  }
}

ListingCopy.propTypes = {
  /** Button color (Bootstrap theme) */
  color: string,
  /** Confirmation text */
  confirmation: string,
  /** Delay before confirmation text is reset */
  delay: number,
  /** Button size */
  size: string,
  /** Text that appears in the button */
  text: string,
  /** Text that will be copied to the clipboard */
  value: string.isRequired,
};

ListingCopy.defaultProps = {
  color: "success",
  confirmation: "Copied!",
  delay: 1000,
  size: null,
  text: "Copy",
};

export default ListingCopy;
