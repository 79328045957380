import { api, getApiErrorMessage } from "../../helpers";

import { URL_API_MAKES_MODELS, URL_API_SOURCES } from "../../constants";

/**
 * Action types
 * @property {string} MAKES_ERROR
 * @property {string} MAKES_UPDATED
 * @property {string} SOURCES_ERROR
 * @property {string} SOURCES_UPDATED
 */
export const types = {
  MAKES_ERROR: "MAKES_ERROR",
  MAKES_UPDATED: "MAKES_UPDATED",
  SOURCES_ERROR: "SOURCES_ERROR",
  SOURCES_UPDATED: "SOURCES_UPDATED",
};

/**
 * @namespace Reducers
 */
export const reducers = {
  /**
   * Updates makes state
   * @param {object} state
   * @param {object} action
   * @param {string} action.type - `MAKES_UPDATED`, or no change
   * @param {array} action.makes
   * @memberof Reducers
   */
  makes: (state = [], action) => {
    switch (action.type) {
      case types.MAKES_UPDATED:
        return action.makes;
      default:
        return state;
    }
  },

  /**
   * Update makes error state
   * @param {string} state
   * @param {object} action
   * @param {string} action.type
   * @param {string} action.error
   * @memberof Reducers
   */
  makesError: (state = null, action) => {
    switch (action.type) {
      case types.MAKES_ERROR:
        return action.error;
      case types.MAKES_UPDATED:
        return null;
      default:
        return state;
    }
  },

  /**
   * Updates sources state
   * @param {object} state
   * @param {object} action
   * @param {string} action.type - `SOURCES_UPDATED`, or no change
   * @param {array} action.makes
   * @memberof Reducers
   */
  sources: (state = [], action) => {
    switch (action.type) {
      case types.SOURCES_UPDATED:
        return action.sources;
      default:
        return state;
    }
  },

  /**
   * Update sources error state
   * @param {string} state
   * @param {object} action
   * @param {string} action.type
   * @param {string} action.error
   * @memberof Reducers
   */
  sourcesError: (state = null, action) => {
    switch (action.type) {
      case types.SOURCES_ERROR:
        return action.error;
      case types.SOURCES_UPDATED:
        return null;
      default:
        return state;
    }
  },
};

/**
 * @namespace Actions
 */
export const actions = {
  /**
   * Fetch makes from API
   * @memberof Actions
   */
  fetchMakes: () => dispatch => {
    return api
      .get(URL_API_MAKES_MODELS)
      .then(response => {
        if (
          response &&
          response.data &&
          response.data.data &&
          Array.isArray(response.data.data)
        ) {
          dispatch(actions.updateMakes(response.data.data));
        } else {
          const message = getApiErrorMessage(response);
          dispatch(actions.handleMakesError(message));
        }
      })
      .catch(error => {
        const message = getApiErrorMessage(error);
        dispatch(actions.handleMakesError(message));
      });
  },

  /**
   * Handle makes error
   * @param {string} error
   * @memberof Actions
   */
  handleMakesError: message => ({
    type: types.MAKES_ERROR,
    error: message,
  }),

  /**
   * Update makes
   * @param {array} makes
   * @memberof Actions
   */
  updateMakes: makes => ({
    type: types.MAKES_UPDATED,
    makes: makes,
  }),

  /**
   * Fetch sources from API
   * @memberof Actions
   */
  fetchSources: () => dispatch => {
    return api
      .get(URL_API_SOURCES)
      .then(response => {
        if (
          response &&
          response.data &&
          response.data.data &&
          Array.isArray(response.data.data)
        ) {
          dispatch(actions.updateSources(response.data.data));
        } else {
          const message = getApiErrorMessage(response);
          dispatch(actions.handleSourcesError(message));
        }
      })
      .catch(error => {
        const message = getApiErrorMessage(error);
        dispatch(actions.handleSourcesError(message));
      });
  },

  /**
   * Handle sources error
   * @param {string} error
   * @memberof Actions
   */
  handleSourcesError: message => ({
    type: types.SOURCES_ERROR,
    error: message,
  }),

  /**
   * Update sources
   * @param {array} makes
   * @memberof Actions
   */
  updateSources: sources => ({
    type: types.SOURCES_UPDATED,
    sources: sources,
  }),
};
