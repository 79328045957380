import { connect } from "react-redux";

import { actions as userActions } from "./Users.redux";
import Users from "./Users.view";

const mapStateToProps = state => ({
  user: state.auth.user,
  users: state.users,
  usersError: state.usersError,
  usersLoading: state.usersLoading,
});

const mapDispatchToProps = dispatch => ({
  fetchUsers: () => dispatch(userActions.fetch()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
