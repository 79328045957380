import React, { Fragment, PureComponent } from "react";
import { bool, func, string } from "prop-types";

import {
  Alert,
  Button,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card,
  CardHeader,
  CardBody,
  Spinner,
} from "reactstrap";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

class DashboardExport extends PureComponent {
  constructor(props) {
    super(props);

    this.toggleFormData = this.toggleFormData.bind(this);

    this.state = {
      formDataOpen: false,
    };
  }

  /**
   * Toggle form data dropdown
   * @public
   */
  toggleFormData() {
    this.setState(prevState => ({
      formDataOpen: !prevState.formDataOpen,
    }));
  }

  render() {
    const {
      daily,
      dailyError,
      dailyLoading,
      formData,
      formDataError,
      formDataLoading,
      historical,
      historicalError,
      historicalLoading,
      search,
      searchError,
      searchLoading,
    } = this.props;

    const { toggleFormData } = this;
    const { formDataOpen } = this.state;

    return (
      <Card>
        <CardHeader className="border-bottom-0 font-weight-bold h4 py-3_25">
          Export Data
          <strong className="ml-2_5 smaller text-muted">CSV Files</strong>
        </CardHeader>
        <CardBody className="px-lg-5_5 py-4_25 text-center">
          {(!!dailyError ||
            !!formDataError ||
            !!historicalError ||
            !!searchError) && (
            <Alert className="p-4 text-center" color="danger">
              <p className="mb-0">
                {dailyError || formDataError || historicalError || searchError}
              </p>
            </Alert>
          )}
          <p className="mb-3_5">
            <Button
              block
              className="font-weight-bold px-lg-3 py-2_5 text-uppercase"
              color="info"
              disabled={!!historicalLoading}
              onClick={() => daily()}
              size="sm"
            >
              {!!dailyLoading ? (
                <Fragment>
                  Downloading <Spinner className="ml-2" size="sm" />
                </Fragment>
              ) : (
                <Fragment>
                  <Icon className="mr-1_5" icon={faDownload} />
                  Daily Listing Data
                </Fragment>
              )}
            </Button>
          </p>
          <p className="mb-3_5">
            <ButtonDropdown
              className="w-100"
              disabled={!!formDataLoading}
              isOpen={formDataOpen}
              toggle={toggleFormData}
            >
              <DropdownToggle
                caret
                className="font-weight-bold px-lg-3 py-2_5 text-uppercase"
                color="secondary"
                size="sm"
              >
                {!!formDataLoading ? (
                  <Fragment>
                    Downloading <Spinner className="ml-2" size="sm" />
                  </Fragment>
                ) : (
                  <Fragment>
                    <Icon className="mr-1_5" icon={faDownload} />
                    Form Data
                  </Fragment>
                )}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem header>Range</DropdownItem>
                <DropdownItem onClick={() => formData(30)}>
                  30 days
                </DropdownItem>
                <DropdownItem onClick={() => formData(60)}>
                  60 days
                </DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
          </p>
          <p className="mb-3_5">
            <Button
              block
              className="font-weight-bold px-lg-3 py-2_5 text-uppercase"
              color="success"
              disabled={!!historicalLoading}
              onClick={() => historical()}
              size="sm"
            >
              {!!historicalLoading ? (
                <Fragment>
                  Downloading <Spinner className="ml-2" size="sm" />
                </Fragment>
              ) : (
                <Fragment>
                  <Icon className="mr-1_5" icon={faDownload} />
                  Historical Listing Data
                </Fragment>
              )}
            </Button>
          </p>
          <p>
            <Button
              block
              color="primary"
              className="font-weight-bold px-lg-3 py-2_5 text-uppercase"
              disabled={!!searchLoading}
              onClick={() => search()}
              size="sm"
            >
              {!!searchLoading ? (
                <Fragment>
                  Downloading <Spinner className="ml-2" size="sm" />
                </Fragment>
              ) : (
                <Fragment>
                  <Icon className="mr-1_5" icon={faDownload} />
                  Search Parameters Data
                </Fragment>
              )}
            </Button>
          </p>
        </CardBody>
      </Card>
    );
  }
}

DashboardExport.propTypes = {
  daily: func.isRequired,
  dailyError: string,
  dailyLoading: bool,
  formData: func.isRequired,
  formDataError: string,
  formDataLoading: bool,
  historical: func.isRequired,
  historicalError: string,
  historicalLoading: bool,
  search: func.isRequired,
  searchError: string,
  searchLoading: bool,
};

export default DashboardExport;
