import React, { PureComponent } from "react";
import { array, bool, string } from "prop-types";

import { Alert, Card, CardHeader, CardBody, Spinner, Table } from "reactstrap";

class DashboardLogins extends PureComponent {
  render() {
    const { error, loading, logins } = this.props;

    return (
      <Card>
        <CardHeader className="border-bottom-0 font-weight-bold h4 py-3_25">
          Login Activity
          <strong className="ml-2_5 smaller text-muted">
            For the Past 7 Days
          </strong>
        </CardHeader>
        <CardBody className="py-4_25">
          {loading && (
            <Alert className="p-4" color="info">
              <div className="align-items-center d-flex justify-content-center">
                <Spinner />
                <p className="mb-0 ml-3 pr-5">
                  <strong>Loading…</strong>
                </p>
              </div>
            </Alert>
          )}
          {!!error && (
            <Alert className="p-4 text-center" color="danger">
              <p className="mb-0">{error}</p>
            </Alert>
          )}
          {!error && !loading && !!logins && (
            <Table className="dashboard-table" responsive>
              <thead>
                <tr>
                  <th className="w-75">Listing Admin</th>
                  <th className="text-center"># of Logins</th>
                </tr>
              </thead>
              <tbody>
                {logins.map((login, index) => (
                  <tr key={index}>
                    <td>
                      <strong>
                        {!!login.first_name && !!login.last_name
                          ? `${login.first_name} ${login.last_name}`
                          : login.name}
                      </strong>
                    </td>
                    <td className="text-center">
                      <strong>
                        {!!login.login_count || login.login_count === 0
                          ? login.login_count
                          : "–"}
                      </strong>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </CardBody>
      </Card>
    );
  }
}

DashboardLogins.propTypes = {
  error: string,
  loading: bool,
  logins: array,
};

export default DashboardLogins;
