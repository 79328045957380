import React, { Component } from "react";
import {
  array,
  arrayOf,
  func,
  number,
  oneOfType,
  shape,
  string,
  bool,
} from "prop-types";
import classNames from "classnames";

import {
  Badge,
  Button,
  CustomInput,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";

import ListingAttachments from "./Listing.attachments";
// import ListingCertification from "./Listing.certification";
import ListingEditor from "./Listing.editor";
import ListingSelect from "./Listing.select";
import SwitchGroup from "./SwitchGroup";

import { SITE_DEFAULT } from "../../../constants";
import { getText, formatNumber, history } from "../../../helpers";

const getFloat = value =>
  !!value ? parseFloat(value.toString().replace(/[$,]/g, "")) : value;

/**
 * Listing Form
 */
class ListingForm extends Component {
  constructor(props) {
    super(props);

    this.goBack = this.goBack.bind(this);
    this.handleForceStatusSold = this.handleForceStatusSold.bind(this);
    this.handleIsDraft = this.handleIsDraft.bind(this);
    this.handleIsInternal = this.handleIsInternal.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleReactSelect = this.handleReactSelect.bind(this);
    this.requestUpdate = this.requestUpdate.bind(this);

    this._isMounted = false;

    const { listing } = props;

    this.state = {
      adminNotes: listing.adminNotes,
      category_id: listing.category_id || "",
      description: listing.description,
      features: listing.features,
      forceStatusSold: !!listing.forceStatusSold,
      hasChanges: false,
      isDraft: listing.publishStatus === "unpublished",
      isInternal: parseInt(listing.isInternal, 10) === 1,
      makeOverride: listing.makeOverride || "",
      overridePrice: !!listing.overridePrice
        ? formatNumber(listing.overridePrice)
        : listing.overridePrice,
      overridePriceError: null,
      saveError: null,
    };
  }

  /**
   * Go back to previous page, if known,
   * or default page, if not known
   * @param {event|object} e
   * @public
   */
  goBack(e) {
    const destination =
      history.location.state && history.location.state.goBack
        ? history.location.state && history.location.state.goBack
        : SITE_DEFAULT;
    const confirmation = e.confirmation ? e : null;
    history.push(destination, confirmation);
  }

  /**
   * Handle sold override checkbox
   * @public
   */
  handleForceStatusSold() {
    this.setState(({ forceStatusSold }) => ({
      forceStatusSold: !forceStatusSold,
    }));
  }

  /**
   * Handle form input
   * * Expects an event or psuedo-event object
   * * Assumes target.id corresponds to a state property
   * @param {event|object} e
   * @public
   */
  handleInput(e) {
    const { target } = e;
    let error = null;

    if (target && target.id) {
      if (!!target.validity) {
        if (target.validity.patternMismatch) {
          error = target.getAttribute("title") || "Invalid format";
        }
      }

      this.setState({
        [target.id]: target.value,
        [`${target.id}Error`]: error,
      });
    }
  }

  /**
   * Handle change to isDraft state
   * @param {bool} next
   * @public
   */
  handleIsDraft(next) {
    this.setState({ isDraft: !!next });
  }

  /**
   * Handle change to isInternal state
   * @param {bool} next
   * @public
   */
  handleIsInternal(next) {
    this.setState({ isInternal: !!next });
  }

  /**
   * Handle change to ReactSelect
   * @public
   */
  handleReactSelect(selected, action) {
    if (!!action && !!action.name) {
      this.setState({ [action.name]: !!selected ? selected.value : "" });
    }
  }

  /**
   * Request to update listing
   * @param {string} action
   * @public
   */
  requestUpdate(action) {
    const { handleErrors, listing, updateListing } = this.props;
    const { isDraft, isInternal, overridePriceError } = this.state;
    const changes = (({
      adminNotes,
      category_id,
      description,
      features,
      forceStatusSold,
      makeOverride,
      overridePrice,
    }) => ({
      adminNotes,
      category_id,
      description,
      features,
      forceStatusSold,
      makeOverride,
      overridePrice,
    }))(this.state);

    if (!!overridePriceError) {
      handleErrors(["Check form for errors"]);
      return false;
    }

    if (isDraft) {
      changes.publishStatus = "unpublished";
    } else if (listing.originalValues.publishStatus !== "unpublished") {
      changes.publishStatus = listing.originalValues.publishStatus;
    } else {
      changes.publishStatus = "published";
    }

    if (!!changes.overridePrice) {
      changes.overridePrice = getFloat(changes.overridePrice);
    }

    changes.isInternal = isInternal ? 1 : 0;

    updateListing(action, changes)
      .then(response => {
        if (this._isMounted) {
          switch (action) {
            case "saveGoToPhotos":
              history.push({
                pathname: `/listing/photos/${listing.equipmentId}`,
                state: history.location.state,
              });
              break;
            case "saveFromListing":
              this.goBack({
                confirmation: {
                  type: changes.forceStatusSold
                    ? "forceStatusSold"
                    : isDraft
                    ? "unpublished"
                    : "published",
                  slug: listing.slug,
                  isInternal: changes.isInternal,
                  equipmentId: listing.equipmentId,
                },
              });
              break;
            default:
              break;
          }
        }
      })
      .catch(error => {
        return false;
      });
  }

  componentDidMount() {
    this._isMounted = true;

    const {
      categoryOptions,
      fetchCategoryOptions,
      fetchMakes,
      listing,
      makes,
    } = this.props;

    if (!!listing && listing.allowMakeOverride && makes.length === 0) {
      fetchMakes();
    }

    if (!!listing && (!categoryOptions || categoryOptions.length === 0)) {
      fetchCategoryOptions();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { listing } = this.props;
    const { originalValues } = listing;
    const {
      adminNotes,
      category_id,
      description,
      features,
      isDraft,
      isInternal,
      makeOverride,
      overridePrice,
    } = this.state;
    const hasChanges =
      adminNotes !== originalValues.adminNotes ||
      category_id !== originalValues.category_id ||
      description !== originalValues.description ||
      features !== originalValues.features ||
      (isDraft && originalValues.publishStatus !== "unpublished") ||
      (!isDraft && originalValues.publishStatus === "unpublished") ||
      (isInternal && parseInt(originalValues.isInternal, 10) !== 1) ||
      (!isInternal && parseInt(originalValues.isInternal, 10) === 1) ||
      makeOverride !== originalValues.makeOverride ||
      getFloat(overridePrice) !== getFloat(originalValues.overridePrice);

    if (hasChanges !== prevState.hasChanges) {
      this.setState({ hasChanges: hasChanges });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      goBack,
      handleForceStatusSold,
      handleInput,
      handleIsDraft,
      handleIsInternal,
      handleReactSelect,
      requestUpdate,
    } = this;
    const {
      categoriesError,
      categoriesLoading,
      categoryOptions,
      colLeftSize,
      colRightSize,
      listing,
      listingAction,
      makes,
      makesError,
    } = this.props;
    const { originalValues } = listing;
    const {
      adminNotes,
      category_id,
      description,
      features,
      forceStatusSold,
      hasChanges,
      isDraft,
      isInternal,
      makeOverride,
      overridePrice,
      overridePriceError,
    } = this.state;

    return (
      <Form className="listing-form" onSubmit={e => e.preventDefault()}>
        <FormGroup className="bg-subtle mx-0 px-2 py-3" row>
          <Label
            className={`col-form-label col-lg-${colLeftSize}`}
            for="adminNotes"
          >
            {adminNotes !== originalValues.adminNotes && (
              <div className="text-uppercase">
                <Badge color="secondary">Unsaved</Badge>
              </div>
            )}
            Notes
          </Label>
          <div className={`col-lg-${colRightSize}`}>
            <ListingEditor
              handleInput={handleInput}
              id="adminNotes"
              value={adminNotes}
            />
          </div>
        </FormGroup>
        {listing.locationCity ||
        listing.locationState ||
        listing.locationZip ||
        listing.locationCountry ? (
          <FormGroup className="separated" row>
            <h6 className={`col-form-label col-lg-${colLeftSize}`}>Location</h6>
            <div className={`col-lg-${colRightSize}`}>
              <p className="form-control-plaintext h4">
                {listing.sourceDivision === "Texas First Rentals" &&
                  !!listing.locationAddress &&
                  `${listing.locationAddress}, `}
                {listing.locationCity}
                {listing.locationCity &&
                  listing.locationState &&
                  `, ${listing.locationState}`}{" "}
                {listing.locationZip}
                {listing.locationCountry && `, ${listing.locationCountry}`}
              </p>
            </div>
          </FormGroup>
        ) : null}
        {listing.allowMakeOverride && (
          <FormGroup className="separated" row>
            <Label
              className={`col-form-label col-lg-${colLeftSize}`}
              for="makeOverride"
            >
              {makeOverride !== originalValues.makeOverride && (
                <div className="text-uppercase">
                  <Badge color="secondary">Unsaved</Badge>
                </div>
              )}
              Manufacturer Override
            </Label>
            <div className={`col-lg-${colRightSize}`}>
              <CustomInput
                bsSize="lg"
                className={classNames({ placeholder: !makeOverride })}
                disabled={!!makesError || makes.length === 0}
                id="makeOverride"
                onChange={handleInput}
                type="select"
                value={makeOverride}
              >
                <option value="">- - Select Manufacturer Override - -</option>
                {makes.map(make => (
                  <option key={make.id} value={make.id}>
                    {make.name}
                  </option>
                ))}
              </CustomInput>
              {!!makesError && (
                <FormText className="position-absolute" color="danger">
                  {makesError}
                </FormText>
              )}
              {makes.length === 0 && !makesError && (
                <FormText className="font-italic position-absolute">
                  Loading…
                </FormText>
              )}
            </div>
          </FormGroup>
        )}
        <FormGroup className="separated" row>
          <h6 className={`col-form-label col-lg-${colLeftSize}`}>
            Digital Attachments
          </h6>
          <div className={`col-lg-${colRightSize}`}>
            <ListingAttachments
              attachments={listing.attachments}
              listingId={listing.id}
            />
          </div>
        </FormGroup>
        <FormGroup className="separated" row>
          <Label
            className={`col-form-label col-lg-${colLeftSize}`}
            for="category_id"
          >
            {category_id !== originalValues.category_id && (
              <div className="text-uppercase">
                <Badge color="secondary">Unsaved</Badge>
              </div>
            )}
            Category
          </Label>
          <div className={`col-lg-${colRightSize}`}>
            {!categoriesError &&
              !categoriesLoading &&
              !!categoryOptions.length && (
                <ListingSelect
                  id="category_id"
                  isClearable={!!category_id}
                  isSearchable={true}
                  name="category_id"
                  onChange={handleReactSelect}
                  options={categoryOptions}
                  placeholder="- - Select Category - -"
                  value={categoryOptions.filter(
                    ({ value }) => value === category_id
                  )}
                />
              )}
            {!!categoriesError && (
              <FormText className="position-absolute" color="danger">
                {categoriesError}
              </FormText>
            )}
            {categoriesLoading && !categoriesError && (
              <FormText className="font-italic position-absolute">
                Loading…
              </FormText>
            )}
          </div>
        </FormGroup>
        {/* {!!listing.certifiedUsed &&
        typeof listing.certifiedUsed !== "boolean" &&
        listing.certifiedUsed !== "none" ? (
          <FormGroup row>
            <h6 className={`col-form-label col-lg-${colLeftSize}`}>
              Certification(s)
            </h6>
            <div className={`col-lg-${colRightSize}`}>
              <ListingCertification status={listing.certifiedUsed} />
            </div>
          </FormGroup>
        ) : null} */}
        {listing.sourceDivision !== "Texas First Rentals" && (
          <FormGroup className="separated" row>
            <Label
              className={`col-form-label col-lg-${colLeftSize}`}
              for="description"
            >
              {getFloat(overridePrice) !==
                getFloat(originalValues.overridePrice) && (
                <div className="text-uppercase">
                  <Badge color="secondary">Unsaved</Badge>
                </div>
              )}
              Price Override
            </Label>
            <div className={`col-lg-${colRightSize}`}>
              <InputGroup size="lg">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>$</InputGroupText>
                </InputGroupAddon>
                <Input
                  id="overridePrice"
                  invalid={!!overridePriceError}
                  maxLength={200}
                  onChange={handleInput}
                  pattern="^(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{2})?$"
                  title="Enter a valid dollar amount (xx,xxx,xxx.xx)"
                  type="text"
                  value={overridePrice}
                />
                {!!overridePriceError && (
                  <FormFeedback>{overridePriceError}</FormFeedback>
                )}
              </InputGroup>
            </div>
          </FormGroup>
        )}
        <FormGroup className="separated" row>
          <Label
            className={`col-form-label col-lg-${colLeftSize}`}
            for="forceStatusSold"
          >
            {forceStatusSold !== originalValues.forceStatusSold && (
              <div className="text-uppercase">
                <Badge color="secondary">Unsaved</Badge>
              </div>
            )}
            Sold Override
          </Label>
          <div className={`col-lg-${colRightSize}`}>
            <CustomInput
              checked={forceStatusSold}
              disabled={listing.status === "sold"}
              id="forceStatusSold"
              label="This listing is sold. Remove from site immediately. Once saved,
              this listing will no longer be available in the Admin area."
              name="forceStatusSold"
              type="checkbox"
              value={forceStatusSold}
              onChange={handleForceStatusSold}
            />
          </div>
        </FormGroup>
        <FormGroup row>
          <Label
            className={`col-form-label col-lg-${colLeftSize}`}
            for="description"
          >
            {description !== originalValues.description &&
              getText(description) !== getText(originalValues.description) && (
                <div className="text-uppercase">
                  <Badge color="secondary">Unsaved</Badge>
                </div>
              )}
            Description
          </Label>
          <div className={`col-lg-${colRightSize}`}>
            <ListingEditor
              handleInput={handleInput}
              id="description"
              value={description}
            />
          </div>
        </FormGroup>
        <FormGroup row>
          <Label
            className={`col-form-label col-lg-${colLeftSize}`}
            for="features"
          >
            {features !== originalValues.features &&
              getText(features) !== getText(originalValues.features) && (
                <div className="text-uppercase">
                  <Badge color="secondary">Unsaved</Badge>
                </div>
              )}
            Features
          </Label>
          <div className={`col-lg-${colRightSize}`}>
            <ListingEditor
              handleInput={handleInput}
              id="features"
              value={features}
            />
          </div>
        </FormGroup>
        <div className="form-actions pt-4">
          <SwitchGroup
            checked={isInternal}
            handleChange={handleIsInternal}
            id="isInternal"
            offLabel="Public-Facing"
            onColor="brand"
            onLabel="Internal Only"
          />
          <SwitchGroup
            checked={isDraft}
            className="ml-lg-auto"
            handleChange={handleIsDraft}
            id="isDraft"
            offLabel="Live"
            onLabel="Draft"
          />
        </div>
        <div className="form-actions">
          {hasChanges ? (
            <Button
              color="primary"
              disabled={!!listingAction}
              onClick={() => requestUpdate("saveGoToPhotos")}
              size="sm"
              type="button"
            >
              {listingAction === "saveGoToPhotos" ? (
                <span>
                  Saving <Spinner className="ml-2" size="sm" />
                </span>
              ) : (
                "Save + Select Photos"
              )}
            </Button>
          ) : (
            <Button
              color="primary"
              disabled={!!listingAction}
              size="sm"
              tag={Link}
              to={{
                pathname: `/listing/photos/${listing.equipmentId}`,
                state: history.location.state,
              }}
            >
              Select Photos
            </Button>
          )}
          <Button
            className="ml-lg-auto"
            color="danger"
            disabled={!!listingAction}
            onClick={goBack}
            size="sm"
            type="button"
          >
            Cancel
          </Button>
          <Button
            color="success"
            disabled={!!listingAction}
            onClick={() => requestUpdate("saveFromListing")}
            size="sm"
            type="button"
          >
            {listingAction === "saveFromListing" ? (
              <span>
                Saving <Spinner className="ml-2" size="sm" />
              </span>
            ) : (
              "Save"
            )}
          </Button>
        </div>
      </Form>
    );
  }
}

ListingForm.propTypes = {
  categoriesError: string,
  categoriesLoading: bool,
  categoryOptions: arrayOf(
    shape({
      label: string,
      value: oneOfType([number, string]),
    })
  ),
  colLeftSize: number,
  colRightSize: number,
  fetchMakes: func,
  handleErrors: func.isRequired,
  listing: shape({
    adminNotes: string,
    attachments: array,
    category_id: oneOfType([number, string]),
    description: string,
    features: string,
    forceStatusSold: bool,
    locationCity: string,
    locationCountry: string,
    locationState: string,
    locationZip: oneOfType([number, string]),
    publishStatus: string,
    status: string,
    updated_at: string,
  }).isRequired,
  listingAction: string,
  makes: array,
  makesError: string,
  updateListing: func.isRequired,
};

ListingForm.defaultProps = {
  colLeftSize: 3,
  colRightSize: 9,
};

export default ListingForm;
