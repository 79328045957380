import React, { Component } from "react";
import { arrayOf, object, shape, string } from "prop-types";
import qs from "query-string";
import {
  Redirect,
  Route,
  Switch,
  NavLink as RouterNavLink,
} from "react-router-dom";

import { Alert, Badge, Container, Nav, NavLink, Spinner } from "reactstrap";

import Filter from "../filter";
import Layout from "../layout";
import { ListingConfirmation } from "../listing";
import PaginationNav from "../pagination-nav";
import { ListingsDetail } from "./components";

import { URL_API_LISTINGS } from "../../constants";
import {
  api,
  CancelToken,
  getApiErrorMessage,
  formatNumber,
  normalizeListingsData,
} from "../../helpers";

/**
 * Displays listings
 */
class Listings extends Component {
  constructor(props) {
    super(props);

    this.fetchListings = this.fetchListings.bind(this);
    this.getLinkSearch = this.getLinkSearch.bind(this);

    const { filters, match, sourceTypes, statuses } = props;

    this._isMounted = false;
    this.apiCancel = () => false;
    this.defaultTitle = "Listings";
    this.updateFiltersForType = this.updateFiltersForType.bind(this);

    this.state = {
      counts: {},
      filtersForType: [],
      heading: this.defaultTitle,
      headingSourceType: null,
      isLoading: true,
      linkSearch: null,
      listings: [],
      listingsMessage: null,
      pageCurrent: null,
      pageLast: null,
      routeBase: match && match.path && `/${match.path.split("/")[1]}`,
      routeFilters: filters.map(filter => filter.param),
      routeSourceTypes: sourceTypes.map(sourceType => sourceType.param),
      routeStatuses: statuses.map(status => status.param),
      title: this.defaultTitle,
    };
  }

  /**
   * Clear listings state and update error message, if needed
   * @param {string} errorMessage
   * @public
   */
  clearListings(errorMessage) {
    if (this._isMounted) {
      this.setState({
        listings: [],
        listingsMessage: errorMessage || "Error",
        pageCurrent: null,
        pageLast: null,
      });
    }
  }

  /**
   * Retrieve listings from API if
   * filter parameter and page props have changed
   * @param {string} prevParams
   * @param {number} prevSearch
   * @public
   */
  fetchListings(prevParams, prevSearch) {
    const { defaultTitle } = this;
    const { filters, location, match, sourceTypes, statuses } = this.props;
    const { isLoading } = this.state;
    const sourceTypeParam = match.params.sourceType;
    const statusParam = match.params.status;
    const filterParam = match.params.filter;
    const search = location.search;
    prevParams = prevParams || {};

    if (!sourceTypeParam || !statusParam || !filterParam) {
      return false;
    } else if (
      prevParams.sourceType === sourceTypeParam &&
      prevParams.status === statusParam &&
      prevParams.filter === filterParam &&
      prevSearch === search
    ) {
      return false;
    } else if (
      !!prevParams.sourceType &&
      !!prevParams.status &&
      !!prevParams.filter &&
      isLoading
    ) {
      this.apiCancel("Canceled");
    }

    if (!isLoading) {
      this.setState({ isLoading: true });
    }

    let newQuery = "";
    const sourceType =
      Array.isArray(sourceTypes) &&
      sourceTypes.find(sourceType => sourceType.param === sourceTypeParam);
    const status =
      Array.isArray(statuses) &&
      statuses.find(status => status.param === statusParam);
    const filter =
      Array.isArray(filters) &&
      filters.find(filter => filter.param === filterParam);

    if (!!sourceType && !!status && !!filter) {
      const parsed = qs.parse(search);

      if (
        ["draft", "live"].indexOf(filterParam) > -1 &&
        !!parsed.publishStatus
      ) {
        delete parsed.publishStatus;
      }

      newQuery = qs.stringify(
        { ...sourceType.query, ...status.query, ...filter.query, ...parsed },
        { skipNull: true }
      );

      const {
        equipmentId,
        make,
        model,
        source,
        publishStatus,
        year,
        page,
      } = parsed;
      const titleMods = [];
      const searchFilters = [];

      if (equipmentId) {
        searchFilters.push(equipmentId);
      }

      if (make) {
        searchFilters.push(make);
      }

      if (model) {
        searchFilters.push(model);
      }

      if (source) {
        searchFilters.push(source);
      }

      if (publishStatus) {
        searchFilters.push(publishStatus);
      }

      if (year) {
        searchFilters.push(year);
      }

      if (searchFilters.length > 0) {
        titleMods.push(`Filtered by ${searchFilters.join(", ")}`);
      }

      if (page > 1) {
        titleMods.push(`Page ${page}`);
      }

      this.setState({
        heading: `${status.name} ${defaultTitle}`,
        headingSourceType: sourceType.name,
        title: `${filter.name}${
          titleMods.length > 0 ? " (" + titleMods.join("; ") + ")" : ""
        } - ${sourceType.name} - ${status.name} ${defaultTitle}`,
      });
    }

    api
      .get(`${URL_API_LISTINGS}?${newQuery}`, {
        cancelToken: new CancelToken(c => {
          this.apiCancel = c;
        }),
      })
      .then(response => {
        if (response && response.data && response.data.data) {
          const { data } = response.data;

          if (this._isMounted && data.counts) {
            this.setState({
              counts: data.counts,
            });
          }

          if (
            this._isMounted &&
            data.listings &&
            data.listings.data &&
            Array.isArray(data.listings.data) &&
            data.listings.data.length > 0
          ) {
            const listings = normalizeListingsData(data.listings.data);
            this.setState({
              listings: listings,
              listingsMessage: null,
              pageCurrent: data.listings.current_page,
              pageLast: data.listings.last_page,
            });
          } else {
            this.clearListings(getApiErrorMessage(response));
          }
        } else {
          this.clearListings(getApiErrorMessage(response));
        }
      })
      .catch(error => {
        const message = getApiErrorMessage(error);
        this.clearListings(message);
      })
      .then(() => {
        if (this._isMounted) {
          this.setState({
            isLoading: this.state.listingsMessage === "Canceled",
          });
        }
      });
  }

  /**
   * Get search parameters for navigation links
   * @public
   */
  getLinkSearch() {
    const { location } = this.props;
    const parsed = qs.parse(location.search);
    const { page, ...linkSearch } = parsed;
    this.setState({ linkSearch: qs.stringify(linkSearch) });
  }

  /**
   * Update filter navigation links for active source type
   * @public
   */
  updateFiltersForType() {
    const { filters } = this.props;
    const sourceType = this.props.match?.params?.sourceType;

    if (sourceType === "new") {
      this.setState({
        filtersForType: filters.filter(({ param }) =>
          ["draft", "live", "needs-photos"].includes(param)
        ),
      });
    } else {
      this.setState({ filtersForType: filters });
    }
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchListings();
    this.getLinkSearch();
    this.updateFiltersForType();
  }

  componentDidUpdate(prevProps) {
    const { match, location } = prevProps;

    this.fetchListings(match.params, location.search);

    if (location.search !== this.props.location.search) {
      this.getLinkSearch();
    }

    if (match?.params?.sourceType !== this.props.match?.params?.sourceType) {
      this.updateFiltersForType();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.apiCancel();
  }

  render() {
    const { location, match, statuses } = this.props;
    const {
      counts,
      filtersForType,
      heading,
      headingSourceType,
      isLoading,
      linkSearch,
      listings,
      listingsMessage,
      pageCurrent,
      pageLast,
      routeBase,
      routeFilters,
      routeStatuses,
      routeSourceTypes,
      title,
    } = this.state;

    const showAvailability =
      !!match &&
      !!match.params &&
      !!match.params.status &&
      match.params.status === statuses[0].param;

    return (
      <Layout title={title}>
        <Container tag="article">
          <header>
            <h1>
              {!!headingSourceType && (
                <span className="d-block h6 mt-n3 position-absolute text-muted">
                  {headingSourceType}
                </span>
              )}
              {heading}
            </h1>
            {location.state && location.state.confirmation && (
              <ListingConfirmation confirmation={location.state.confirmation} />
            )}
            <Filter type={match.params.filter || routeFilters[0]} />
          </header>
          <section>
            <Nav className="listings-nav d-block d-lg-flex" tag="nav">
              {filtersForType.map(filter => (
                <NavLink
                  activeClassName="active"
                  key={filter.param}
                  tag={RouterNavLink}
                  to={{
                    pathname: `${routeBase}/${
                      match.params.sourceType || routeSourceTypes[0]
                    }/${match.params.status || routeStatuses[0]}/${
                      filter.param
                    }`,
                    search: linkSearch,
                  }}
                >
                  <Badge color="listings-nav" pill>
                    {counts[filter.count]
                      ? formatNumber(counts[filter.count])
                      : 0}
                  </Badge>
                  <span>{filter.name}</span>
                </NavLink>
              ))}
            </Nav>
            <div className="layout-content">
              {isLoading && (
                <Alert className="p-4" color="info">
                  <div className="align-items-center d-flex justify-content-center">
                    <Spinner />
                    <p className="mb-0 ml-3 pr-5">
                      <strong>Loading…</strong>
                    </p>
                  </div>
                </Alert>
              )}
              {!isLoading && listingsMessage && (
                <Alert className="p-4" color="info">
                  <p className="mb-0 text-center">
                    <strong>{listingsMessage}</strong>
                  </p>
                </Alert>
              )}
              <Switch>
                <Route
                  exact
                  path={`${routeBase}/:sourceType(${routeSourceTypes.join(
                    "|"
                  )})/:status(${routeStatuses.join(
                    "|"
                  )})/:filter(${routeFilters.join("|")})`}
                  render={() =>
                    !isLoading && listings.length > 0
                      ? listings.map((listing, index) => (
                          <ListingsDetail
                            key={listing.equipmentId}
                            location={location}
                            listing={listing}
                            notLast={index + 1 < listings.length}
                            showAvailability={showAvailability}
                          />
                        ))
                      : null
                  }
                />
                <Route
                  exact
                  path={`${routeBase}/:sourceType(${routeSourceTypes.join(
                    "|"
                  )})/:status(${routeStatuses.join("|")})`}
                  render={() => (
                    <Redirect
                      to={{
                        pathname: `${routeBase}/${match.params.sourceType}/${match.params.status}/${routeFilters[0]}`,
                        state: { ...location.state },
                      }}
                    />
                  )}
                />
                <Route
                  render={() => (
                    <Redirect
                      to={{
                        pathname: `${routeBase}/${routeSourceTypes[0]}/${routeStatuses[0]}/${routeFilters[0]}`,
                        state: { ...location.state },
                      }}
                    />
                  )}
                />
              </Switch>
              {!isLoading && pageLast > 1 && (
                <PaginationNav pageCurrent={pageCurrent} pageLast={pageLast} />
              )}
            </div>
          </section>
        </Container>
      </Layout>
    );
  }
}

Listings.propTypes = {
  /** Navigation filters for listings */
  filters: arrayOf(
    shape({
      /** Key used to identify count for this filter */
      count: string.isRequired,
      /** Name displayed in navigation for this filter */
      name: string.isRequired,
      /** React Router param for loading this filter */
      param: string.isRequired,
      /** Query parameters needed to request filtered results from API */
      query: object.isRequired,
    })
  ),
  /** Location information from React Router */
  location: object.isRequired,
  /** Route match information from React Router */
  match: object.isRequired,
  /** Navigation statuses for listings */
  statuses: arrayOf(
    shape({
      /** Name displayed in navigation for this status */
      name: string.isRequired,
      /** React Router param for loading this status */
      param: string.isRequired,
      /** Query parameters needed to request results from API */
      query: object.isRequired,
    })
  ),
};

Listings.defaultProps = {
  filters: [
    {
      count: "draft",
      name: "Draft",
      param: "draft",
      query: { type: "draft" },
    },
    {
      count: "needsPhotos",
      name: "Needs Photos",
      param: "needs-photos",
      query: { type: "needsPhotos" },
    },
    // {
    //   count: "newReports",
    //   name: "New Reports",
    //   param: "new-reports",
    //   query: { type: "newReports" },
    // },
    {
      count: "updatedPhotos",
      name: "Updated Photos",
      param: "updated-photos",
      query: { type: "updatedPhotos" },
    },
    {
      count: "needsCategory",
      name: "Needs Category",
      param: "needs-category",
      query: { type: "needsCategory" },
    },
    {
      count: "live",
      name: "Live",
      param: "live",
      query: { type: "live" },
    },
  ],
  sourceTypes: [
    {
      name: "Used Equipment",
      param: "used",
      query: { sourceType: "used" },
    },
    {
      name: "New Equipment",
      param: "new",
      query: { sourceType: "new" },
    },
  ],
  statuses: [
    {
      name: "All",
      param: "all",
      query: { status: null },
    },
    {
      name: "Internal",
      param: "internal",
      query: { isInternal: "1" },
    },
    {
      name: "Public",
      param: "public",
      query: { isInternal: "0" },
    },
  ],
};

export default Listings;
