import { forwardRef, useMemo } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";

import { Button, InputGroup, InputGroupAddon, Input } from "reactstrap";

function ListingDatePicker({
  id,
  maxDate = new Date(),
  onChange,
  placeholderText = "Choose date",
  selectedDate,
}) {
  const parsedDate = useMemo(() => getParsedDate(selectedDate), [selectedDate]);

  function handleChange(date) {
    if (!!onChange) {
      if (!!date) {
        onChange(moment(date).format("YYYY-MM-DD"));
      } else {
        onChange("");
      }
    }
  }

  return (
    <DatePicker
      customInput={<DateInput />}
      id={id}
      maxDate={maxDate}
      onChange={handleChange}
      placeholderText={placeholderText}
      popperProps={{ style: { zIndex: 10 } }}
      selected={parsedDate}
      wrapperClassName="d-block"
    />
  );
}

const DateInput = forwardRef(({ value, onClick, ...rest }, ref) => {
  return (
    <InputGroup>
      <Input {...rest} innerRef={ref} onClick={onClick} value={value} />
      <InputGroupAddon addonType="append">
        <Button color="secondary" onClick={onClick}>
          <Icon icon={faCalendarAlt} />
        </Button>
      </InputGroupAddon>
    </InputGroup>
  );
});

function getParsedDate(dateString = "") {
  dateString = dateString.toString();
  const parsed = moment(dateString);
  return parsed.isValid() ? parsed.toDate() : null;
}

export default ListingDatePicker;
