import React, { PureComponent } from "react";
import { arrayOf, bool, func, number, shape, string } from "prop-types";
import classNames from "classnames";

import { CSSTransition, TransitionGroup } from "react-transition-group";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import PhotosImage from "./Photos.image";

import { FS_WATERMARK, FS_WATERMARK_SOLD } from "../../../constants";

class PhotosPreview extends PureComponent {
  constructor(props) {
    super(props);

    this.close = this.close.bind(this);
    this.getImagePath = this.getImagePath.bind(this);
    this.getImageSource = this.getImageSource.bind(this);
    this.show = this.show.bind(this);

    this.state = {
      active: 0,
      activeDirection: "next",
    };
  }

  /**
   * Close preview
   * @param {event} e
   * @public
   */
  close(e) {
    e.stopPropagation();
    this.props.toggle();
  }

  /**
   * Get image path
   * @param {string} handle
   * @param {number} height
   * @param {number} watermarkSize
   * @param {number} watermarkSoldSize
   * @public
   */
  getImagePath(handle, height, watermarkSize, watermarkSoldSize) {
    const { cdn, sold } = this.props;
    const resize = height ? `/resize=height:${height},fit:scale` : "";
    const watermark = !!FS_WATERMARK
      ? `/watermark=file:${FS_WATERMARK},size:${watermarkSize},position:[bottom,right]`
      : "";

    const watermarkSold =
      sold && !!FS_WATERMARK_SOLD
        ? `/watermark=file:${FS_WATERMARK_SOLD},size:${watermarkSoldSize},position:[middle,center]`
        : "";

    return `${cdn}${resize}${watermark}${watermarkSold}/cache=expiry:max/${handle}`;
  }

  /**
   * Get image source
   * @param {string} handle
   */
  getImageSource(handle) {
    const { getImagePath } = this;
    const { imgHeight, watermarkSize, watermarkSoldSize } = this.props;
    const small = getImagePath(
      handle,
      imgHeight,
      watermarkSize,
      watermarkSoldSize
    );
    const large = getImagePath(
      handle,
      imgHeight * 2,
      watermarkSize * 2,
      watermarkSoldSize * 2
    );

    return {
      src: small,
      srcSet: `${small} 1x, ${large} 2x`,
    };
  }

  /**
   * Show active photo
   * @param {number} index
   * @public
   */
  show(index) {
    const { active } = this.state;
    const direction = index < active ? "prev" : "next";

    this.setState({
      active: index,
      activeDirection: direction,
    });
  }

  componentDidUpdate(prevProps) {
    const { open, openClass } = this.props;

    if (prevProps.open !== open && open) {
      this.setState({ active: 0 });
      document.body.classList.add(openClass);
    } else {
      document.body.classList.remove(openClass);
    }
  }

  componentWillUnmount() {
    document.body.classList.remove(this.props.openClass);
  }

  render() {
    const { close, getImageSource, show } = this;
    const { cdn, open, photos } = this.props;
    const { active, activeDirection } = this.state;

    return !!cdn ? (
      <TransitionGroup>
        {open && (
          <CSSTransition key={open} timeout={500}>
            <div
              className="photos-preview-overlay"
              onClick={close}
              role="dialog"
              tabIndex="-1"
            >
              <div
                className="photos-preview"
                onClick={e => e.stopPropagation()}
                role="document"
              >
                {photos.length > 1 && (
                  <button
                    className="photos-preview-nav photos-preview-nav-prev"
                    disabled={active - 1 < 0}
                    onClick={e => {
                      e.stopPropagation();
                      show(active - 1);
                    }}
                    type="button"
                  >
                    <Icon icon={faChevronLeft} />
                    <span className="sr-only">Previous</span>
                  </button>
                )}
                <TransitionGroup
                  className={classNames({
                    "photos-preview-slider": true,
                    next: activeDirection === "next",
                    prev: activeDirection === "prev",
                  })}
                >
                  <CSSTransition
                    key={active}
                    timeout={{
                      enter: 0,
                      exit: 400,
                    }}
                  >
                    {photos[active] && photos[active].draftCropped && (
                      <div className="photos-preview-img">
                        <PhotosImage
                          alt="preview"
                          {...getImageSource(photos[active].draftCropped)}
                        />
                      </div>
                    )}
                  </CSSTransition>
                </TransitionGroup>
                {photos.length > 1 && (
                  <button
                    className="photos-preview-nav photos-preview-nav-next"
                    disabled={active + 1 >= photos.length}
                    onClick={e => {
                      e.stopPropagation();
                      show(active + 1);
                    }}
                    type="button"
                  >
                    <Icon icon={faChevronRight} />
                    <span className="sr-only">Next</span>
                  </button>
                )}
                <button
                  aria-label="close"
                  className="photos-preview-close"
                  type="button"
                  onClick={close}
                >
                  <Icon icon={faTimes} />
                </button>
              </div>
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    ) : null;
  }
}

PhotosPreview.propTypes = {
  cdn: string.isRequired,
  imgHeight: number,
  open: bool,
  openClass: string,
  photos: arrayOf(
    shape({
      draftCropped: string.isRequired,
    })
  ),
  sold: bool,
  toggle: func.isRequired,
  watermarkSize: number,
  watermarkSoldSize: number,
};

PhotosPreview.defaultProps = {
  imgHeight: 1280,
  open: false,
  openClass: "photos-preview-open",
  sold: false,
  watermarkSize: 30,
  watermarkSoldSize: 22,
};

export default PhotosPreview;
