import { useEffect, useRef, useState } from "react";
import { array, bool, func, string } from "prop-types";
import classNames from "classnames";
import _cloneDeep from "lodash/cloneDeep";

import { SITE_PHOTOS_GROUPS } from "../../../constants";

import { Badge, Nav, NavLink, TabContent, TabPane } from "reactstrap";

import PhotosLibrary from "./Photos.library";

export default function PhotosGroups({ cdn, crop, cropOpen, photos, select }) {
  const [activeTab, setActiveTab] = useState();
  const [photosGroups, setPhotosGroups] = useState([]);

  const counts = useRef();

  function handleTab(tab) {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  // Watching stringified photos array as an easy way to
  // deep compare array before triggering a re-render
  useEffect(() => {
    const grouped = groupPhotos(photos);
    setPhotosGroups(grouped);
  }, [JSON.stringify(photos)]); // eslint-disable-line react-hooks/exhaustive-deps

  // Update active tab
  useEffect(() => {
    let changed = false;

    // Automatically change active tab if the user's last action was to
    // upload a new photo or remove an existing photo from the gallery
    const newCounts = photosGroups.reduce(function watchPhotosGroupCounts(
      cts,
      curr
    ) {
      cts[curr.id] = (!!curr.photos && curr.photos.length) || 0;

      if (
        !!counts.current &&
        !!Object.keys(counts.current).length &&
        !!cts[curr.id] &&
        (!counts.current[curr.id] ||
          (!!counts.current[curr.id] &&
            counts.current[curr.id] !== cts[curr.id]))
      ) {
        changed = true;
        setActiveTab(curr.id);
      }
      return cts;
    },
    {});

    counts.current = newCounts;

    // Otherwise set the active tab to the default group if there's either
    // no active tab or the currently active tab has no photos
    if (!changed && !activeTab && !!photosGroups.length) {
      getDefaultGroup();
    } else if (!changed && !!activeTab && !!photosGroups.length) {
      const activeGroup = photosGroups.find(group => group.id === activeTab);

      if (
        !activeGroup ||
        !activeGroup.photos ||
        (!!activeGroup.photos && !activeGroup.photos.length)
      ) {
        getDefaultGroup();
      }
    }

    function getDefaultGroup() {
      const hasPhotos = photosGroups.find(
        group => !!group.photos && !!group.photos.length
      );
      setActiveTab((hasPhotos && hasPhotos.id) || "other");
    }
  }, [activeTab, photosGroups]);

  return !photosGroups.length ? null : (
    <>
      <Nav className="listings-nav border-0 d-block d-lg-flex">
        {photosGroups.map(group => (
          <NavLink
            className={classNames({ active: activeTab === group.id })}
            disabled={!group?.photos?.length}
            key={`tab-nav-${group.id}`}
            onClick={() => handleTab(group.id)}
            tag="button"
            type="button"
          >
            <Badge color="listings-nav" pill>
              {(!!group.photos && group.photos.length) || 0}
            </Badge>
            <span>{group.label}</span>
          </NavLink>
        ))}
      </Nav>
      <TabContent activeTab={activeTab}>
        {photosGroups.map(group =>
          !!group.photos && group.photos.length ? (
            <TabPane key={`tab-content-${group.id}`} tabId={group.id}>
              <PhotosLibrary
                cdn={cdn}
                crop={crop}
                cropOpen={cropOpen}
                photos={group.photos.map((photo, index) => {
                  photo.groupIndex = index;
                  return photo;
                })}
                select={select}
              />
            </TabPane>
          ) : null
        )}
      </TabContent>
    </>
  );
}

function groupPhotos(photos) {
  if (!photos || !Array.isArray(photos)) {
    return [];
  }

  const grouped = photos.reduce(function groupPhotos(groups, current) {
    const group = current.taskName || "other";

    if (!groups[group]) {
      groups[group] = [];
    }

    groups[group].push(current);

    return groups;
  }, {});

  let found = [];
  let photosGroups = _cloneDeep(SITE_PHOTOS_GROUPS);

  photosGroups.map(photosGroup => {
    if (!!grouped[photosGroup.type]) {
      found.push(photosGroup.type);
      photosGroup.photos = grouped[photosGroup.type];
    } else {
      photosGroup.photos = [];
    }

    return photosGroup;
  });

  for (const [group, photos] of Object.entries(grouped)) {
    if (!found.includes(group)) {
      photosGroups.push({ id: group, label: group, photos, type: group });
    }
  }

  return photosGroups;
}

PhotosGroups.propTypes = {
  cdn: string.isRequired,
  crop: func.isRequired,
  cropOpen: bool,
  photos: array.isRequired,
  select: func.isRequired,
};
