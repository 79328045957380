import React, { useEffect, useMemo, useRef, useState, Fragment } from "react";
import { arrayOf, number, oneOfType, shape, string } from "prop-types";
import classNames from "classnames";
import moment from "moment";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";

import {
  REQUEST_MAX_SIZE,
  URL_API_ATTACHMENTS,
  URL_ASSETS,
} from "../../../constants";
import {
  api,
  CancelToken,
  getApiErrorMessage,
  getFileSize,
} from "../../../helpers";

import {
  Alert,
  Button,
  Col,
  CustomInput,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Spinner,
} from "reactstrap";

import ListingDatePicker from "./Listing.datepicker";

const attachmentOptions = [
  { label: "Choose a name for this file", value: "" },
  { label: "Brochure", value: "brochure" },
  { label: "Build Sheet", value: "buildSheet" },
  { label: "Inspection", value: "inspectionReport" },
  { label: "Product Status", value: "productStatus" },
  { label: "Service History", value: "serviceHistory" },
  { label: "Supporting Information", value: "supportingInformation" },
  { label: "TA1", value: "ta1" },
  { label: "Technical Data", value: "technicalData" },
  { label: "Undercarriage", value: "undercarriageAssessment" },
];

const disclaimer =
  "Your action will be applied immediately, whether you save the listing or not.";

const formatAttachmentName = name => {
  if (!!name) {
    const found = attachmentOptions.find(option => option.value === name);
    return (!!found && found.label) || name;
  } else {
    return "";
  }
};

const formatAttachmentReportDate = (dateString = "") => {
  if (!dateString) {
    return "";
  }

  dateString = dateString.toString();
  const parsed = moment(dateString);
  return parsed.isValid() ? parsed.format("MMMM D, YYYY") : "";
};

const ModalAdd = ({ listingId, onSuccess, open, toggle }) => {
  const [isPublic, setIsPublic] = useState(false);
  const [error, setError] = useState(null);
  const [file, setFile] = useState("");
  const [inProgress, setInProgress] = useState(false);
  const [name, setName] = useState("");
  const [reportDate, setReportDate] = useState("");

  const cancel = useRef();
  const isMounted = useRef(true);

  const handleError = error => {
    if (isMounted.current) {
      setError(getApiErrorMessage(error));
      setInProgress(false);
    }
  };

  const handleFile = event => {
    if (!event || !event.target || !event.target.files) {
      setFile("");
      return false;
    }

    const files = Array.from(event.target.files);

    if (!files.length) {
      setFile("");
      return false;
    }

    if (files[0].type !== "application/pdf") {
      setError("File must be a PDF");
      setFile("");
      return false;
    }

    if (files[0].size > REQUEST_MAX_SIZE) {
      setError(`File cannot be larger than ${getFileSize(REQUEST_MAX_SIZE)}`);
      return false;
    }

    setFile(files[0]);
  };

  const handleName = event =>
    setName((!!event && !!event.target && event.target.value) || "");

  const handleSubmit = event => {
    event.preventDefault();

    if (!file) {
      setError("Please select a file");
      return false;
    }

    if (!name) {
      setError("Please select a name");
      return false;
    }

    if (!listingId && parseInt(listingId, 10) !== 0) {
      setError("Missing Listing ID");
      return false;
    }

    if (!onSuccess) {
      setError("Missing Callback");
      return false;
    }

    setInProgress(true);

    const formData = new FormData();
    formData.append("name", name);
    formData.append("file", file);
    formData.append("listingId", listingId);
    formData.append("isPublic", isPublic);
    formData.append("reportDate", reportDate);

    api
      .post(URL_API_ATTACHMENTS, formData, {
        cancelToken: new CancelToken(c => {
          cancel.current = c;
        }),
      })
      .then(response => {
        if (
          !!response &&
          !!response.data &&
          response.data.success &&
          !!response.data.data &&
          !!response.data.data.attachments &&
          !!response.data.data.attachments.length
        ) {
          if (isMounted.current) {
            onSuccess(response.data.data.attachments[0]);
            toggle();
            setInProgress(false);
            setError(null);
          }
        } else {
          handleError(response);
        }
      })
      .catch(handleError);
  };

  useEffect(() => {
    isMounted.current = true;

    return () => {
      if (!!cancel.current) {
        cancel.current();
      }

      isMounted.current = false;
    };
  }, []);

  return (
    <Modal
      backdrop={!inProgress ? true : "static"}
      isOpen={open}
      onClosed={() => {
        setError(null);
        setFile("");
        setName("");
      }}
      toggle={toggle}
    >
      <Form onSubmit={handleSubmit}>
        <ModalBody>
          {!inProgress && !!error && (
            <Alert className="font-weight-bold text-center" color="danger">
              {error}
            </Alert>
          )}
          {inProgress ? (
            <Fragment>
              <p className="font-weight-bold h5 mb-0 text-center">Uploading…</p>
              <p className="mb-0 text-center">
                <em>Time of upload will increase with size of file.</em>
              </p>
            </Fragment>
          ) : (
            <Fragment>
              <div className="custom-file mb-3">
                <Input
                  accept="application/pdf"
                  className="custom-file-input"
                  id="file"
                  label={(file && file.name) || "Choose file"}
                  onChange={handleFile}
                  type="file"
                />
                <Label
                  className={classNames({
                    "custom-file-label": true,
                    placeholder: !file || !file.name,
                  })}
                  for="file"
                >
                  {(file && file.name) || "Choose file"}
                </Label>
              </div>
              <label className="sr-only" htmlFor="add-name">
                Name
              </label>
              <CustomInput
                id="add-name"
                onChange={handleName}
                type="select"
                value={name}
              >
                {attachmentOptions.map(option => (
                  <option key={`opt-${option.value}`} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </CustomInput>
              <div className="pt-3">
                <label className="sr-only" htmlFor="add-report-date">
                  Report Date
                </label>
                <ListingDatePicker
                  id="add-report-date"
                  placeholderText="Choose report date (optional)"
                  onChange={setReportDate}
                  selectedDate={reportDate}
                />
              </div>
              <div className="pt-3">
                <CustomInput
                  checked={isPublic}
                  id="add-isPublic"
                  label="Public"
                  name="add-isPublic"
                  type="checkbox"
                  value={isPublic}
                  onChange={() => setIsPublic(prev => !prev)}
                />
              </div>
              <p className="mb-0 pt-3">{disclaimer}</p>
            </Fragment>
          )}
        </ModalBody>
        <ModalFooter className={inProgress ? "justify-content-lg-center" : ""}>
          <Button
            className="font-weight-bold px-3 py-2_75 text-uppercase"
            color="danger"
            onClick={() => {
              if (inProgress && !!cancel.current) {
                cancel.current();
              }

              toggle();
            }}
            size="sm"
            type="button"
          >
            Cancel
          </Button>
          {!inProgress && (
            <Button
              className="font-weight-bold px-3 py-2_75 text-uppercase"
              color="success"
              size="sm"
              type="submit"
            >
              Save
            </Button>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const ModalChange = ({ attachment, onSuccess, toggle }) => {
  const [error, setError] = useState(null);
  const [inProgress, setInProgress] = useState(false);
  const [name, setName] = useState("");
  const [reportDate, setReportDate] = useState("");

  const cancel = useRef();
  const isMounted = useRef(true);

  const handleError = error => {
    if (isMounted.current) {
      setError(getApiErrorMessage(error));
      setInProgress(false);
    }
  };

  const handleName = event =>
    setName((!!event && !!event.target && event.target.value) || "");

  const handleSubmit = event => {
    event.preventDefault();

    if (!name) {
      setError("Please select a name");
      return false;
    }

    if (
      !attachment ||
      (!!attachment && !attachment.id && parseInt(attachment.id, 10) !== 0)
    ) {
      setError("No attachment selected");
      return false;
    }

    if (!onSuccess) {
      setError("Missing Callback");
      return false;
    }

    setInProgress(true);

    const formData = {
      name: name,
      fileName: attachment.fileName,
      listingId: attachment.listingId,
      isPublic: attachment.isPublic,
      reportDate: reportDate,
    };

    api
      .put(`${URL_API_ATTACHMENTS}/${attachment.id}`, formData, {
        cancelToken: new CancelToken(c => {
          cancel.current = c;
        }),
      })
      .then(response => {
        if (
          !!response &&
          !!response.data &&
          response.data.success &&
          !!response.data.data &&
          !!response.data.data.attachments &&
          !!response.data.data.attachments.length
        ) {
          if (isMounted.current) {
            const update = response.data.data.attachments.find(
              a => a.id === attachment.id
            );

            if (!!update) {
              onSuccess(update);
              toggle(null);
              setInProgress(false);
              setError(null);
            } else {
              handleError("Unexpected response");
            }
          }
        } else {
          handleError(response);
        }
      })
      .catch(handleError);
  };

  const { name: origName = "", reportDate: origReportDate = "" } =
    attachment || {};

  useEffect(() => {
    setName(origName || "");
  }, [origName]);

  useEffect(() => {
    setReportDate(origReportDate || "");
  }, [origReportDate]);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      if (!!cancel.current) {
        cancel.current();
      }

      isMounted.current = false;
    };
  }, []);

  return (
    <Modal
      backdrop={!inProgress ? true : "static"}
      isOpen={!!attachment}
      onClosed={() => setError(null)}
      toggle={() => toggle(null)}
    >
      <Form onSubmit={handleSubmit}>
        <ModalBody>
          {!inProgress && !!error && (
            <Alert className="font-weight-bold text-center" color="danger">
              {error}
            </Alert>
          )}
          <label className="sr-only" htmlFor="change-name">
            Name
          </label>
          <CustomInput
            id="change-name"
            onChange={handleName}
            type="select"
            value={name}
          >
            {attachmentOptions.map(option => (
              <option key={`opt-${option.value}`} value={option.value}>
                {option.label}
              </option>
            ))}
          </CustomInput>
          <div className="pt-3">
            <label className="sr-only" htmlFor="change-report-date">
              Report Date
            </label>
            <ListingDatePicker
              id="change-report-date"
              placeholderText="Choose report date (optional)"
              onChange={setReportDate}
              selectedDate={reportDate}
            />
          </div>
          <p className="mb-0 pt-3">{disclaimer}</p>
        </ModalBody>
        <ModalFooter>
          <Button
            className="font-weight-bold px-3 py-2_75 text-uppercase"
            color="danger"
            disabled={inProgress}
            onClick={() => toggle(null)}
            size="sm"
            type="button"
          >
            Cancel
          </Button>
          <Button
            className="font-weight-bold px-3 py-2_75 text-uppercase"
            color="success"
            disabled={inProgress}
            size="sm"
            type="submit"
          >
            {inProgress ? (
              <Fragment>
                Saving <Spinner className="ml-2" size="sm" />
              </Fragment>
            ) : (
              "Save Edits"
            )}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const ModalDelete = ({ id, onSuccess, toggle }) => {
  const [error, setError] = useState(null);
  const [inProgress, setInProgress] = useState(false);

  const cancel = useRef();
  const isMounted = useRef(true);

  const handleError = error => {
    if (isMounted.current) {
      setError(getApiErrorMessage(error));
      setInProgress(false);
    }
  };

  const handleSubmit = event => {
    event.preventDefault();

    if (!id && parseInt(id, 10) !== 0) {
      setError("No attachment selected");
      return false;
    }

    if (!onSuccess) {
      setError("Missing Callback");
      return false;
    }

    setInProgress(true);

    api
      .delete(`${URL_API_ATTACHMENTS}/${id}`, {
        cancelToken: new CancelToken(c => {
          cancel.current = c;
        }),
      })
      .then(response => {
        if (!!response && !!response.data && !!response.data.success) {
          if (isMounted.current) {
            onSuccess(id);
            toggle(null);
            setInProgress(false);
            setError(null);
          }
        } else {
          handleError(response);
        }
      })
      .catch(handleError);
  };

  useEffect(() => {
    isMounted.current = true;

    return () => {
      if (!!cancel.current) {
        cancel.current();
      }

      isMounted.current = false;
    };
  }, []);

  return (
    <Modal
      backdrop={!inProgress ? true : "static"}
      isOpen={!!id || parseInt(id, 10) === 0}
      onClosed={() => setError(null)}
      toggle={() => toggle(null)}
    >
      <Form onSubmit={handleSubmit}>
        <ModalBody>
          {!inProgress && !!error && (
            <Alert className="font-weight-bold text-center" color="danger">
              {error}
            </Alert>
          )}
          <p className="h5 text-center">
            Are you sure you want to delete this file?
          </p>
          <p className="mb-0 text-center">{disclaimer}</p>
        </ModalBody>
        <ModalFooter className="justify-content-lg-center">
          <Button
            className="font-weight-bold px-3 py-2_75 text-uppercase"
            color="danger"
            disabled={inProgress}
            onClick={() => toggle(null)}
            size="sm"
            type="button"
          >
            No, Cancel
          </Button>
          <Button
            className="font-weight-bold px-3 py-2_75 text-uppercase"
            color="success"
            disabled={inProgress}
            size="sm"
            type="submit"
          >
            {inProgress ? (
              <Fragment>
                Deleting <Spinner className="ml-2" size="sm" />
              </Fragment>
            ) : (
              "Yes, Delete"
            )}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const CheckboxIsPublic = ({ attachment, onSuccess }) => {
  const [error, setError] = useState(null);
  const [inProgress, setInProgress] = useState(false);
  const [isPublic, setIsPublic] = useState(attachment.isPublic || false);

  const cancel = useRef();
  const isMounted = useRef(true);

  const fieldName = `isPublic-${attachment.id}`;

  const handleCheckbox = event => {
    event.preventDefault();

    if (
      !attachment ||
      (!!attachment && !attachment.id && parseInt(attachment.id, 10) !== 0)
    ) {
      setError("No attachment selected");
      return false;
    }

    if (!onSuccess) {
      setError("Missing Callback");
      return false;
    }

    setIsPublic(!isPublic);
    setInProgress(true);

    const formData = {
      name: attachment.name,
      fileName: attachment.fileName,
      listingId: attachment.listingId,
      isPublic: !isPublic, // state hasn't updated yet
      reportDate: attachment.reportDate,
    };

    api
      .put(`${URL_API_ATTACHMENTS}/${attachment.id}`, formData, {
        cancelToken: new CancelToken(c => {
          cancel.current = c;
        }),
      })
      .then(response => {
        if (
          !!response &&
          !!response.data &&
          response.data.success &&
          !!response.data.data &&
          !!response.data.data.attachments &&
          !!response.data.data.attachments.length
        ) {
          if (isMounted.current) {
            const update = response.data.data.attachments.find(
              a => a.id === attachment.id
            );

            if (!!update) {
              onSuccess(update);
              setInProgress(false);
              setError(null);
            } else {
              handleError("Unexpected response");
            }
          }
        } else {
          handleError(response);
        }
      })
      .catch(handleError);
  };

  const handleError = error => {
    if (isMounted.current) {
      setError(getApiErrorMessage(error));
      setInProgress(false);
    }
  };

  useEffect(() => {
    setIsPublic(attachment?.isPublic);
  }, [attachment]);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      if (!!cancel.current) {
        cancel.current();
      }

      isMounted.current = false;
    };
  }, []);

  return (
    <CustomInput
      checked={isPublic}
      disabled={inProgress}
      id={fieldName}
      label={
        <>
          {!!error ? error : "Public"}{" "}
          {inProgress ? <Icon className="ml-2" icon={faSync} spin /> : ""}
        </>
      }
      name={fieldName}
      type="checkbox"
      value={isPublic}
      onChange={handleCheckbox}
    />
  );
};

const Attachment = ({
  attachment,
  handleChange,
  toggleChange,
  toggleDelete,
}) => {
  const { name, reportDate } = attachment;

  const formattedName = useMemo(() => formatAttachmentName(name), [name]);
  const formattedReportDate = useMemo(
    () => formatAttachmentReportDate(reportDate),
    [reportDate]
  );

  return (
    <div className="border-top py-2_75">
      <p className="align-items-md-baseline d-md-flex mb-2">
        <span className="mr-md-2">{formattedName}</span>
        <em className="d-block small text-muted">
          {!!formattedReportDate ? (
            <>report date {formattedReportDate}</>
          ) : (
            <>report date not included</>
          )}
        </em>
      </p>
      <Row className="align-items-center">
        <Col xs={{ order: 2 }} md={{ order: 1, size: 8 }}>
          <div className="align-items-lg-start d-md-flex pt-2 pt-lg-0">
            <Button
              className="d-block font-weight-bold mb-2 mb-md-0 text-uppercase w-100"
              color="success"
              href={`${URL_ASSETS}/${attachment.fileName}`}
              rel="noopener noreferrer"
              size="sm"
              tag="a"
              target="_blank"
            >
              Preview
            </Button>
            <Button
              className="d-block font-weight-bold mb-2 mb-md-0 mx-md-2 text-uppercase w-100"
              color="primary"
              onClick={() => toggleChange(attachment)}
              size="sm"
              type="button"
            >
              Edit Data
            </Button>
            <Button
              className="d-block font-weight-bold mb-2 mb-md-0 text-uppercase w-100"
              color="danger"
              onClick={() => toggleDelete(attachment.id)}
              size="sm"
              type="button"
            >
              Delete
            </Button>
          </div>
        </Col>
        <Col xs={{ order: 1 }} md={{ order: 2, size: 4 }}>
          <CheckboxIsPublic attachment={attachment} onSuccess={handleChange} />
        </Col>
      </Row>
    </div>
  );
};

const ListingAttachments = props => {
  const [add, setAdd] = useState(false);
  const [attachments, setAttachments] = useState(
    !!props.attachments ? [...props.attachments] : []
  );
  const [change, setChange] = useState(null);
  const [del, setDel] = useState(null);

  const toggleAdd = () => setAdd(!add);

  const handleAdd = attachment => {
    if (!attachment) {
      return false;
    }

    setAttachments(prevAttachments => [attachment, ...prevAttachments]);
  };

  const toggleChange = attachment =>
    setChange(!!attachment ? attachment : null);

  const handleChange = attachment => {
    setAttachments(prevAttachments =>
      prevAttachments.map(prevAttachment => {
        if (
          !!attachment &&
          (!!attachment.id || parseInt(attachment.id, 10) === 0) &&
          attachment.id === prevAttachment.id
        ) {
          return attachment;
        } else {
          return prevAttachment;
        }
      })
    );
  };

  const toggleDelete = id => setDel(!!id || parseInt(id, 10) === 0 ? id : null);

  const handleDelete = id => {
    setAttachments(prevAttachments =>
      prevAttachments.filter(attachment => attachment.id !== id)
    );
  };

  return (
    <Fragment>
      <p className={!!attachments && !!attachments.length ? "" : "mb-0"}>
        <Button
          className="font-weight-bold px-3 py-2_75 text-uppercase"
          color="success"
          onClick={toggleAdd}
          size="sm"
          type="button"
        >
          Add New Digital Attachment
        </Button>
      </p>
      {!!attachments &&
        !!attachments.length &&
        attachments.map(attachment => (
          <Attachment
            attachment={attachment}
            handleChange={handleChange}
            key={`attachment-${attachment.id}`}
            toggleChange={toggleChange}
            toggleDelete={toggleDelete}
          />
        ))}
      <ModalAdd
        listingId={props.listingId}
        onSuccess={handleAdd}
        open={add}
        toggle={toggleAdd}
      />
      <ModalChange
        attachment={change}
        onSuccess={handleChange}
        toggle={toggleChange}
      />
      <ModalDelete id={del} onSuccess={handleDelete} toggle={toggleDelete} />
    </Fragment>
  );
};

ListingAttachments.propTypes = {
  attachments: arrayOf(
    shape({
      created_at: string,
      fileName: string,
      id: oneOfType([number, string]),
      listingId: oneOfType([number, string]),
      name: string,
      publicLink: string,
      updated_at: string,
    })
  ),
  listingId: oneOfType([number, string]),
};

export default ListingAttachments;
