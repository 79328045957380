import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { element, func, object, oneOfType } from "prop-types";

import AppError from "./App.error";

/**
 * Determines if user should be allowed to view the requested route
 * * Rechecks after every prop change
 */
class PrivateRoute extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasAuth: this.props.checkAuth(),
    };
  }

  componentDidUpdate(props, state) {
    const hasAuth = this.props.checkAuth();

    if (hasAuth !== state.hasAuth) {
      this.setState({ hasAuth: hasAuth });
    }
  }

  render() {
    const {
      component: Component,
      trackEvent,
      trackModalView,
      ...rest
    } = this.props;
    const { hasAuth } = this.state;

    return (
      <Route
        {...rest}
        render={props =>
          hasAuth ? (
            <AppError>
              <Component
                trackEvent={trackEvent}
                trackModalView={trackModalView}
                {...props}
              />
            </AppError>
          ) : (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )
        }
      />
    );
  }
}

PrivateRoute.propTypes = {
  /** Check user's authentication status */
  checkAuth: func.isRequired,
  /** Component that should be displayed if the user is authenticated */
  component: oneOfType([element, object, func]).isRequired,
  trackEvent: func,
  trackModalView: func,
};

export default PrivateRoute;
