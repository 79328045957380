import React, { Component, Fragment } from "react";
import { array, bool, func, object, string } from "prop-types";
import classNames from "classnames";

import { Alert, Badge, Container, Spinner } from "reactstrap";

import Layout from "../layout";
import { ListingForm, ListingGrid } from "./components";

import { SITE_SHOW_STATUSES } from "../../constants";
import { scrollIfNeeded } from "../../helpers";

/**
 * Displays listing data and allows the user to:
 * * Copy public listing link
 * * Edit the description and features content
 * * Save
 */
class Listing extends Component {
  constructor(props) {
    super(props);

    this.handleErrors = this.handleErrors.bind(this);

    this._isMounted = false;
    this.alertError = React.createRef();

    this.state = {
      listingErrors: [],
      showStatus: false,
      title: "Review Listing",
    };
  }

  /**
   * Handle errors
   * @param {array} errors
   * @public
   */
  handleErrors(errors) {
    if (
      this._isMounted &&
      JSON.stringify(errors) !== JSON.stringify(this.state.listingErrors)
    ) {
      this.setState({ listingErrors: errors }, () => {
        if (errors.length > 0 && this.alertError.current) {
          scrollIfNeeded(this.alertError.current);
        }
      });
    }
  }

  componentDidMount() {
    this._isMounted = true;

    const { fetchListing, match } = this.props;

    if (!match || !match.params || !match.params.equipmentId) {
      this.handleErrors(["Request missing Equipment ID"]);
      return false;
    }

    fetchListing(match.params.equipmentId);
  }

  componentDidUpdate(prevProps, prevState) {
    const { listing, listingError } = this.props;

    if (!!listingError && !this.state.listingErrors.includes(listingError)) {
      this.handleErrors([listingError]);
    }

    if (
      !listingError &&
      !!prevProps.listing.error &&
      this.state.listingErrors.includes(prevProps.listingError)
    ) {
      this.state.listingErrors.filter(
        error => error !== prevProps.listingError
      );
    }

    // Set page title
    if (
      !!listing &&
      (!prevProps.listing ||
        listing.equipmentId !== prevProps.listing.equipmentId)
    ) {
      this.setState({
        showStatus:
          !!listing.status && SITE_SHOW_STATUSES.includes(listing.status),
        title: listing.title,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.props.clearListing();
  }

  render() {
    const { alertError, handleErrors } = this;
    const {
      categoriesError,
      categoriesLoading,
      categoryOptions,
      fetchCategoryOptions,
      fetchMakes,
      listing,
      listingAction,
      listingLoading,
      lookupCategory,
      makes,
      makesError,
      updateListing,
    } = this.props;
    const { hasDraftPhotos } = listing;
    const { listingErrors, showStatus, title } = this.state;

    return (
      <Layout title={title}>
        <Container tag="article">
          <header>
            <p className="h1">
              {!!listing && !!listing.sourceType && (
                <span className="d-block h6 mt-n3 position-absolute text-muted">
                  {listing.sourceType} Equipment
                </span>
              )}
              Review Listing
            </p>
          </header>
          <section>
            <div className="layout-content">
              {listingLoading && (
                <Alert className="p-4" color="info">
                  <div className="align-items-center d-flex justify-content-center">
                    <Spinner />
                    <p className="mb-0 ml-3 pr-5">
                      <strong>Loading…</strong>
                    </p>
                  </div>
                </Alert>
              )}
              <div ref={alertError}>
                {!listingLoading && listingErrors.length > 0 && (
                  <Alert className="mb-4_5 p-4 text-center" color="danger">
                    {listingErrors.map((error, index) => (
                      <p
                        className={classNames({
                          "mb-0": index + 1 === listingErrors.length,
                        })}
                        key={`error-${index}`}
                      >
                        <strong>{error}</strong>
                      </p>
                    ))}
                  </Alert>
                )}
              </div>
              {!listingLoading && !!listing && !!Object.keys(listing).length && (
                <Fragment>
                  <h1
                    className={classNames({
                      "align-items-sm-end d-sm-flex":
                        hasDraftPhotos || showStatus,
                    })}
                  >
                    <span>
                      {!!listing.year && (
                        <Fragment>
                          <strong>{listing.year}</strong>{" "}
                        </Fragment>
                      )}
                      <span>
                        {listing.make}{" "}
                        {!!listing.make && !!listing.model && "–"}{" "}
                        {listing.model}
                      </span>
                    </span>
                    {hasDraftPhotos && (
                      <span className="d-block d-sm-inline smallest">
                        <Badge
                          className="mb-sm-2 text-uppercase ml-sm-4"
                          color="secondary"
                        >
                          Draft Photos
                        </Badge>
                      </span>
                    )}
                    {showStatus && (
                      <span className="d-block d-sm-inline smallest">
                        <Badge
                          className={classNames({
                            "mb-2 text-uppercase": true,
                            "ml-sm-2": !!hasDraftPhotos,
                            "ml-sm-4": !hasDraftPhotos,
                          })}
                          color="danger"
                        >
                          Sold
                        </Badge>
                      </span>
                    )}
                    {!!listing.salePrice && (
                      <span className="d-block d-sm-inline smallest">
                        <Badge
                          className={classNames({
                            "mb-2 text-uppercase": true,
                            "ml-sm-2":
                              (!!hasDraftPhotos && !showStatus) || showStatus,
                            "ml-sm-4": !hasDraftPhotos && !showStatus,
                          })}
                          color="special"
                        >
                          Special Pricing
                        </Badge>
                      </span>
                    )}
                  </h1>
                  <ListingGrid listing={listing} />
                  <ListingForm
                    categoriesError={categoriesError}
                    categoriesLoading={categoriesLoading}
                    categoryOptions={categoryOptions}
                    fetchCategoryOptions={fetchCategoryOptions}
                    fetchMakes={fetchMakes}
                    handleErrors={handleErrors}
                    listing={listing}
                    listingAction={listingAction}
                    makes={makes}
                    makesError={makesError}
                    lookupCategory={lookupCategory}
                    updateListing={updateListing}
                  />
                </Fragment>
              )}
            </div>
          </section>
        </Container>
      </Layout>
    );
  }
}

Listing.propTypes = {
  clearListing: func.isRequired,
  categoriesError: string,
  categoriesLoading: bool,
  categoryOptions: array,
  fetchCategoryOptions: func.isRequired,
  fetchListing: func.isRequired,
  fetchMakes: func,
  listing: object,
  listingAction: string,
  listingError: string,
  listingLoading: bool,
  makes: array,
  makesError: string,
  /** Route match information from React Router */
  match: object.isRequired,
  updateListing: func.isRequired,
};

export default Listing;
