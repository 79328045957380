import React, { Fragment, PureComponent } from "react";
import {
  arrayOf,
  bool,
  number,
  object,
  oneOfType,
  shape,
  string,
} from "prop-types";
import classNames from "classnames";

import { Link } from "react-router-dom";
import { Badge } from "reactstrap";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-regular-svg-icons";

// import { ListingCertification } from "../../listing";

import { SITE_SHOW_STATUSES } from "../../../constants";
import {
  displayMeter,
  formatNumber,
  formatTitleCase,
  getExcerpt,
  getStamp,
} from "../../../helpers";

class ListingsDetail extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showStatus: false,
    };
  }

  componentDidMount() {
    const { listing } = this.props;

    this.setState({
      showStatus:
        !!listing.status && SITE_SHOW_STATUSES.includes(listing.status),
    });
  }

  render() {
    const { listing, location, notLast, showAvailability } = this.props;
    const { showStatus } = this.state;

    return (
      <div
        className={classNames({
          "d-flex flex-wrap mb-4 pb-2": true,
          "border-bottom": notLast,
        })}
      >
        <div className="d-flex w-75">
          <div className="h3 mr-3 text-primary">
            <Link to={`/listing/${listing.equipmentId}`}>
              <Icon icon={faEdit} />
            </Link>
          </div>
          <div className="flex-grow-1">
            <h5 className="align-items-sm-end d-sm-flex font-weight-bold">
              <Link
                to={{
                  pathname: `/listing/${listing.equipmentId}`,
                  state: {
                    goBack: {
                      pathname: location.pathname,
                      search: location.search,
                    },
                  },
                }}
              >
                {!!listing.year && listing.year} {listing.make}{" "}
                {!!listing.make && !!listing.model && "–"} {listing.model}
              </Link>
              {listing.hasDraftPhotos && (
                <span className="d-block d-sm-inline smaller">
                  <Badge
                    className="mb-1 text-uppercase ml-sm-3"
                    color="secondary"
                    tag={Link}
                    to={{
                      pathname: `/listing/photos/${listing.equipmentId}`,
                      state: {
                        goBack: {
                          pathname: location.pathname,
                          search: location.search,
                        },
                      },
                    }}
                  >
                    Draft Photos
                  </Badge>
                </span>
              )}
              {showStatus && (
                <span className="d-block d-sm-inline smaller">
                  <Badge
                    className={classNames({
                      "mb-1 text-uppercase": true,
                      "ml-sm-1": !!listing.hasDraftPhotos,
                      "ml-sm-3": !listing.hasDraftPhotos,
                    })}
                    color="danger"
                  >
                    Sold
                  </Badge>
                </span>
              )}
              {!!listing.salePrice && (
                <span className="d-block d-sm-inline smaller">
                  <Badge
                    className={classNames({
                      "mb-1 text-uppercase": true,
                      "ml-sm-1":
                        (!!listing.hasDraftPhotos && !showStatus) || showStatus,
                      "ml-sm-3": !listing.hasDraftPhotos && !showStatus,
                    })}
                    color="special"
                  >
                    Special Pricing
                  </Badge>
                </span>
              )}
            </h5>
            <p className="mb-0">
              <span className="d-inline-block mr-3">
                <span className="text-muted text-uppercase">Equipment ID:</span>{" "}
                {!!listing.equipmentId ? listing.equipmentId : "–"}
              </span>
              <span className="d-inline-block mr-3">
                <span className="text-muted text-uppercase">Serial #:</span>{" "}
                {!!listing.serial ? listing.serial : "–"}
              </span>
              <span className="d-inline-block mr-3">
                <span className="text-muted text-uppercase">Acct #:</span>{" "}
                {!!listing.accountNumber ? listing.accountNumber : "–"}
              </span>
            </p>
            <p className="mb-0">
              <span className="d-inline-block mr-3">
                <span className="text-muted text-uppercase">List:</span>{" "}
                {!!listing.salePrice ? (
                  <Fragment>
                    {!!listing.advertisedPrice && (
                      <Fragment>
                        <s>${formatNumber(listing.advertisedPrice)}</s>{" "}
                      </Fragment>
                    )}
                    ${formatNumber(listing.salePrice)}
                  </Fragment>
                ) : (
                  <Fragment>
                    {!!listing.advertisedPrice
                      ? `$${formatNumber(listing.advertisedPrice)}`
                      : "—"}
                  </Fragment>
                )}
              </span>
              <span className="d-inline-block mr-3">
                <span className="text-muted text-uppercase">Meter:</span>{" "}
                {displayMeter(listing) ? (
                  <Fragment>
                    {formatNumber(listing.meterValue)}{" "}
                    {formatTitleCase(listing.meterType)}
                  </Fragment>
                ) : (
                  "–"
                )}
              </span>
              <span className="d-inline-block mr-3">
                <span className="text-muted text-uppercase">Photos:</span>{" "}
                {formatNumber(listing.photoCounts.total)}{" "}
                <small className="px-1">
                  <Link
                    className="small text-nowrap"
                    to={{
                      pathname: `/listing/photos/${listing.equipmentId}`,
                      state: {
                        goBack: {
                          pathname: location.pathname,
                          search: location.search,
                        },
                      },
                    }}
                  >
                    {!!listing.photoCounts.draft ||
                    !!listing.photoCounts.live ? (
                      <Fragment>
                        {formatNumber(listing.photoCounts.draft)}&nbsp;DRAFT |{" "}
                        {formatNumber(listing.photoCounts.live)}&nbsp;LIVE
                      </Fragment>
                    ) : (
                      "UPLOAD"
                    )}
                  </Link>
                </small>
              </span>
            </p>
            {!!listing.sourceDivision && (
              <p className="mb-0">
                <span className="d-inline-block">
                  <span className="text-muted text-uppercase">Source:</span>{" "}
                  {listing.sourceDivision}
                </span>
              </p>
            )}
            {!!listing.adminNotes && (
              <div className="bg-subtle mt-2 px-3 py-2">
                {getExcerpt(listing.adminNotes)}
              </div>
            )}
            <p className="mt-2">
              <em className="small text-muted">
                Last updated{" "}
                <span>
                  by {listing.updatedBy ? listing.updatedBy : "System"}
                </span>{" "}
                {!!listing.updated_at && getStamp(listing.updated_at)}
              </em>
            </p>
          </div>
        </div>
        <div className="ml-auto text-right">
          {listing.locationCity && listing.locationState && (
            <p className="mb-2">
              <strong>
                {listing.locationCity}
                {!!listing.locationCity &&
                  !!listing.locationState &&
                  `, ${listing.locationState}`}
              </strong>
            </p>
          )}
          {showAvailability && listing.isInternal !== 1 && (
            <Badge className="mb-1 text-uppercase" color="success">
              Public
            </Badge>
          )}
          {showAvailability && listing.isInternal === 1 && (
            <Badge className="mb-1 text-uppercase" color="brand">
              Internal
            </Badge>
          )}
          {/* {!!listing.certifiedUsed &&
            typeof listing.certifiedUsed !== "boolean" &&
            listing.certifiedUsed !== "none" && (
              <p>
                <ListingCertification status={listing.certifiedUsed} />
              </p>
            )} */}
        </div>
      </div>
    );
  }
}

ListingsDetail.propTypes = {
  /** Individual listing results */
  listing: shape({
    equipmentId: string,
    advertisedPrice: number,
    make: string,
    meterValue: number,
    meterType: string,
    model: string,
    photos: arrayOf(
      shape({
        draftIndex: number,
        draftNew: bool,
      })
    ),
    publishStatus: string,
    sourceDivision: string,
    status: string,
    updated_at: string,
    updatedBy: string,
    year: oneOfType([number, string]),
  }),
  location: object,
  notLast: bool,
  showAvailability: bool,
};

ListingsDetail.defaultProps = {
  notLast: true,
};

export default ListingsDetail;
