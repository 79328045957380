import { connect } from "react-redux";
import { withRouter } from "react-router";

import { actions as filterActions } from "./Filter.redux";
import Filter from "./Filter.view";

const mapStateToProps = state => ({
  // makes: state.makes,
  // makesError: state.makesError,
  sources: state.sources,
  sourcesError: state.sourcesError,
});

const mapDispatchToProps = dispatch => ({
  // fetchMakes: () => dispatch(filterActions.fetchMakes()),
  fetchSources: () => dispatch(filterActions.fetchSources()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Filter));
